@mixin icon-font-face {
@font-face {
	font-family: "iconfont";
    font-display: swap;
    font-weight: normal;
    font-style: normal;
	src: url('../fonts/iconfont/iconfont.woff2') format('woff2'),
		 url('../fonts/iconfont/iconfont.woff') format('woff');
}
}

@mixin icon-styles {
	font-family: "iconfont";
    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	//font-variant: normal;
	//font-weight: normal;
	//text-decoration: none;
	//text-transform: none;
}

%icon {
	@include icon-styles;
}

@function icon-char($filename) {
	$char: "";

	@if $filename == address-book {
		$char: "\E001";
	}
	@if $filename == angle-down {
		$char: "\E002";
	}
	@if $filename == angle-left {
		$char: "\E003";
	}
	@if $filename == angle-right {
		$char: "\E004";
	}
	@if $filename == angle-up {
		$char: "\E005";
	}
	@if $filename == bars {
		$char: "\E006";
	}
	@if $filename == book {
		$char: "\E007";
	}
	@if $filename == briefcase-medical {
		$char: "\E008";
	}
	@if $filename == bullhorn {
		$char: "\E009";
	}
	@if $filename == calculator {
		$char: "\E00A";
	}
	@if $filename == calendar-alt {
		$char: "\E00B";
	}
	@if $filename == camera {
		$char: "\E00C";
	}
	@if $filename == caret-down {
		$char: "\E00D";
	}
	@if $filename == caret-left {
		$char: "\E00E";
	}
	@if $filename == caret-right {
		$char: "\E00F";
	}
	@if $filename == caret-up {
		$char: "\E010";
	}
	@if $filename == chevron-down {
		$char: "\E011";
	}
	@if $filename == chevron-left {
		$char: "\E012";
	}
	@if $filename == chevron-right {
		$char: "\E013";
	}
	@if $filename == chevron-up {
		$char: "\E014";
	}
	@if $filename == cog {
		$char: "\E015";
	}
	@if $filename == comment-dots {
		$char: "\E016";
	}
	@if $filename == comments {
		$char: "\E017";
	}
	@if $filename == envelope {
		$char: "\E018";
	}
	@if $filename == eraser {
		$char: "\E019";
	}
	@if $filename == eye {
		$char: "\E01A";
	}
	@if $filename == facebook-square {
		$char: "\E01B";
	}
	@if $filename == heart-rate {
		$char: "\E01C";
	}
	@if $filename == info-circle {
		$char: "\E01D";
	}
	@if $filename == lock {
		$char: "\E01E";
	}
	@if $filename == minus {
		$char: "\E01F";
	}
	@if $filename == pinterest {
		$char: "\E020";
	}
	@if $filename == play {
		$char: "\E021";
	}
	@if $filename == plus-square {
		$char: "\E022";
	}
	@if $filename == plus {
		$char: "\E023";
	}
	@if $filename == print {
		$char: "\E024";
	}
	@if $filename == question-circle {
		$char: "\E025";
	}
	@if $filename == radar {
		$char: "\E026";
	}
	@if $filename == random {
		$char: "\E027";
	}
	@if $filename == rss {
		$char: "\E028";
	}
	@if $filename == search-plus {
		$char: "\E029";
	}
	@if $filename == search {
		$char: "\E02A";
	}
	@if $filename == star-empty {
		$char: "\E02B";
	}
	@if $filename == star-half {
		$char: "\E02C";
	}
	@if $filename == star {
		$char: "\E02D";
	}
	@if $filename == stethoscope {
		$char: "\E02E";
	}
	@if $filename == tag {
		$char: "\E02F";
	}
	@if $filename == thermometer-full {
		$char: "\E030";
	}
	@if $filename == thunderstorm {
		$char: "\E031";
	}
	@if $filename == times {
		$char: "\E032";
	}
	@if $filename == twitter {
		$char: "\E033";
	}
	@if $filename == umbrella {
		$char: "\E034";
	}
	@if $filename == user-md {
		$char: "\E035";
	}
	@if $filename == user {
		$char: "\E036";
	}
	@if $filename == users {
		$char: "\E037";
	}
	@if $filename == whatsapp {
		$char: "\E038";
	}
	@if $filename == youtube {
		$char: "\E039";
	}

	@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
	&:#{$insert} {
		@if $extend {
			@extend %icon;
		} @else {
			@include icon-styles;
		}
		content: icon-char($filename);
	}
}