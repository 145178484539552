@import "iconfont";

@include icon-font-face();

$page-width: 55rem;

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: none;
}

ins {
	background-color: #ff9;
	color: #000;
	text-decoration: none;
}

mark {
	background-color: #ff9;
	color: #000;
	font-style: italic;
	font-weight: bold;
}

del {
	text-decoration: line-through;
}

abbr[title], acronym[title], dfn[title] {
	border-bottom: .0625rem dotted;
	cursor: help;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	margin-left: .0625rem;
}

hr {
	display: block;
	height: .0625rem;
	border: 0;
	border-top: .0625rem solid #ccc;
	margin: 1em 0;
	padding: 0;
}

input, select {
	vertical-align: middle;
}

/**
 * Font normalization inspired by YUI Library's fonts.css: developer.yahoo.com/yui/
 */

body {
	font: .8125rem/1.231 sans-serif;
	*font-size: small;
}

/* Hack retained to preserve specificity */
select, input, textarea, button {
	font: 99% sans-serif;
}

/* Normalize monospace sizing:
	en.wikipedia.org/wiki/MediaWiki_talk:Common.css/Archive_11#Teletype_style_fix_for_Chrome */
pre, code, kbd, samp {
	font-family: monospace, sans-serif;
}

/**
 * Minimal base styles.
 */

/* Always force a scrollbar in non-IE */
html {
	overflow-y: scroll;
	scroll-padding-top: 1em;
}

/* Accessible focus treatment: people.opera.com/patrickl/experiments/keyboard/test */
a:hover, a:active {
	outline: none;
}

ul, ol {
	margin-left: 2em;
}

ol {
	list-style-type: decimal;
}

/* Remove margins for navigation lists */
nav ul, nav li {
	margin: 0;
	list-style: none none;
}

small {
	font-size: 85%;
}

b, strong, th {
	font-weight: bold;
}

td {
	vertical-align: top;
}

/* Set sub, sup without affecting line-height: gist.github.com/413930 */
sub, sup {
	font-size: 75%;
	position: relative;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

pre {
	/* www.pathf.com/blogs/2008/05/formatting-quoted-code-in-blog-posts-css21-white-space-pre-wrap/ */
	white-space: pre-wrap;
	word-wrap: break-word;
	padding: .9375rem;
}

textarea {
	overflow: auto;
}

/* Align checkboxes, radios, text inputs with their label by: Thierry Koblentz tjkdesign.com/ez-css/css/base.css */
input[type="radio"] {
	vertical-align: text-bottom;
}

input[type="checkbox"] {
	vertical-align: bottom;
}

/* Hand cursor on clickable input elements */
label, input[type="button"], input[type="submit"], input[type="image"], button {
	cursor: pointer;
}

/* Webkit browsers add a .125rem margin outside the chrome of form elements */
button, input, select, textarea {
	margin: 0;
}

/* Colors for form validity */

input:invalid, textarea:invalid {
	border-radius: .0625rem;
	-moz-box-shadow: 0 0 .3125rem red;
	-webkit-box-shadow: 0 0 .3125rem red;
	box-shadow: 0 0 .3125rem red;
}

.no-boxshadow input:invalid, .no-boxshadow textarea:invalid {
	background-color: #f0dddd;
}

/* These selection declarations have to be separate
	No text-shadow: twitter.com/miketaylr/status/12228805301
	Also: hot pink! */
::-moz-selection {
	background: #409bb9;
	color: #fff;
	text-shadow: none;
}

::selection {
	background: #409bb9;
	color: #fff;
	text-shadow: none;
}

/* j.mp/webkit-tap-highlight-color */
a:link {
	-webkit-tap-highlight-color: #409bb9;
}

/* Make buttons play nice in IE:
	www.viget.com/inspire/styling-the-button-element-in-internet-explorer/ */
button {
	width: auto;
	overflow: visible;
}

/**
 * You might tweak these..
 */

body, select, input, textarea {
	color: #000;
	/* Set your base font here, to apply evenly */
	font-family: Arial, sans-serif;
}

/* Headers (h1, h2, etc) have no default font-size or margin; define those yourself */
h1, h2, h3, h4, h5, h6 {
	font-weight: bold;
}

a, a:active, a:visited, a:hover {
	color: #409bb9;
}

i, em {
	font-style: italic;
}

.no_ls {
	margin: 0;
	padding: 0;
}

.no_ls li {
	list-style: none;
}

/* legend bugfix chrome */
fieldset {
	padding-top: .0625rem;
}

/* Font Awesome equivalents - please do NOT add more of them */

.fa {
	display: inline-block;
	font: normal normal normal .875rem/1 iconfont;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&:before,
	&:after {
		@include icon-styles();
	}
}

.fa-angle-left:before {
	content: icon-char("angle-left");
}

.fa-angle-right:before {
	content: icon-char("angle-right");
}

.fa-angle-up:before {
	content: icon-char("angle-up");
}

.fa-book:before {
	content: icon-char("book");
}

.fa-bullhorn:before {
	content: icon-char("bullhorn");
}

.fa-camera:before {
	content: icon-char("camera");
}

.fa-info-circle:before {
	content: icon-char("info-circle");
}

.fa-plus:before {
	content: icon-char("plus");
}

.fa-search:before {
	content: icon-char("search");
}

.fa-times:before {
	content: icon-char("times");
}

/* header */
.header {
	position: relative;
	z-index: 5002000;
}

.header .logo {
	left: 0;
	position: absolute;
	top: 0;
}

.header .header_ad {
	bottom: 0;
	left: 13.125rem;
	overflow: hidden;
	position: absolute;
	top: 4.375rem;
	width: 15.625rem;
}

.header_menu {
	position: relative;
	width: 55rem;
}

.header_menu > .bg {
	height: 2.375rem;
	position: absolute;
	right: 0;
	top: 0;
	width: 51.25rem;
}

.head_nav {
	height: 2.375rem;
	position: relative;
	z-index: 5001999;
}

.head_nav > li {
	float: left;
	height: 1.625rem;
	padding: .375rem .0625rem;
	position: relative;
	width: auto;
}

.head_nav > li > .bg {
	display: inline-block;
	position: relative;
}

.head_nav > li > .bg a {
	color: #fff;
	display: block;
	font-size: .6875rem;
	font-weight: bold;
	height: 2rem;
	letter-spacing: .01875rem;
	line-height: 1.625rem;
	padding: 0 .25rem;
}

.head_nav > li > .bg a:hover {
	text-decoration: none;
}

.head_nav > li.active .bg a {
	color: #000;
}

.head_nav > li:hover .bg {
	border-left-color: transparent;
	border-bottom: none;
	z-index: 5000040;
}

.head_nav > li:hover + li .bg {
	border-left-color: transparent;
}

.head_nav > li .bg a {
	border: .0625rem solid transparent;
	border-bottom: none;
}

.head_nav > li:hover .bg a {
	background-color: #fff;
	border-color: #ccc;
	border-top-left-radius: .3125rem;
	border-top-right-radius: .3125rem;
	color: #000;
}

.head_nav > li:hover .nav_sec {
	display: block;
}

.head_nav .nav_sec {
	background: #f7f7f4;
	border: .0625rem solid #ccc;
	border-radius: .25rem;
	display: none;
	left: .125rem;
	min-width: 0;
	padding: .75rem 0 1.5rem;
	position: absolute;
	top: 2.375rem;
	z-index: 5000039;
}

.head_nav .nav_sec_col1 {
	width: 10.0625rem;
}

.head_nav .nav_sec_col2 {
	width: 20.125rem;
}

.head_nav .nav_sec_col3 {
	width: 30.1875rem;
}

.head_nav .nav_sec_left {
	border-top-left-radius: 0;
	left: .0625rem;
	right: auto;
}

.head_nav .nav_sec_right {
	border-top-right-radius: 0;
	left: auto;
	right: .0625rem;
}

.head_nav .nav_sec ul {
	display: block;
	float: left;
	overflow: hidden;
	width: 10.0625rem;
}

.head_nav .nav_sec ul:first-child {
	background: #f7f7f4;
}

.head_nav .nav_sec li {
	border: none;
	margin-top: .5rem;
}

.head_nav .nav_sec li:first-child {
	margin-top: 0;
}

.head_nav .nav_sec a {
	display: block;
	font-size: .8125rem;
	line-height: 1rem;
	padding: 0 .625rem;
}

.header_main {
	height: 5.125rem;
	position: relative;
	width: 100%;
}

.header .search {
	background-color: transparent;
	bottom: 1rem;
	height: 1.75rem;
	position: absolute;
	right: 1.25rem;
}

.header .search fieldset {
	display: flex;
	padding-top: 0;
	position: relative;
	z-index: 80;
}

.header .search legend {
	display: none;
}

.header .search .text {
	border: .0625rem solid #aaa;
	border-top-left-radius: .3125rem;
	border-bottom-left-radius: .3125rem;
	background: transparent;
	font-size: .75rem;
	font-style: italic;
	height: 1.75rem;
	line-height: 1.75rem;
	margin: 0;
	padding: 0 .3125rem;
	width: 11.1875rem;
}

.header .search .default {
	color: #cfcfcf;
}

.header .search .submit {
	@include icon("search");

	background: #c01d2e;
	border: none;
	border-radius: 0 .3125rem .3125rem 0;
	cursor: pointer;
	display: block;
	line-height: 1.875rem;
	height: 1.875rem;
	width: 1.875rem;

	&:before {
		color: #fff;
		font-size: 1rem;
	}
}

.header .search .suggest {
	background: #fff;
	display: none;
	left: 0;
	padding: .1875rem .375rem .3125rem .375rem;
	position: absolute;
	top: 1.6875rem;
	width: 13rem;
	z-index: 5000070;
}

.header .search .suggest .group {
	border-top: .0625rem solid #ded9d3;
}

.header .search .suggest .group:first-child {
	border-top: none;
}

.header .search .suggest .group li {
	border-top: .0625rem solid #f1eee9;
}

.header .search .suggest .group li:hover,
.header .search .suggest .group li.active {
	background-color: #fff;
}

.header .search .suggest .group li:first-child {
	border-top: none;
}

.header .search .suggest a {
	display: block;
	font-size: .6875rem;
	line-height: .9375rem;
	padding: .25rem .1875rem .1875rem;
}

.header .search .suggest a:hover {
	text-decoration: none;
}

.header .search .suggest a span {
	cursor: pointer;
}

.header .search .suggest a .term {
	float: left;
}

.header .search .suggest a .type {
	color: #d9d6d0;
	float: right;
	margin-left: .1875rem;
}

.header .search .suggest .group li:first-child .type {
	color: #6f6f6f;
}

.header .feature {
	height: 1.625rem;
	padding: 0 0 0 .75rem;
	position: relative;
}

.header .feature > .bg {
	height: 1.625rem;
	position: absolute;
	right: 0;
	top: 0;
	width: 51.25rem;
}

.header .feature,
.header .feature a {
	color: #000;
	font-size: .75rem;
	line-height: .875rem;
}

.header .feature .list {
	float: left;
	width: auto;
}

.header .feature .list > li {
	float: left;
	padding: 0 .5rem 0 .5625rem;
	position: relative;
	width: auto;

	&:before {
		border-left: .0625rem solid #e6e3dc;
		content: "";
		display: block;
		height: 1rem;
		left: 0;
		position: absolute;
		top: .25rem;
	}
}

.header .feature .list > li.first {
	padding: 0 .5rem 0 0;

	&:before {
		display: none;
	}
}

.header .feature .list > li > a {
	cursor: pointer;
	display: block;
	font-size: .6875rem;
	height: 1.625rem;
	line-height: 1.625rem;
}

.header .feature .list > li > a.not_expanded {
	color: #6f6f6f;
	font-weight: bold;
}

.header .feature .list > li > a.expanded {
	font-weight: bold;
}

.header .feature .list > li > a.not_expanded,
.header .feature .list > li > a.sub {
	@include icon("caret-right", "after");

	&:after {
		color: #c01d2e;
		display: inline-block;
		font-size: .875rem;
		line-height: .9375rem;
		padding-left: .25rem;
		vertical-align: middle;
		width: .5rem;
	}
}

.header .feature .list > li > a.expanded,
.header .feature .list > li:hover > a.sub {
	@include icon("caret-down", "after");
}

.header .feature .list > li:hover .nav_sec {
	display: block;
}

.header .feature .login {
	color: #c01d2e;
	display: block;
	font-size: .75rem;
	font-weight: bold;
	height: 1.625rem;
	line-height: 1.625rem;
	position: absolute;
	right: 1.25rem;
	text-align: right;
	top: 0;
	width: 5rem;
}

.header .feature .login.logged_in {
	color: #000;
	font-weight: normal;
}

.header .feature .community {
	color: #fff;
	display: block;
	font-size: .75rem;
	font-weight: bold;
	height: 1.625rem;
	line-height: 1.625rem;
	position: absolute;
	right: 6.25rem;
	text-align: right;
	top: 0;
	width: 7.6875rem;
}

.header .feature .nav_sec {
	background: #e6e3dc;
	border-color: #cccccc;
	border-style: solid;
	border-width: 0 .0625rem .0625rem .0625rem;
	display: none;
	left: 0;
	min-width: 9.375rem;
	position: absolute;
	top: 1.625rem;
	z-index: 5000090;
}

.header .feature .nav_sec li {
	border-top: .0625rem solid #cccccc;
}

.header .feature .nav_sec li.first {
	border-top: none;
}

.header .feature .nav_sec a {
	display: block;
	font-size: .6875rem;
	line-height: 1.25rem;
	padding: 0 .625rem;
}

.header .feature .nav_sec a:hover {
	background: #f7f7f4;
}

/************************/

.breadcrumb {
	float: none;
	font-size: .6875rem;
	line-height: .8125rem;
	margin: 0 1.25rem 1.375rem;
	width: auto;
}

.breadcrumb p {
	float: left;
	width: auto;
}

.breadcrumb p a {
	margin: 0;
}

.breadcrumb h1 {
	float: left;
	font-weight: normal;
	margin-left: .125rem;
}

/* up-link at bottom, ... */
.bottom {
	color: #969696;
	float: none;
	margin-top: 1.25rem;
	padding: 0 0 1.25rem 1.25rem;
}

.bottom a {
	color: #969696;
	font-size: .75rem;
}

.bottom .top {
	@include icon("caret-up");
	padding-left: .9375rem;
	position: relative;

	&:before {
		color: #c01d2e;
		left: 0;
		position: absolute;
		top: -0.3125rem;
		font-size: 1.125rem;
	}
}

/* Trennlinie */
hr.separator {
	background: linear-gradient(190deg, rgba(212, 212, 212, 1) 0%, rgba(241, 241, 241, 1) 100%);
	border: none;
	clear: both;
	height: .9375rem;
	margin: 0 0 1.25rem 0;
}

/* article & related */
.article {
	font-size: .875rem;
	line-height: 1.25rem;
}

.article .video {
	background-color: #f2f1ed;
	border: .0625rem solid #ccc6c0;
	clear: both;
	margin-bottom: .625rem;
}

.article .video .img_container {
	float: left;
	margin: .5rem .625rem .3125rem .625rem;
	position: relative;
}

.article .video .img {
	background: #969696;
	border: .0625rem solid black;
	display: block;
	height: 7.625rem;
	width: 13.4375rem;
}

.article .video .img:hover {
	border-color: red;
}

.article .video .overlay,
.mod_video .inner .overlay {
	@include icon("play");
	background: rgba(0, 0, 0, .5);
	border-radius: 50%;
	display: block;
	font-style: normal;
	height: 3.125rem;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 3.125rem;
	z-index: 10;

	&:before {
		color: #fff;
		font-size: 1.25rem;
		position: absolute;
		transform: translate(-35%, -50%);
		left: 50%;
		font-style: normal;
		top: 50%;
	}

	&:after {
		content: "Video abspielen";
		color: rgba(255, 255, 255, .75);
		position: absolute;
		text-align: center;
		top: 100%;
		left: -52%;
		right: 0;
		white-space: nowrap;
	}
}

.mod_video .img_container:hover .overlay:after,
.article .video .img:hover .overlay:after {
	display: none;
}

.article .video .inner {
	padding: .4375rem .625rem;
}

.article .video .adMarker {
	float: right;
	font-size: .625rem;
	line-height: 1.0625rem;
	margin-left: .9375rem;
	text-transform: uppercase;
}

.article .video .hl {
	color: #000;
	font-size: .8125rem;
	font-weight: bold;
	line-height: 1.0625rem;
	margin: 0 0 .3125rem 0;
}

.article .video .txt {
	color: #000;
	font-size: .8125rem;
	line-height: 1.0625rem;
	margin-bottom: .3125rem;
}

.article .video .rights {
	color: #333333;
	font-size: .6875rem;
	line-height: 1.0625rem;
	margin: 0;
}

.article .kicker {
	font-weight: bold;
	margin-bottom: .3125rem;
}

.article .hl {
	color: #409bb9;
	font-size: 1.25rem;
	font-weight: bold;
	line-height: 1.5rem;
	margin-bottom: 1.125rem;
}

.article .hl_ad {
	padding: 0 3.125rem 0 0;
	position: relative;
}

.hl.hl_ad.ad::after {
	color: #333;
	content: "anzeige";
	font-size: .75rem;
	font-variant: small-caps;
	font-weight: normal;
	padding: 0 .125rem 0 0;
	position: absolute;
	right: 0;
}

.article .hl_sub {
	color: #409bb9;
	font-size: .875rem;
	line-height: 1rem;
	margin: -0.9375rem 0 1.25rem;
}

.article p,
.article ol,
.article ul,
.article dl,
.article table {
	margin-bottom: .6875rem;
}

.article p a,
.article p a:active,
.article p a:visited,
.article p a:hover {
	color: #409BB9;
}

.article a.inlineLink.ad::after {
	content: "anzeige";
	color: #333;
	font-size: .75rem;
	font-variant: small-caps;
	font-weight: normal;
	padding: 0 0 0 .3125rem;
}

.article .txt {
	overflow: hidden;
	width: 100%;
}

.article .txt h1,
.article .txt h2,
.article .txt h3,
.article .txt h4 {
	font-size: 1rem;
	line-height: 1.25rem;
}

.article .txt h1,
.article .txt h2,
.article .txt h3,
.article .txt h4,
.article .txt h5,
.article .txt h6 {
	margin-bottom: .6875rem;
}

.article .txt h1 a,
.article .txt h1 a:active,
.article .txt h1 a:visited,
.article .txt h1 a:hover,
.article .txt h2 a,
.article .txt h2 a:active,
.article .txt h2 a:visited,
.article .txt h2 a:hover,
.article .txt h3 a,
.article .txt h3 a:active,
.article .txt h3 a:visited,
.article .txt h3 a:hover,
.article .txt h4 a,
.article .txt h4 a:active,
.article .txt h4 a:visited,
.article .txt h4 a:hover,
.article .txt h5 a,
.article .txt h5 a:active,
.article .txt h5 a:visited,
.article .txt h5 a:hover,
.article .txt h6 a,
.article .txt h6 a:active,
.article .txt h6 a:visited,
.article .txt h6 a:hover {
	color: #409BB9;
}

.article .txt li a,
.article .txt li a:active,
.article .txt li a:visited,
.article .txt li a:hover {
	color: #409BB9;
}

.article table th,
.article table td {
	border: .0625rem solid;
	padding: .125rem .3125rem;
}

.article .toggleBlockLink {
	@include icon("plus");
	margin-left: .0625rem;
	padding-left: 1rem;
	position: relative;

	&:before {
		color: #0d497f;
		font-size: .625rem;
		position: absolute;
		top: .125rem;
		left: .1875rem;
		line-height: 1;
	}
}

.article .toggleBlockLink.open {
	@include icon("minus");
}

/* paging */
.article .original {
	display: none;
}

.article .paging_group {
	display: none;
}

.article-abstract {
	font-size: .9375rem;
	line-height: 1.375rem;
	margin-bottom: .6875rem;
}

.article .speakable {
	border-bottom: .0625rem solid;
	border-color: #409bb9;
	margin-bottom: .6875rem;
}

/* Teaser-Overlays */
.teaserImageContainer {
	display: block;
	height: 100%;
	position: relative;
	width: auto;
}

.teaserImageContainer .teaserImageOverlay {
	bottom: 0;
	display: block;
	left: 0;
	margin: auto;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
}

.teaserImageContainer .teaserImageOverlay {
	background: rgba(58, 56, 66, .5);
	color: #fff;
	height: 2.75rem;
	width: 12.5rem;
}

.teaserImageContainer .teaserImageOverlay--small {
	width: 3.125rem;
}

.teaserImageContainer .teaserImageOverlay_block {
	display: block;
	float: left;
	height: 100%;
}

.teaserImageContainer .teaserImageOverlay_block:after {
	display: block;
	font-size: 1.875rem;
	line-height: 1.875rem;
	margin-top: .4375rem;
	text-align: center;
}

.teaserImageContainer .teaserImageOverlay_block--icon {
	width: 3.125rem;
}

.teaserImageContainer .teaserImageOverlay_block--icon:after {
	@include icon-styles();
	font-size: 1.875rem;
}

.teaserImageContainer .teaserImageOverlay_block--text {
	width: 6.875rem;
}

.teaserImageContainer .teaserImageOverlay_block--text:after {
	font-size: 1rem;
	font-weight: bold;
}

.teaserImageContainer .teaserImageOverlay_block--arrow {
	@include icon("chevron-right", "after");
	width: 2.5rem;

	&:after {
		font-size: 1.5rem;
	}
}

.teaserImageContainer--gallery .teaserImageOverlay_block--icon:after {
	content: icon-char("camera");
}

.teaserImageContainer--gallery .teaserImageOverlay_block--text:after {
	content: "Bildergalerie";
}

.teaserImageContainer--media .teaserImageOverlay_block--icon:after {
	content: icon-char("youtube");
}

.teaserImageContainer--media .teaserImageOverlay_block--text:after {
	content: "Video";
}

.teaserImageContainer--selftest .teaserImageOverlay_block--icon:after {
	content: icon-char("question-circle");
}

.teaserImageContainer--selftest .teaserImageOverlay_block--text:after {
	content: "Selbsttest";
}

/* Bildergalerie-Teaser / -Inline */
.slideshow {
	background-color: #f2f1ed;
	border-bottom: .0625rem solid #ccc6c0;
	border-top: .0625rem solid #ccc6c0;
	clear: both;
	margin-bottom: 1.125rem;
	padding: .625rem;
	position: relative;
}

.slideshow .hd {
	border-bottom: none;
	color: #000;
	float: left;
	font-size: 1rem;
	font-weight: bold;
	line-height: 1.8125rem;
	margin-bottom: .625rem;
}

.slideshow .control {
	background-color: #f2f1ed;
	color: #6f6f6f;
	display: none;
	float: right;
	height: 1.8125rem;
}

.slideshow .control .prev,
.slideshow .control .next {
	cursor: pointer;
	float: left;

	&:before {
		@include icon-styles();
		background: #0D497F;
		border-radius: 50%;
		color: #FFF;
		display: block;
		font-size: 1.3125rem;
		height: 1.8125rem;
		line-height: 1.8125rem;
		text-align: center;
		width: 1.8125rem;
	}
}

.slideshow .control .prev:before {
	content: icon-char('caret-left');
	text-indent: -0.1875rem;
}

.slideshow .control .next:before {
	content: icon-char('caret-right');
	text-indent: .1875rem;
}

.slideshow .control .counter {
	float: left;
	line-height: 1.8125rem;
	margin: 0 .5625rem;
}

.slideshow .control_bottom {
	bottom: .625rem;
	position: absolute;
	right: .625rem;
}

.slideshow .wrapper {
	overflow: hidden;
}

.slideshow .list {
	margin-left: -0.3125rem;
	width: 9999em;
}

.slideshow .list li {
	float: left;
	margin: 0 .3125rem;
	width: 31.25rem;
}

.slideshow .list.ads li {
	padding-top: 1.25rem;
}

.slideshow .list.ads li.ad {
	padding-top: 0;
}

.slideshow .list.ads li.ad::before {
	color: #333;
	content: "anzeige";
	display: inline-block;
	font-size: .75rem;
	font-variant: small-caps;
	font-weight: normal;
	float: right;
	height: 1.25rem;
}

.slideshow .hl {
	color: #000;
	font-size: .8125rem;
	font-weight: bold;
	line-height: 1.0625rem;
	margin: .3125rem 0 .3125rem 0;
}

.slideshow .txt {
	color: #000;
	font-size: .8125rem;
	line-height: 1.0625rem;
}

.slideshow .pflichttext {
	color: #6f6f6f;
	font-size: .6875rem;
	line-height: .9375rem;
	margin: 0;
	font-style: italic;
}

.slideshow .rights {
	color: #6f6f6f;
	font-size: .6875rem;
	line-height: 2rem;
	margin: 0;
}

/* Bildergalerie */
.mod_gallery {
	clear: both;
	margin-bottom: 1.125rem;
	position: relative;
}

.mod_gallery .head .hd {
	border-bottom: none;
	color: #000;
	font-size: .9375rem;
	font-weight: bold;
	line-height: 1.875rem;
	margin-bottom: .75rem;
}

.mod_gallery .navibar {
	float: left;
	width: 17.5rem;
	padding: 0 1.25rem;
}

.mod_gallery .navibar .link {
	font-size: .9375rem;
	font-weight: bold;
	line-height: 2.8125rem;
}

.mod_gallery .navibar .backLink {
	float: left;
}

.mod_gallery .navibar .allLink,
.mod_gallery .naviLinks .allLink {
	float: right;
}

.mod_gallery .control {
	color: #6f6f6f;
	height: 3.125rem;
	margin: 0 auto;
	text-align: center;
}

.mod_gallery .control .counter {
	line-height: 2.8125rem;
	margin: 0 .5625rem;
	min-width: 3.1875rem;
	display: inline-block;
	vertical-align: top;
}

.mod_gallery .control .prev,
.mod_gallery .control .next {
	background-color: #0D497F;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-family: Verdana, sans-serif;
	font-size: 1.875rem;
	font-weight: normal;
	font-style: normal;
	height: 2.5rem;
	line-height: 2.5rem;
	text-align: center;
	text-decoration: none;
	text-indent: 0;
	vertical-align: top;
	width: 2.5rem;
}

.mod_gallery .prev {
	@include icon("angle-left");

	&:before {
		font-size: 1.4375rem;
		padding-right: .3125rem;
	}
}

.mod_gallery .next {
	@include icon("angle-right");

	&:before {
		font-size: 1.4375rem;
		padding-left: .3125rem;
	}
}

.mod_gallery .naviLinks {
	background-color: #0B3C68;
	clear: both;
	color: #fff;
	padding: .3125rem .625rem;
	width: 31.25rem;
}

.mod_gallery .naviLinks a,
.mod_gallery .naviLinks a:active,
.mod_gallery .naviLinks a:visited,
.mod_gallery .naviLinks a:hover {
	color: #fff;
	font-size: .75rem;
}

.mod_gallery .current-headline {
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: bold;
	margin-bottom: .625rem;
}

.mod_gallery .control .prev:hover,
.mod_gallery .control .next:hover {
	background-color: #0B3C68;
}

.mod_gallery .control .prev:active,
.mod_gallery .control .next:active {
	position: relative;
	top: .0625rem;
}

.mod_gallery .inner.ad {
	display: flex;
	flex-direction: column;
}

.mod_gallery .inner.ad::before {
	color: #333;
	content: "anzeige";
	font-size: .75rem;
	font-variant: small-caps;
	font-weight: normal;
	text-align: right;
}

.mod_gallery .hl {
	color: #000;
	font-size: .8125rem;
	font-weight: bold;
	line-height: 1.0625rem;
	margin: .3125rem 0 .3125rem 0;
}

.mod_gallery .txt {
	color: #000;
	font-size: .9375rem;
	line-height: 1.375rem;
	margin-right: 9.375rem;
}

.mod_gallery .pflichttext {
	bottom: 1.875rem;
	color: #fff;
	font-size: .6875rem;
	font-style: italic;
	line-height: .9375rem;
	margin: 0 9.375rem 0 0;
	position: absolute;
	padding: 1rem;
	z-index: 1;
}

.mod_gallery .rights {
	bottom: .3125rem;
	color: #FFF;
	font-size: 1rem;
	left: 1rem;
	margin: 0;
	position: absolute;
	right: 1rem;
	transform: scale(.87);
	transform-origin: left;
	z-index: 1;
}

.mod_gallery .teaser-wrapper .hd {
	margin-bottom: .5rem;
}

.mod_gallery .teaser-wrapper .line {
	margin: 0 0 1.25rem 0;
}

.mod_gallery .teaser-wrapper .media {
	float: left;
	margin-left: 1.25rem;
	margin-bottom: .625rem;
	overflow: hidden;
	width: 15.625rem;
}

.mod_gallery .teaser-wrapper .media:first-child {
	margin-left: 0;
}

.mod_gallery .teaser-wrapper .media:nth-child(2n+1) {
	clear: left;
	margin-left: 0;
}

.mod_gallery .teaser-wrapper .media .img {
	display: block;
	float: none;
	margin-bottom: .4375rem;
	width: auto;
}

.mod_gallery .teaser-wrapper .bd {
	display: block;
}

.mod_gallery .teaser-wrapper .hl {
	font-size: .8125rem;
	font-weight: bold;
	line-height: 1.125rem;
	margin: .1875rem 0;
}

.mod_gallery .teaser-wrapper .hl a.ad {
	display: flex;
	justify-content: space-between;
}

.mod_gallery .teaser-wrapper .hl a.ad::after {
	content: "anzeige";
	color: #333;
	font-size: .75rem;
	font-variant: small-caps;
	font-weight: normal;
	padding: 0 .125rem;
}

.mod_gallery .teaser-wrapper .txt {
	font-size: .8125rem;
	line-height: 1rem;
	margin: 0;
}

.mod_gallery .teaser-wrapper .txt a {
	color: #000;
	font-weight: bold;
}

.mod_gallery .ad-wrapper {
	clear: both;
}

.mod_gallery .u-slide.isAd .wrapper {
	align-items: center;
	display: flex;
	justify-content: center;
	min-height: 21.875rem;
}

/* Bildergalerien-Uebersicht */
.mod_galleries .hd {
	margin-bottom: .75rem;
}

.mod_galleries .teaser-wrapper .line {
	margin: 0 0 1.25rem 0;
}

.mod_galleries .teaser-wrapper--top .line {
	background-color: #f2f1ed;
	padding: .625rem;
}

.mod_galleries .teaser-wrapper .media {
	float: left;
	margin-left: .9375rem;
	overflow: hidden;
	width: 16.875rem;
}

.mod_galleries .teaser-wrapper--top .media {
	margin-left: 1.25rem;
	width: 16.25rem;
}

.mod_galleries .teaser-wrapper .media:first-child {
	margin-left: 0;
}

.mod_galleries .teaser-wrapper .media .img {
	display: block;
	float: none;
	margin-bottom: .4375rem;
	width: auto;
}

.mod_galleries .teaser-wrapper .media .img img {
	height: auto;
	width: 100%;
}

.mod_galleries .teaser-wrapper .bd {
	display: block;
}

.mod_galleries .teaser-wrapper .hl {
	font-size: .8125rem;
	font-weight: bold;
	line-height: 1.125rem;
	margin: .1875rem 0;
}

.mod_galleries .teaser-wrapper .hl a.ad {
	display: block;
	position: relative;
	padding: 0 3.125rem 0 0;
}

.mod_galleries .teaser-wrapper .hl a.ad::after {
	color: #333;
	content: "anzeige";
	font-size: .75rem;
	font-variant: small-caps;
	font-weight: normal;
	padding: 0 .125rem;
	position: absolute;
	right: 0;
	top: 0;
}

.mod_galleries .teaser-wrapper .txt {
	font-size: .8125rem;
	line-height: 1rem;
	margin: 0;
}

.mod_galleries .teaser-wrapper .txt a {
	color: #000;
	font-weight: bold;
}

/* mod_remedies */
.mod_remedies {
	color: #333333;
	font-size: .8125rem;
	line-height: 1.125rem;
}

.mod_remedies .hd {
	border-bottom: .0625rem solid #e3dfdc;
	font-size: .875rem;
	line-height: 1.0625rem;
	padding-bottom: .375rem;
}

.mod_remedies .ilist li {
	border-bottom: .0625rem solid #e3dfdc;
	padding: .375rem 0 .375rem .9375rem;
}

/* mod_rate */
.mod_rate .list li {
	border-bottom: .0625rem solid #e3dfdc;
	padding: 1.4375rem 0 .6875rem;
}

.mod_rate .report {
	float: right;
}

.mod_rate .kicker {
	color: #777777;
	font-size: .8125rem;
	line-height: 1.0625rem;
	margin-bottom: .625rem;
}

.mod_rate .txt {
	font-size: .8125rem;
	line-height: 1.0625rem;
	margin-bottom: 1.5rem;
}

.mod_rate form {
	background-color: transparent;
}

.mod_rate legend {
	color: #000;
	float: left;
	font-size: .8125rem;
	font-weight: normal;
	line-height: 1.125rem;
}

.mod_rate .line {
	float: right;
}

.mod_rate .line input[type=radio] {
	margin-left: .9375rem;
}

.mod_rate .rating {
	color: #999999;
	font-size: .8125rem;
	line-height: 1.125rem;
	margin-bottom: 0;
}

/* generics for ALL forms! */

p.msg {
	min-height: 1.3em;
	line-height: 1.3em;
	margin: 1em 0 .5em 0;
}

.dynamic_success {
	color: #1cab00;
	font-weight: bold;
	display: none;
}

.dynamic_error {
	color: #f61212;
	font-weight: bold;
	display: none;
}

/* privacy settings */
.mod_privacy .h1 {
	margin-bottom: .875rem;
}

.mod_privacy p {
	font-size: .8125rem;
	line-height: 1rem;
	margin-bottom: .9375rem;
}

.mod_privacy form {
	background-color: transparent;
	margin-bottom: 2.5rem;
}

.mod_privacy form fieldset {
	margin-bottom: 0;
}

.mod_privacy form legend {
	border-bottom: .0625rem solid #e6e3dc;
	font-size: .875rem;
	font-weight: bold;
	line-height: 1.4375rem;
	padding-bottom: .1875rem;
	margin-bottom: .5rem;
	width: 100%;
}

.mod_privacy form input[type=checkbox],
.mod_privacy form input[type=radio] {
	vertical-align: middle;
}

.mod_privacy form textarea,
.mod_privacy form input[type=password],
.mod_privacy form input[type=text] {
	width: 14.3125rem;
}

.mod_privacy form .line {
	border-bottom: .0625rem solid #e6e3dc;
	margin-bottom: 0;
	padding: .5rem 0;
}

.mod_privacy form .line_nb {
	border: none;
}

.mod_privacy form div.left {
	float: left;
	margin: 0 .625rem 0 0;
	text-align: left;
	width: 14.0625rem;
}

.mod_privacy form div.left label {
	font-size: .75rem;
	font-weight: bold;
	line-height: 1.25rem;
	margin: 0;
}

.mod_privacy form div.right {
	float: right;
	text-align: left;
	width: 14.6875rem;
}

.mod_privacy form div.right label {
	font-size: .8125rem;
	line-height: 1rem;
	margin: 0 0 0 .375rem;
	vertical-align: middle;
}

.mod_privacy form div.right select {
	height: 1.3125rem;
	width: 100%;
}

.mod_privacy form div.right p {
	font-size: .75rem;
	line-height: 1.25rem;
	margin: 0;
}

.mod_privacy form.notifySettings div.left {
	text-align: left;
	width: 19.375rem;
}

.mod_privacy form.notifySettings div.right {
	text-align: left;
	width: 9.375rem;
}

.mod_privacy form .actionbar div.left {
	color: #969696;
	font-size: .75rem;
	font-style: italic;
	line-height: 1.125rem;
	margin: 0 .625rem 0 .9375rem;
	text-align: left;
	width: 18.125rem;
}

.mod_privacy form .actionbar div.right {
	text-align: left;
	width: 9.6875rem;
}

.mod_privacy form input[type=submit] {
	float: right;
	margin: .3125rem .4375rem 0 0;
}

.cancel_account {
	background-color: #f2f1ed;
	border-bottom: .0625rem solid #ccc6c0;
	border-top: .0625rem solid #ccc6c0;
	margin-bottom: 1.25rem;
	padding: .625rem .4375rem .625rem 1.0625rem;
}

.cancel_account .hl {
	color: #409bb9;
	font-size: 1rem;
	line-height: 1.25rem;
}

.cancel_account p {
	margin-bottom: 0;
}

.cancel_account .submit {
	float: right;
}

/* 13.2.1.2 register site */
.register {
	background-color: transparent;
}

.register .txt {
	font-size: .8125rem;
	line-height: 1rem;
	margin-bottom: 1.8125rem;
}

.register p.error {
	color: #f61212;
	font-weight: bold;
	margin: 1.8125rem 0;
}

.register p.success {
	color: #1cab00;
	font-weight: bold;
	margin: 1.8125rem 0;
}

.register legend {
	font-size: 1.25rem;
	border-bottom: .1875rem solid #409bb9;
	line-height: 1.875rem;
	margin-bottom: .75rem;
	padding-bottom: .125rem;
	width: 100%;
}

.register .line {
	margin-bottom: .625rem;
}

.register .line .left {
	float: left;
	text-align: right;
	width: 8.3125rem;
}

.register .line .left label {
	color: #000;
	font-size: .8125rem;
	line-height: 1.4375rem;
	font-weight: bold;
}

.register .line .right {
	float: right;
	width: 20.125rem;
}

.register .line .right input[type=text],
.register .line .right input[type=password],
.register .line .right input[type=email] {
	margin-bottom: .4375rem;
	width: 13.75rem;
}

.register .line .right select {
	width: 13.875rem;
}

.register .line .right input[type=text].error,
.register .line .right input[type=password].error,
.register .line .right input[type=email].error,
.register .line .right select.error {
	border-color: #f61212;
}

.register .line .right p.error {
	color: #973544;
	font-weight: bold;
	margin: 0 0 .3125rem 0;
}

.register .line .right p.success {
	color: #1cab00;
	font-weight: bold;
	margin: 0 0 .3125rem 0;
}

.register .line .right .info {
	font-size: .6875rem;
	line-height: .875rem;
}

.register .line .right input[type=checkbox] {
	float: left;
}

.register .line .right label {
	margin-left: .1875rem;
	margin-right: .375rem;
	vertical-align: middle;
}

.register .line .right label.checkboxRow {
	color: #6f6f6f;
	display: block;
	font-size: .6875rem;
	font-weight: bold;
	margin: 0 0 .5625rem 1.625rem;
}

.register .line .left label.error,
.register .line .right label.error {
	color: #f61212;
}

.register .line .right label a {
	color: #f61212;
}

.register .external_account_info {
	border: .0625rem solid #E6E3DC;
	font-size: .875rem;
	font-weight: bold;
	margin-bottom: 1.25rem;
}

.register .external_account_info .inner {
	background: none repeat scroll 0 0 #F7F7F4;
	padding: .625rem .9375rem;
}

.register .external_account_info .account_name {
	border-bottom: .0625rem solid #E6E3DC;
	margin: .3125rem 0 .625rem 0;
	padding-bottom: .9375rem;
	text-align: center;
}

.register .external_account_info .account_link_info {
	font-size: .8125rem;
	font-weight: bold;
}

.register .bb {
	border-bottom: .0625rem solid #e6e3dc;
	margin-bottom: .625rem;
	padding-bottom: .625rem;
}

.register .captchaImage {
	height: 3.25rem;
	margin: .625rem 0;
}

.mod_user_activation .hd {
	margin-bottom: .75rem;
}

/* 9.2. contact-form */
.contact {
	background-color: transparent;
}

.contact .txt {
	font-size: .8125rem;
	line-height: 1rem;
	margin-bottom: 1.8125rem;
}

.contact p.error {
	color: #f61212;
	margin: 1.8125rem 0;
}

.contact p.error label {
	display: inline-block;
	width: 10.1875rem;
	text-align: right;
	margin-right: .625rem;
}

.contact p.success {
	color: #1cab00;
	margin: 1.8125rem 0;
}

.contact legend {
	font-size: 1.25rem;
	border-bottom: .1875rem solid #409bb9;
	line-height: 1.875rem;
	margin-bottom: .75rem;
	padding-bottom: .125rem;
	width: 100%;
}

.contact .line {
	margin-bottom: .625rem;
}

.contact .left {
	float: left;
	text-align: right;
	width: 10.1875rem;
}

.contact .left label {
	color: #000;
	font-size: .8125rem;
	line-height: 1.4375rem;
	font-weight: bold;
}

.contact .right {
	float: right;
	width: 21.375rem;
}

.contact .right input[type=text],
.contact .right input[type=password],
.contact .right input[type=email] {
	margin-bottom: .4375rem;
	width: 20rem;
}

.contact .right input[type=text].error,
.contact .right input[type=password].error,
.contact .right input[type=email].error {
	border-color: #f61212;
}

.contact .right .info {
	font-size: .6875rem;
	line-height: .875rem;
	margin-bottom: .375rem;
}

.contact .right input[type=checkbox] {
	float: left;
}

.contact .right select,
.contact .right textarea {
	margin-bottom: .4375rem;
	width: 20.3125rem;
}

.contact .right select.error,
.contact .right textarea.error {
	border-color: #f61212;
}

.contact .right.zip.location input[type=text]#zip {
	width: 3.75rem;
}

.contact .right.zip.location input[type=text]#location {
	width: 15.6875rem;
}

.contact .right label {
	color: #6f6f6f;
	display: block;
	font-size: .6875rem;
	font-weight: bold;
	margin: 0 0 .5625rem 1.625rem;
}

.contact .left label.error,
.contact .right label.error {
	color: #f61212;
}

.contact .right label a {
	color: #f61212;
}

.contact .bb {
	border-bottom: .0625rem solid #e6e3dc;
	padding-bottom: 1.1875rem;
}

.contact .captchaImage {
	height: 3.25rem;
	margin: .625rem 0;
}

/* Formular */
.form {
	background-color: transparent;
	line-height: 1.0625rem;
}

.form form {
	padding: .625rem;
}

.form .txt {
	font-size: .8125rem;
	line-height: 1rem;
	margin-bottom: 1.8125rem;
}

.form p.error {
	color: #f61212;
	margin: 1.8125rem 0;
}

.form p.error label {
	display: inline-block;
	width: 10.1875rem;
	text-align: right;
	margin-right: .625rem;
}

.form p.success {
	color: #1cab00;
	margin: 1.8125rem 0;
}

.form legend {
	font-size: 1.25rem;
	border-bottom: .1875rem solid #409bb9;
	line-height: 1.875rem;
	margin-bottom: .75rem;
	padding-bottom: .125rem;
	width: 100%;
}

.form label.error {
	color: #f61212;
}

.form input.error {
	border-color: #f61212;
}

.form .form_item {
	font-size: .8125rem;
	padding: 0 0 .6875rem 0;
	width: 31.25rem;
}

.form .form_item.last {
	padding: 0;
}

.form .form_item h4 {
	border-style: solid;
	border-width: 0 0 .0625rem;
	color: #6f6f6f;
	font-size: .875rem;
	font-weight: bold;
	padding: .625rem 0 .125rem 0;
	width: 100%;
}

.form .form_label {
	float: left;
	text-align: right;
	width: 10.625rem;
}

.form .form_label label {
	font-weight: normal;
}

.form .form_input {
	float: left;
	margin-left: .625rem;
	text-align: left;
	width: 20rem;
}

.form .form_input input.i, .form_input input.ui-datepicker {
	width: 19.5625rem;
}

.form .form_input input[type="radio"],
.form .form_input input[type="checkbox"],
.form .form_input label {
	vertical-align: middle;
}

.form .form_input select {
	width: 19.9375rem;
}

.form .form_input textarea {
	height: 9.375rem;
	width: 19.8125rem;
}

.form .captcha_wrapper {
	padding: 0 0 .6875rem 0;
}

.form .form_agbCheck label {
	font-weight: bold;
}

.form .form_agbCheck a {
	text-decoration: underline;
}

.form .form_agbCheck a:hover {
	color: #d5091c;
}

.form .formButtons {
	margin-top: .3125rem;
}

.form .formButtons .form_label {
	padding-top: 1.0625rem;
}

.form .formButtons .form_input {
	border-top: .0625rem solid #6f6f6f;
	padding-top: .625rem;
}

.form .formButtons .requiredText {
	text-align: left;
}

.form .formButtons #submitFormBtn {
	float: right;
}

.form .formButtons #resetFormBtn {
	float: none;
}

/* 9.4. rss-feeds */
.rss {
	font-size: .8125rem;
	line-height: 1.0625rem;
}

.rss .hd {
	font-size: 1.25rem;
	line-height: 1.5rem;
	margin-bottom: .75rem;
	padding-bottom: .3125rem;
}

.rss .ilist {
	margin-bottom: 1.3125rem;
}

.rss .ilist li {
	border-bottom: .0625rem solid #e3dfdc;
	height: 1.375rem;
	padding: 0 .5625rem 0 1.375rem;
	position: relative;

	&:before {
		left: .5rem;
		top: .375rem;
	}
}

.rss .ilist li a {
	color: #409bb9;
	font-size: .875rem;
	line-height: 1.375rem;
}

.rss .ilist .rss-icon {
	@include icon("rss");
	border-radius: .1875rem;
	cursor: pointer;
	background-color: #ee802f;
	display: block;
	height: 1rem;
	position: absolute;
	right: .5625rem;
	top: .1875rem;
	text-align: center;
	width: 1rem;

	&:before {
		color: #fff;
		font-size: .625rem;
		line-height: 1rem;
		vertical-align: top;
	}
}

.rss h2,
.rss h3 {
	margin-bottom: .5rem;
}

.rss .abstract {
	background-color: transparent;
	margin-bottom: .6875rem;
	padding: 0;
}

.rss p {
	margin-bottom: 1.3125rem;
}

/* 9.7 sitemap */
.sitemap {
	margin: 0 0 1.25rem 1.25rem;
}

.sitemap .hd {
	font-size: 1rem;
	line-height: 1.25rem;
	margin-bottom: .75rem;
	padding-bottom: .3125rem;
}

.sitemap > ul {
	margin-bottom: 1.3125rem;
}

.sitemap ul li {
	padding-left: .875rem;
	position: relative;
}

.sitemap ul li.sub:before {
	@include icon-styles();
	content: icon-char("caret-right");
	font-size: 1.0625rem;
	line-height: 1.125rem;
	position: absolute;
	left: 0;
	top: 0;
}

.sitemap ul li.open:before {
	content: icon-char("caret-down");
}

.sitemap ul li a {
	font-size: .875rem;
	font-weight: bold;
	line-height: 1.125rem;
}

.sitemap ul li a:hover {
	color: #9cc5db;
}

.sitemap ul ul {
	display: none;
	margin-bottom: 1.3125rem;
	padding-left: .9375rem;
}

.sitemap ul ul li {
	background-image: none;
}

.sitemap ul ul li a {
	color: #000;
}

.sitemap ul ul li a:hover {
	color: #6f6f6f;
}

.sitemap ul ul ul {
	display: none;
	margin-bottom: 0;
}

.sitemap ul ul ul li a {
	font-weight: normal;
}

.sitemap ul ul ul ul {
	display: block;
	margin-bottom: 0;
}

/* 7.1.2.1.1_metainformationen-zum-artikel.html */
.meta_info {
	clear: both;
	font-size: .6875rem;
	line-height: 1.0625rem;
	margin-bottom: .6875rem;
}

.meta_info .editorialsource--label {
	cursor: pointer;
	display: block;
}

.meta_info .editorialsource--label {
	@include icon("chevron-down", "after");

	&:after {
		color: #0D497F;
		padding-left: .125rem;
		vertical-align: middle;
	}
}

.meta_info .editorialsource--label.open {
	@include icon("chevron-up", "after");
}

.meta_info .copyright {
	display: block;
	font-size: inherit;
	line-height: inherit;
	margin-left: 1em;
	word-wrap: break-word;
}

.meta_info .copyright li {
	font-size: inherit;
	line-height: inherit;
}

.meta_info .icd-info {
	cursor: help;
	margin: 0 .0625rem 0 .1875rem;
}

/************************/
.article .inline {
	display: block;
}

.article .inline_img {
	margin-bottom: 1.5625rem;
	margin-top: .125rem;
}

.article .inline_left {
	float: left;
	margin-left: 0;
	margin-right: 1rem;
	width: auto;
}

.article .inline_right {
	float: right;
	margin-left: 1rem;
	margin-right: 0;
	width: auto;
}

.article .inline_box {
	width: 16.875rem;
}

.article .inline_img img,
.inline_img img {
	max-width: 100%;
}

.inline_img dl {
	margin-bottom: 0;
}

.inline_img dl .img {
	position: relative;
	margin: 0 0 .375rem 0;
}

.inline_img .img img {
	display: block;
}

.inline_img .img .magnifier {
	@include icon("search");
	background-color: rgba(255, 255, 255, .75);
	bottom: 0;
	display: block;
	height: 1.9375rem;
	position: absolute;
	text-align: center;
	right: 0;
	width: 1.875rem;

	&:before {
		color: #000;
		font-size: 1.125rem;
		line-height: 1.9375rem;
	}
}

.inline_img .caption {
	font-size: .75rem;
	font-style: italic;
	line-height: 1.125rem;
}

.inline_img .rights {
	color: #999999;
	font-size: .6875rem;
	line-height: 1.0625rem;
}

.special_wrapper {
	margin: 0 -1.25rem 0;
	padding: 0 .625rem .9375rem;
}

.special_wrapper .left {
	float: left;
	width: 30rem;
}

.special_wrapper .right {
	float: right;
	width: 18.75rem;
}

.special_wrapper .right .mod {
	margin-bottom: 0;
}

.special_wrapper .mod_special {
	margin-bottom: 0;
}

/************************/

.content .mod {
	margin: 0 0 1.25rem 0;
}

.content .mod .more {
	@include icon("caret-right", "after");
	padding-right: .625rem;
	position: relative;

	&:after {
		color: #c01d2e;
		font-size: inherit;
		position: absolute;
		line-height: 1;
		right: 0;
		top: .0625rem;
	}

	&:before {
		display: none;
	}
}

.mod .signature {
	border-top: .0625rem solid #e6e3dc;
	color: #6f6f6f;
	font-style: italic;
	margin-top: .625rem;
	padding-top: .3125rem;
}

/* Newsletter-Anmeldung */

.mod_newsletter form {
	background-color: transparent;
}

.mod_newsletter .error,
.mod_newsletter .success {
	margin: .625rem 0 1.25rem 0;
}

.mod_newsletter .error .hl,
.mod_newsletter .success .hl {
	font-size: .875rem;
	font-weight: bold;
	margin: 0 0 .625rem 0;
}

.mod_newsletter .error .hl {
	color: #f61212;
}

.mod_newsletter .success .hl {
	color: #1cab00;
}

.mod_newsletter p {
	margin: 0 0 .5rem 0;
}

.mod_newsletter .line {
	margin: .5rem 0;
}

.mod_newsletter .line .label {
	float: left;
	width: 5.3125rem;
}

.mod_newsletter .line .input {
	float: left;
	width: 18.75rem;
}

.mod_newsletter .line .label {
	line-height: 1.25rem;
}

.mod_newsletter .line .label.required {
	font-weight: bold;
}

.mod_newsletter .line .input input[type=text],
.mod_newsletter .line .input input[type=email] {
	padding: .125rem;
	width: 18.375rem;
}

.mod_newsletter .line .input select {
	width: 18.75rem;
}

.mod_newsletter .privacy_check_label {
	font-size: 90%;
}

.mod_newsletter .btnSubmit {
	margin-left: 5.3125rem;
}

/* mod_selftest (52_Sebsttest) */
.mod_selftest {
	background-color: #e6e3dc;
	color: #000;
	padding: .875rem .9375rem .625rem;
}

.mod_selftest form {
	background-color: transparent;
}

.mod_selftest .img {
	float: right;
	margin: 0 0 .125rem .3125rem;
}

.mod_selftest .counter {
	color: #6f6f6f;
	font-size: .6875rem;
	line-height: .9375rem;
	margin-bottom: .1875rem;
}

.mod_selftest .hl,
.mod_selftest legend {
	color: #000;
	font-size: .875rem;
	font-weight: normal;
	line-height: 1.1875rem;
	margin-bottom: .625rem;
	white-space: normal;
}

.mod_selftest .teaser,
.mod_selftest .text,
.mod_selftest p {
	margin-bottom: .625rem;
	margin-top: .625rem;
}

.mod_selftest .teaser {
	font-size: .8125rem;
	font-weight: bold;
}

.mod_selftest .error {
	color: #f61212;
	font-weight: bold;
}

.mod_selftest input[type=radio],
.mod_selftest input[type=checkbox] {
	float: left;
}

.mod_selftest label {
	color: #000;
	display: block;
	font-size: .8125rem;
	line-height: 1.0625rem;
	margin-left: 1.25rem;
}

.mod_selftest .line {
	margin-bottom: .3125rem;
}

.mod_selftest .answer_desc {
	display: none;
}

.mod_selftest .buttons {
	clear: both;
}

.mod_selftest .previous,
.mod_selftest .next,
.mod_selftest .previous:hover,
.mod_selftest .next:hover {
	color: #fff;
	display: block;
	float: left;
	font-size: .75rem;
	font-weight: bold;
	line-height: 1.6875rem;
	margin-top: 1.875rem;
	text-align: center;
	text-decoration: none;
	width: 5.75rem;
}

.mod_selftest .next,
.mod_selftest .next:hover {
	float: right;
}

.mod_selftest .scale {
	height: 1.25rem;
	margin-bottom: .625rem;
	position: relative;
}

.mod_selftest .scale img {
	height: .625rem;
	margin: .3125rem 0;
	width: 100%;
}

.mod_selftest .scalePos {
	background: #000;
	bottom: 0;
	position: absolute;
	top: 0;
	width: .125rem;
}

/* Umfrage */
.mod_poll {
	background-color: #e6e3dc;
	color: #000;
	padding: .875rem .9375rem .625rem;
}

.mod_poll form {
	background-color: transparent;
}

.mod_poll .kicker {
	color: #6f6f6f;
	font-size: .6875rem;
	font-weight: normal !important;
	line-height: .9375rem;
	margin-bottom: .1875rem;
}

.mod_poll .hl,
.mod_poll legend {
	color: #000;
	font-size: .875rem;
	font-weight: normal;
	line-height: 1.1875rem;
	margin-bottom: .625rem;
	white-space: normal;
}

.mod_poll .teaser,
.mod_poll .text,
.mod_poll p {
	margin-bottom: .625rem;
	margin-top: .625rem;
}

.mod_poll .teaser {
	font-size: .8125rem;
	font-weight: bold;
}

.mod_poll .error {
	color: #f61212;
	font-weight: bold;
}

.mod_poll input[type=radio],
.mod_poll input[type=checkbox] {
	float: left;
	margin-top: .125rem;
}

.mod_poll label {
	color: #000;
	display: block;
	font-size: .8125rem;
	line-height: 1.0625rem;
	margin-left: 1.25rem;
}

.mod_poll .barLine {
	margin-top: .6875rem;
}

.mod_poll .bar {
	background-color: #000;
	float: left;
	height: .875rem;
}

.mod_poll .barLegend {
	background-color: #000;
	float: left;
	height: .875rem;
	margin-bottom: .3125rem;
	margin-right: .625rem;
	width: .875rem;
}

.mod_poll .barColor1 {
	background-color: #004276;
}

.mod_poll .barColor2 {
	background-color: #7FA0BA;
}

.mod_poll .barColor3 {
	background-color: #5EA5D9;
}

.mod_poll .barColor4 {
	background-color: #31838C;
}

.mod_poll .line {
	margin-bottom: .3125rem;
	overflow: hidden;
}

.mod_poll .result {
	border-bottom: .0625rem solid #DDDDDD;
	margin-bottom: .625rem;
	padding-bottom: .625rem;
}

.mod_poll .percentage {
	float: left;
	margin-left: .625rem;
}

.mod_poll .buttons {
	clear: both;
}

.mod_poll .voteBtn,
.mod_poll .resultBtn,
.mod_poll .voteBtn:hover,
.mod_poll .resultBtn:hover {
	color: #fff;
	display: block;
	float: right;
	font-size: .75rem;
	font-weight: bold;
	line-height: 1.6875rem;
	margin-left: .625rem;
	margin-top: 1.25rem;
	text-align: center;
	text-decoration: none;
	width: 5.75rem;
}

.aside .mod_poll .hl {
	font-weight: normal !important;
}

.aside .mod_poll .percentage {
	margin-left: .5rem;
}

/* 40_aktivste_nutzer_fragen_und_antworten */
.mod_users .users_sort {
	background-color: transparent;
	border-bottom: .0625rem solid #e6e3dc;
	color: #6f6f6f;
	font-size: .875rem;
	height: 2.125rem;
	line-height: 1.0625rem;
	text-align: center;
}

.mod_users .users_sort fieldset {
	padding: .5625rem 0 .5rem;
}

.mod_users .users_sort input[type=radio] {
	margin-left: .5rem;
}

.mod_users table {
	border-bottom: .0625rem solid #e6e3dc;
	border-collapse: separate;
	width: 100%;
}

.mod_users th {
	color: #6f6f6f;
	font-size: .6875rem;
	font-weight: normal;
	line-height: .8125rem;
	padding: .5rem .75rem .4375rem 0;
	text-align: right;
}

.mod_users th:first-child {
	padding-left: .5rem;
	text-align: left;
}

.mod_users td {
	border-left: .0625rem solid #e6e3dc;
	border-bottom: .0625rem solid #fff;
	border-top: .0625rem solid #e6e3dc;
	color: #409bb9;
	font-size: 1.1875rem;
	line-height: 1.3125rem;
	padding: .625rem .75rem .75rem 0;
	text-align: right;
	vertical-align: middle;
}

.mod_users td:first-child {
	border-left: none;
	color: #6f6f6f;
	font-size: .8125rem;
	line-height: 1.125rem;
	padding-left: .5rem;
	text-align: left;
}

.mod_users .uicon {
	float: left;
	margin-right: 1.0625rem;
}

.mod_users .reg {
	font-size: .6875rem;
	white-space: nowrap;
}

/* 41_fremdansicht_benutzerprofil */
.mod_profile .hd {
	margin-bottom: .75rem;
	padding-bottom: .1875rem;
	position: relative;
}

.mod_profile .common {
	border: .0625rem solid #e6e3dc;
	padding: .1875rem;
}

.mod_profile .inner {
	background: #f7f7f4;
	padding: .625rem;
}

.mod_profile .uinfo {
	float: left;
	width: 8.75rem;
}

.mod_profile .uicon {
	box-shadow: 0 0 .1875rem #cdcdcd;
	height: 8.125rem;
	margin-bottom: .625rem;
	width: 8.125rem;
}

.mod_profile .online_status {
	color: #000;
	font-size: .5625rem;
	float: right;
	font-weight: bold;
	line-height: 1.25rem;
	margin: 0;
	text-transform: uppercase;
}

.mod_profile .online_color {
	color: green;
	font-weight: bold;
}

.mod_profile .h2 {
	font-size: 1rem;
	line-height: 1.25rem;
}

.mod_profile .h3 {
	border-bottom: .0625rem solid #e3dfdc;
	color: #409bb9;
	font-size: 1rem;
	line-height: 1.125rem;
	padding-bottom: .1875rem;
}

.mod_profile .info {
	font-size: .8125rem;
	line-height: 1.0625rem;
	min-height: 8.75rem;
	margin-left: 10rem;
}

.mod_profile .info ul {
	list-style: none;
	margin-left: 0;
	padding: 0;
}

.mod_profile .info table th {
	padding-right: 1.25rem;
	text-align: left;
}

.mod_profile .info p {
	margin-bottom: .5rem;
}

.mod_profile .info th {
	font-weight: bold;
}

.mod_profile .info .sep {
	border-bottom: .0625rem solid #409bb9;
	height: .0625rem;
	margin: .625rem 0;
}

.mod_profile .ilist {
	margin-bottom: 1.375rem;
}

.mod_profile .ilist li {
	border-bottom: .0625rem solid #e3dfdc;
	padding: .1875rem 0 .1875rem 1.125rem;
}

.mod_profile .ilist * {
	font-size: .8125rem;
	line-height: 1.0625rem;
}

.mod_profile .ilist a {
	color: #409bb9;
}

.mod_profile .txt {
	font-size: .8125rem;
	line-height: 1.0625rem;
	margin: .5rem 0 0 0;
}

.mod_profile .gender {
	font-size: .8125rem;
}

.mod_profile .username {
	margin-bottom: .625rem;
	font-size: 1rem;
	line-height: 1.25rem;
	color: #409bb9;
}

.mod_profile .interests {
	margin-bottom: 1.875rem;
}

.mod_profile .interests .hd {
	margin-bottom: 0 !important;
}

.mod_profile .interests .inner {
	background: transparent;
	padding: .3125rem 0;
}

.mod_profile .interests .inner ul {
	margin: 0;
}

.mod_profile .interests .inner li.last {
	border: none;
}

/* 42_eigenansicht_benutzerprofil */
.priv_change {
	@include icon("cog");
	background: -webkit-gradient(linear, left top, left bottom, from(#136fc0), to(#0d4a80));
	background: -o-linear-gradient(top, #136fc0 0%, #0d4a80 100%);
	background: linear-gradient(180deg, #136fc0 0%, #0d4a80 100%);
	bottom: 0;
	border-radius: .3125rem .3125rem 0 0;
	height: 1.625rem;
	position: absolute;
	text-align: center;
	right: 0;
	width: 2.25rem;

	&:before {
		color: #fff;
		line-height: 1.625rem;
		position: relative;
		top: .0625rem;
	}
}

.mod_profile_p .h2 {
	border-bottom: .0625rem solid #e3dfdc;
	color: #409bb9;
	font-size: 1rem;
	line-height: 1.125rem;
	padding-bottom: .1875rem;
	position: relative;
}

.mod_profile_p .privacy {
	color: #969696;
	font-size: .6875rem;
	font-weight: normal;
	line-height: 1.0625rem;
}

.mod_profile_p .expert,
.mod_profile_p .private {
	padding-right: 1.125rem;
	position: relative;

	&:after {
		@include icon-styles();
		color: #969696;
		font-size: .6875rem;
		line-height: 1;
		position: absolute;
		right: .25rem;
		top: .0625rem;
	}
}

.mod_profile_p .expert:after {
	content: icon-char("briefcase-medical");
}

.mod_profile_p .private:after {
	content: icon-char("lock");
}

.mod_profile_p .icon {
	float: left;
	margin: .9375rem .625rem 0 .625rem;
	width: 8.75rem;
}

.mod_profile_p .icon form {
	background-color: transparent;
	text-align: center;
}

.mod_profile_p .icon input[type=file] {
	display: none;
}

.mod_profile_p .icon button {
	border: none;
	cursor: pointer;
}

.mod_profile_p .icon .delete,
.mod_profile_p .icon .upload {
	background: -webkit-gradient(linear, left top, left bottom, from(#136fc0), to(#0d4a80));
	background: -o-linear-gradient(top, #136fc0 0%, #0d4a80 100%);
	background: linear-gradient(180deg, #136fc0 0%, #0d4a80 100%);
	color: #fff;
	font-weight: bold;
	white-space: nowrap;
	border-radius: .3125rem
}

.mod_profile_p .icon .upload {
	height: 1.3125rem;
	margin: .625rem auto 0;
	width: 6.5625rem;
}

.mod_profile_p .icon .delete {
	height: 1.3125rem;
	margin: .5rem auto 0;
	width: 4.375rem;
}

.mod_profile_p .uicon {
	height: 8.125rem;
	width: 8.125rem;
}

.mod_profile_p .info {
	margin: 0;
}

.mod_profile_p table {
	font-size: .8125rem;
	line-height: 1.0625rem;
	margin: .9375rem 0 2.1875rem 0;
	width: 19.375rem;
}

.mod_profile_p form table {
	margin-top: .3125rem;
}

.mod_profile_p th {
	font-weight: normal;
	padding-right: .625rem;
	text-align: right;
}

.mod_profile_p td {
	font-weight: bold;
	padding-bottom: .5625rem;
}

.mod_profile_p .ilist li {
	border-bottom: .0625rem solid #e3dfdc;
	padding: .1875rem 2.25rem .1875rem 1.125rem;
	position: relative;
}

.mod_profile_p .ilist li .delete {
	@include icon("times");
	background: #999 none;
	border-radius: .25rem;
	color: #fff;
	height: 1rem;
	position: absolute;
	right: .625rem;
	text-align: center;
	top: .1875rem;
	width: 1rem;

	&:before {
		font-size: .6875rem;
		line-height: 1rem;
	}
}

.mod_profile_p .ilist li .delete:hover {
	background-color: #c01d2e;
}

.mod_profile_p .ilist * {
	font-size: .8125rem;
	line-height: 1.0625rem;
}

.mod_profile_p .ilist a {
	color: #409bb9;
}

.mod_profile_p .interests {
	border-bottom: .0625rem solid #e3dfdc;
	margin-bottom: 2.375rem;
	padding: .625rem .625rem 0;
}

.mod_profile_p .interests legend {
	color: #000;
	font-size: .8125rem;
	line-height: 1.0625rem;
	margin-bottom: .5625rem;
}

.mod_profile_p .interests select {
	width: 13.625rem;
}

.mod_profile_p .interests label {
	color: #000;
	font-weight: bold;
	margin: 0 .625rem 0 0;
}

.mod_profile_p .interests input[type=text] {
	margin-top: 0;
	width: 14.375rem;
}

.mod_profile_p .interests input[type=submit] {
	margin-top: -0.1875rem;
}

.mod_profile_p .interests textarea {
	width: 26.125rem;
}

.mod_profile_p .interests .line {
	margin-bottom: .8125rem;
}

.mod_profile_p .interests .left {
	float: left;
}

.mod_profile_p .interests .right {
	float: right;
}

.mod_profile_p #community_user_userprofile_info form {
	border-bottom: .0625rem solid #e3dfdc;
	margin-bottom: 1.875rem;
	padding: .9375rem 0 .625rem 0;
}

.mod_profile_p .actionbar {
	line-height: 1.6875rem;
	padding: 0 .625rem;
}

.mod_profile_p .actionbar .submit {
	float: right;
}

.mod_profile_p .txt {
	font-size: .8125rem;
	line-height: 1.0625rem;
	margin: .5rem 0 1.125rem;
}

/* 34_Startseite_Mein_BesserLeben */
.mod_my_intro .h1 {
	margin-bottom: 1.25rem;
}

.mod_my_intro p {
	font-size: .8125rem;
	line-height: 1.0625rem;
}

.mod_my_latest {
	overflow: hidden;
}

.mod_my_latest .tabs li a {
	padding: 0 .625rem;
}

.mod_my_latest .uicon {
	height: 3.3125rem;
	left: .75rem;
	padding: .3125rem;
	position: absolute;
	top: 2.6875rem;
	width: 3.3125rem;
}

.mod_my_latest .hd {
	border: none;
	font-size: 1rem;
	line-height: 1.125rem;
	margin-bottom: .25rem;
}

.mod_my_latest .sub {
	color: #6f6f6f;
	font-size: .8125rem;
	line-height: 1.125rem;
}

.mod_my_latest .list {
	margin-bottom: .75rem;
}

.mod_my_latest .list li {
	border-top: none;
	min-height: 6.25rem;
	padding: .625rem 1.25rem;
	position: relative;
}

.mod_my_latest .list li.first {
	border-top: .0625rem solid #e3dfdc;
}

.mod_my_latest .badge {
	color: #409bb9;
	font-size: .8125rem;
	font-weight: bold;
	left: .9375rem;
	line-height: 1.875rem;
	position: absolute;
	top: 0;
}

.mod_my_latest .kicker {
	color: #6f6f6f;
	font-size: .8125rem;
	line-height: 1.125rem;
	margin: 0 0 .625rem 4.625rem;
}

.mod_my_latest .hl {
	font-size: 1rem;
	font-weight: bold;
	line-height: 1.125rem;
	margin-bottom: .4375rem;
}

.mod_my_latest .hl a {
	color: #000;
}

.mod_my_latest .bd {
	font-size: .8125rem;
	line-height: 1.125rem;
}

.mod_my_latest .bd a {
	color: #000;
	font-weight: bold;
}

.mod_my_latest .ft {
	text-align: right;
}

.mod_my_latest .ft .more {
	color: #6f6f6f;
}

.mod_my_latest .simple.list li {
	min-height: 0;
	padding-left: 2.0625rem;
}

.mod_my_latest .simple.list .icon {
	@include icon("comment-dots");
	height: .9375rem;
	left: .5625rem;
	margin: 0;
	position: absolute;
	top: .5rem;
	width: 1rem;

	&:before {
		color: #0d497f;
		position: absolute;
		left: 0;
		top: 0;
		font-size: .9375rem;
	}
}

.mod_my_latest_list .kicker {
	margin-bottom: .3125rem;
}

.mod_my_latest_list .hl,
.mod_my_latest_list .sub,
.mod_my_latest_list .bd {
	margin-left: 4.625rem;
}

.my_latest_sort {
	background-color: transparent;
	color: #6f6f6f;
	font-size: .875rem;
	height: 2.125rem;
	line-height: 1.0625rem;
	text-align: center;
}

.my_latest_sort fieldset {
	padding: .5625rem 0 .5rem;
}

.my_latest_sort input[type=radio] {
	margin-left: 1.125rem;
}

/* search */
.mod_search_filter {
	margin-bottom: 1.25rem !important;
	overflow: visible !important;
}

.mod_search_filter .hd {
	margin-top: .625rem;
}

.mod_search_filter form {
	background-color: #fff;
	border-bottom: .0625rem solid #e3dfdc;
}

.mod_search_filter fieldset {
	background-color: #faf9f8;
}

.mod_search_filter legend {
	background-color: #fff;
	border-bottom: .1875rem solid #409bb9;
	font-size: 1rem;
	line-height: 1.125rem;
	padding-bottom: .3125rem;
	width: 100%;
}

.mod_search_filter .search_term {
	color: #000;
}

.mod_search_filter label {
	color: #6f6f6f;
	font-size: .875rem;
	line-height: 1.125rem;
}

.mod_search_filter .inner {
	border-bottom: .0625rem solid #fff;
	border-top: .0625rem solid #fff;
	padding: .625rem;
	position: relative;
}

.mod_search_filter .txt {
	border-bottom: .0625rem solid #e3dfdc;
	font-size: .8125rem;
	line-height: 1rem;
	margin-bottom: .625rem;
	padding-bottom: .625rem;
}

.mod_search_filter .txt .label {
	color: #409bb9;
	float: left;
	font-weight: bold;
	margin-right: .625rem;
}

.mod_search_filter .txt .name {
	float: left;
	margin-right: .3125rem;
}

.mod_search_filter .txt .delete {
	@include icon("times");
	float: left;
	margin-right: .3125rem;
	text-align: center;

	&:before {
		background: #ccc;
		border-radius: .25rem;
		color: #fff;
		display: block;
		font-size: .75rem;
		height: 1rem;
		line-height: 1rem;
		width: 1rem;
	}
}

.mod_search_filter .txt .delete:hover:before {
	background: #c01d2e;
}

.mod_search_filter .unit {
	float: left;
	margin-bottom: .1875rem;
	width: 50%;
}

.mod_search_filter .search_options {
	margin: 0 0 .5rem 0;
	overflow: hidden;
}

.mod_search_filter .search_order {
	margin: 0 0 .5rem 0;
	overflow: hidden;
}

.mod_search_filter .search_order .label {
	margin-right: .625rem;
}

.mod_search_filter .search_order .radio {
	margin-left: .625rem;
}

.mod_search_filter .search_order .radioLabel {
	margin-left: .1875rem;
}

.mod_search_filter .search_filter_query label {
	display: block;
	float: left;
}

.mod_search_filter .search_filter_query .search_filter_text {
	float: left;
	margin-left: .625rem;
	position: relative;
}

.mod_search_filter .search_filter_query input {
	padding: .0625rem;
	width: 9.6875rem;
}

.mod_search_filter input[type=checkbox] {
	vertical-align: text-top;
}

.mod_search_filter input[type=radio] {
	vertical-align: baseline;
}

.mod_search_filter input[type=submit],
.mod_search_filter .submit {
	bottom: .4375rem;
	font-size: .875rem;
	position: absolute;
	right: .625rem;
}

.mod_search_filter .suggest {
	background: #f9f9f9;
	border: .0625rem solid #ccc;
	border-radius: 0 .25rem .25rem .25rem;
	display: none;
	left: 0;
	padding: .0625rem 0;
	position: absolute;
	top: 1.0625rem;
	min-width: 9.8125rem;
	z-index: 5000070;
}

.mod_search_filter .suggest .group {
	border-top: .0625rem solid #ded9d3;
}

.mod_search_filter .suggest .group:first-child {
	border-top: none;
}

.mod_search_filter .suggest .group li {
	border-top: .0625rem solid #f1eee9;
}

.mod_search_filter .suggest .group li:hover,
.mod_search_filter .suggest .group li.active {
	background-color: #fff;
}

.mod_search_filter .suggest .group li:first-child {
	border-top: none;
}

.mod_search_filter .suggest a {
	display: block;
	font-size: .6875rem;
	line-height: .9375rem;
	padding: .125rem .625rem;
}

.mod_search_filter .suggest a:hover {
	text-decoration: none;
}

.mod_search_filter .suggest a span {
	cursor: pointer;
}

.mod_search_filter .suggest a .term {
	float: left;
}

.mod_search_filter .suggest a .type {
	color: #d9d6d0;
	float: right;
	margin-left: .1875rem;
}

.mod_search_filter .suggest .group li:first-child .type {
	color: #6f6f6f;
}

.mod_search_result {
	overflow: hidden;
}

.mod_search_result .inner {
	position: relative;
}

.mod_search_result .icon {
	@include icon("heart-rate");
	height: .875rem;
	position: absolute;
	right: 0;
	top: 0;
	width: 1.125rem;

	&:before {
		color: #999;
		font-size: 1rem;
	}
}

.mod_search_result .list li {
	border-bottom: .0625rem solid #e3dfdc;
	padding: .6875rem 0 .9375rem;
}

.mod_search_result .kicker {
	color: #6f6f6f;
	font-size: .6875rem;
	line-height: 1rem;
}

.mod_search_result .info {
	display: block;
	float: right;
}

.mod_search_result .type {
	font-weight: bold;
	margin-right: .625rem;
}

.mod_search_result .hl {
	font-size: 1rem;
	font-weight: bold;
	line-height: 1.4375rem;
	margin-bottom: .375rem;
}

.mod_search_result .inner .ad {
	display: block;
	position: relative;
	padding: 0 3.125rem 0 0;
}

.mod_search_result .inner .ad::after {
	color: #333;
	content: "anzeige";
	font-size: .75rem;
	font-variant: small-caps;
	font-weight: normal;
	padding: 0 0 0 .3125rem;
	position: absolute;
	right: 0;
	top: 0;
}

.mod_search_result .img {
	float: left;
	width: 6.375rem;
}

.mod_search_result .bd {
	color: #000;
	font-size: .8125rem;
	line-height: 1rem;
}

.mod_search_result .bd a {
	color: #000;
	font-weight: bold;
}

.mod_search_result .original {
	display: none;
}

.mod_search_result .paging_group {
	display: none;
}

/* medicine search */

.mod_search_result .medicine .mainInformation h3.hd {
	margin-bottom: .375rem;
}

.mod_search_result .medicine .mainInformation p.substance,
.mod_search_result .medicine .mainInformation p.manufacturer {
	float: left;
	width: 64%;
}

.mod_search_result .medicine .mainInformation span.prescription {
	float: right;
	text-align: right;
	width: 35%;
}

.mod_search_result .medicine .mainInformation span.prescription.yes {
	color: #F61212;
}

.mod_search_result .medicine .mainInformation span.prescription.no {
	color: green;
}

.mod_search_result .medicine .mainInformation a.more {
	float: right;
}

.mod_search_result .medicine .mainInformation a.more span.wait {
	background: transparent url(./fileuploader/images/loading.gif) scroll no-repeat center center;
	display: inline-block;
	height: 1em;
	width: 1.25rem;
}

.mod_search_result .list li .ilist li {
	border-bottom: none;
	padding-bottom: 0;
	padding-left: .875rem;
	padding-top: 0;
}

.mod_search_result .medicine .moreInformation {
	clear: both;
	display: none;
}

.mod_search_result .medicine .moreInformation h4 {
	margin: .75rem 0 .1875rem 0;
}

.mod_search_result .medicine .moreInformation p {
	margin: 0 0 .5em 0;
}

/* mod_text */
.mod_text {
	color: #000;
	font-size: .8125rem;
	line-height: 1.0625rem;
}

/* mod_select */
.mod_select {
	border-bottom: .0625rem solid #e6e3dc;
}

.mod_select form {
	border-bottom: .0625rem solid #fff;
	padding: .9375rem .625rem;
}

.mod_select select {
	margin-bottom: .875rem;
	width: 13.375rem;
}

.mod_select label {
	font-size: .8125rem;
	font-weight: bold;
	line-height: 1.375rem;
}

.mod_select .input {
	float: right;
	position: relative
}

.mod_select input[type=text] {
	height: 1.1875rem;
	padding-right: 1.4375rem;
	width: 19.8125rem;
}

.mod_select input[type=submit] {
	background: none;
	height: 1.4375rem;
	position: absolute;
	right: 0;
	top: 0;
	width: 1.4375rem;
}

.mod_select .left {
	float: left;
}

.mod_select .right {
	float: right;
}

/* mod_forum_create */

.mod_forum_create form {
	background: none;
}

.mod_forum_create .line {
	margin: .4375rem 0 .4375rem 0;
}

.mod_forum_create .line .left {
	float: left;
	line-height: 1.25rem;
	margin-right: .625rem;
	width: 7.5rem;
}

.mod_forum_create .line .left label {
	font-weight: bold;
}

.mod_forum_create .line .right {
	float: right;
	line-height: 1.25rem;
	width: 21.25rem;
}

.mod_forum_create .line .right input[type="text"] {
	padding: .125rem;
	width: 20.875rem;
}

.mod_forum_create .line .right textarea {
	padding: .125rem;
	width: 20.875rem;
}

.mod_forum_create .line .right select {
	padding: .125rem;
	width: 100%;
}

.mod_forum_create .line .right input[type="checkbox"],
.mod_forum_create .line .right input[type="radio"] {
	vertical-align: middle;
}

.mod_forum_create .line .right label {
	margin-left: .1875rem;
	margin-right: .375rem;
	vertical-align: middle;
}

.mod_forum_create .error_message {
	color: #973544;
	font-weight: bold;
}

.mod_forum_create .success_message {
	color: #1cab00;
	font-weight: bold;
}

.mod_forum_create .success_message a {
	color: #000;
}

/* mod_question */
.mod_u_question {
	margin: 0 !important;
}

.mod_u_question .navbar .buttons {
	margin: 0 0 .9375rem 0;
}

.mod_u_question .navbar .btnBack {
	float: left;
	margin-left: -0.1875rem;
	margin-right: .6875rem;
}

.mod_u_question .navbar .btnNew {
	float: left;
}

.mod_u_question .uicon {
	height: 3.3125rem;
	position: absolute;
	width: 3.3125rem;
}

.mod_u_question .inner {
	margin-left: 5.875rem;
	overflow: hidden;
}

.mod_u_question .kicker {
	font-size: .8125rem;
	line-height: 1rem;
	margin-bottom: 1.25rem;
}

.mod_u_question .category {
	font-weight: normal;
}

.mod_u_question .hl {
	color: #000;
	font-size: 1rem;
	font-weight: bold;
	line-height: 1.125rem;
	margin-bottom: .4375rem;
}

.mod_u_question .bd {
	color: #333333;
	font-size: .8125rem;
	line-height: 1rem;
	margin-bottom: 1.0625rem;
}

.mod_u_question .rate {
	border-top: .0625rem solid #e3dfdc;
	color: #333333;
	font-size: .8125rem;
	height: 3.4375rem;
	line-height: 1.125rem;
}

.mod_u_question .rate .rating_label {
	float: left;
	font-style: italic;
	margin-top: .5rem;
}

.mod_u_question .rate .rating {
	float: right;
	margin-top: .25rem;
	width: 5.6875rem;
}

.mod_u_question .rate .rating form {
	border: none;
	display: block;
	float: left;
	height: 1.5rem;
	margin-top: .125rem;
	padding: 0;
	width: 1.75rem;
}

.mod_u_question .rate .ratingbtn {
	display: block;
	float: left;
	font-size: 1.125rem;
	text-align: center;
	height: 1.5rem;
	line-height: 1.5rem;
	margin-top: 0;
	width: 1.75rem;
}

.mod_u_question .rate .ratingbtn.disabled {
	background: linear-gradient(180deg, rgba(194, 195, 196, 1) 0%, rgba(142, 143, 144, 1) 100%);
}

.mod_u_question .rate .badge {
	color: #409bb9;
	display: block;
	float: right;
	font-size: .75rem;
	font-weight: bold;
	height: 1.8125rem;
	line-height: 1.875rem;
	width: 3.6875rem;
}

.mod_u_question form {
	border-bottom: .0625rem solid #e3dfdc;
	border-top: .0625rem solid #e3dfdc;
	padding: 0 .625rem;
}

.mod_u_question form legend {
	padding: 1.0625rem 0;
}

.mod_u_question form textarea {
	height: 4.6875rem;
	width: 28rem;
}

.mod_u_question form input[type=submit] {
	float: right;
	margin-top: .75rem;
}

.mod_u_question form .txt {
	font-size: .8125rem;
	line-height: 1.0625rem;
	margin: .625rem 0;
}

/* Frage stellen */
.mod_ask {
	background: #E6E3DC;
	height: 7.8125rem;
	width: 30rem;
}

.mod_ask form {
	background: transparent;
	padding: 1.5rem 2.375rem 0 1.875rem;
}

.mod_ask legend {
	color: #f61212;
	font-size: 1.25rem;
	font-weight: normal;
	margin-bottom: .375rem;
}

.mod_ask p {
	color: #333333;
	font-size: .8125rem;
	line-height: 1.125rem;
	margin-bottom: .625rem;
}

.mod_ask input[type=text] {
	border-width: .0625rem;
	height: 1.1875rem;
	margin-bottom: .6875rem;
	padding: .0625rem;
	width: 22.6875rem;
}

.mod_ask label {
	font-size: .8125rem;
	font-weight: bold;
	line-height: 1.3125rem;
}

.mod_ask .rlabel {
	display: inline;
	font-weight: bold;
	letter-spacing: -0.02em;
	line-height: 1.6875rem;
}

.mod_ask input[type=radio] {
	margin-left: .9375rem;
}

.mod_ask input[type=submit] {
	float: right;
	width: 5.75rem;
}

.mod_ask .left {
	float: left;
	width: 2.8125rem;
}

.mod_ask .right {
	float: right;
	width: 22.9375rem;
}

.aside .mod_ask {
	background: #E6E3DC;
	height: 7.8125rem;
	width: 18.75rem;
}

.aside .mod_ask form {
	padding: 1.125rem 1.25rem 0 .9375rem;
}

.aside .mod_ask input[type=text] {
	width: 13.5rem;
}

.aside .mod_ask .rlabel,
.aside .mod_ask .right label,
.aside .mod_ask .right input[type=radio] {
	display: none;
}

.aside .mod_ask .right {
	width: 13.75rem;
}

.mod_ask.mod_medicine .left {
	width: 5.625rem;
}

.mod_ask.mod_medicine .right {
	width: 20.125rem;
}

.mod_ask.mod_medicine input[type="text"] {
	width: 19.875rem;
}

.mod_hb_zentrumsuche form#herzzentrum_suche_result_form {
	background: #E6E3DC;
	height: 8.125rem;
	padding: 1.5rem 2.375rem 0 1.875rem;
	width: 25.75rem;
}

.mod_hb_zentrumsuche form {
	background: #E6E3DC;
	height: 26.875rem;
	padding: 1.5rem 2.375rem 0 1.875rem;
	width: 25.75rem;
}

.mod_hb_zentrumsuche legend {
	color: #f61212;
	font-size: 1.25rem;
	font-weight: normal;
	margin-bottom: .375rem;
}

.mod_hb_zentrumsuche p {
	color: #333333;
	font-size: .8125rem;
	line-height: 1.125rem;
	margin-bottom: .625rem;
}

.mod_hb_zentrumsuche input[type=text] {
	border-width: .0625rem;
	height: 1.1875rem;
	margin-bottom: .1875rem;
	padding: .0625rem;
}

.mod_hb_zentrumsuche label {
	font-size: .8125rem;
	font-weight: bold;
	line-height: 1.3125rem;
	margin-left: .5em;
}

.mod_hb_zentrumsuche .rlabel {
	display: inline;
	font-weight: bold;
	letter-spacing: -0.02em;
	line-height: 1.6875rem;
}

.mod_hb_zentrumsuche input[type=radio] {
	margin-left: .9375rem;
}

.mod_hb_zentrumsuche input[type=submit] {
	float: right;
	width: 5.75rem;
	margin-bottom: 1.25rem;
}

.mod_hb_zentrumsuche .left {
	float: left;
	width: 2.8125rem;
}

.mod_hb_zentrumsuche .right {
	float: right;
	width: 22.9375rem;
}

.mod_hb_zentrumsuche .left {
	width: 6.875rem;
}

.mod_hb_zentrumsuche .right {
	width: 18.75rem;
}

.mod_hb_zentrumsuche input[type="text"] {
	margin-left: .625rem;
	width: 9.375rem;
}

.mod_hb_zentrumsuche fieldset {
	margin-bottom: .625rem;
}

.mod_hb_zentrumsuche li {
	list-style: none;
	margin-bottom: .35em;
}

.mod_hb_zentrumsuche li.info_li {
	margin-top: 2em;
}

#herzzentrum_suche_default_div .zentrum_name {
	font-weight: bold;
	color: #409bb9;
}

/* med search */
.mod_med_search form {
	background: #E6E3DC;
	height: 22.8125rem;
	margin-bottom: 1.25rem;
	padding: 1.875rem 2.375rem 0 1.875rem;
	width: 25.75rem;
}

.mod_med_search legend {
	color: #f61212;
	font-size: 1.25rem;
	line-height: 1.5rem;
}

.mod_med_search label {
	font-weight: bold;
}

.mod_med_search .txt {
	background: transparent url("../img/arztsuche/gaecd_logo.png") no-repeat right 50%;
	border-bottom: .0625rem solid #999;
	line-height: 1.5rem;
	margin: 0 0 1.5rem;
	padding: 1rem 0;
}

.mod_med_search .line {
	line-height: 1.25rem;
	margin-top: .625rem;
}

.mod_med_search .line .left {
	float: left;
	padding-right: .8125rem;
	text-align: right;
	width: 9.375rem;
}

.mod_med_search .line .right {
	float: right;
	width: 15.5625rem;
}

.mod_med_search input[type=text] {
	width: 15.1875rem;
}

.mod_med_search input.plz {
	width: 4.75rem;
}

.mod_med_search select {
	width: 100%;
}

.mod_med_search .submit {
	float: right;
	margin-top: .625rem;
}

.mod_med_search .results .hl {
	border-bottom: .0625rem solid #e3dfdc;
	line-height: 1.25rem;
}

.mod_med_search .results .hl .left {
	float: left;
	font-weight: bold;
}

.mod_med_search .results .hl .right {
	float: right;
}

.mod_med_search .result {
	border-bottom: .0625rem solid #e3dfdc;
	padding: .8125rem 0;
}

.mod_med_search .result .left {
	float: left;
	margin-right: 1rem;
	text-align: center;
	width: 5rem;
}

.mod_med_search .result .right {
	float: right;
	width: 23.375rem;
}

.mod_med_search .result .right div {
	margin-bottom: .3125rem;
}

.mod_med_search .result .img img {
	margin-bottom: .3125rem;
}

.mod_med_search .result .med_office {
	font-weight: bold;
}

.mod_med_search .result .url {
	font-weight: bold;
}

.mod_med_search_hor form {
	position: relative;
}

.mod_med_search_hor .note {
	position: absolute;
	right: 2.375rem;
	top: 2.375rem;
}

.mod_med_search_hor .txt {
	background: none;
	line-height: 1rem;
	padding-top: .625rem;
}

.mod_med_search_hor .note_txt {
	margin: 0;
	padding: .625rem 0;
}

.mod_med_search_hor .note_txt h5 {
	margin-bottom: .625rem;
}

.mod_med_search_hor .result .left {
	text-align: left;
	width: 21.5rem;
}

.mod_med_search_hor .result .right {
	text-align: right;
	width: 6.875rem;
}

.mod_med_search_hor .result img {
	margin: .1875rem 0 0 .3125rem;
}

.mod_med_search_hor .result .txt {
	border: none;
	margin: 0;
	padding: 0;
}

.mod_med_detail .left {
	float: left;
	margin-right: 1rem;
	width: 8.75rem;
}

.mod_med_detail .right {
	float: right;
	width: 22.5rem;
}

.mod_med_detail .img {
	border: .0625rem solid #cdcdcd;
	padding: .25rem;
}

.mod_med_detail .img img {
	display: block;
}

.mod_med_detail .hl {
	border-bottom: .0625rem solid #e3dfdc;
	color: #409bb9;
	font-size: 1rem;
	font-weight: bold;
	padding-bottom: .1875rem;
}

.mod_med_detail .ilist li {
	background-position-y: -39rem;
	border-bottom: .0625rem solid #e3dfdc;
	line-height: 1.375rem;
}

.mod_med_detail .head {
	margin-bottom: 1.25rem;
	padding-top: .75rem;
}

.mod_med_detail .head .name {
	font-size: 1rem;
	font-weight: bold;
	margin-bottom: .625rem;
}

.mod_med_detail .head .specialist {
	margin-bottom: 1rem;
}

.mod_med_detail .head .hl {
	color: #000;
	font-size: .75rem;
	line-height: .875rem;
}

.mod_med_detail .head .ilist li {
	border: none;
}

.mod_med_detail .info,
.mod_med_detail .list {
	margin-bottom: 1.25rem;
}

.mod_med_detail .info .txt {
	line-height: 1rem;
	margin-top: .3125rem;
}

/* Autorenprofil */
.mod_author_profile {
	overflow: hidden;
}

.mod_author_profile .img-wrapper {
	float: left;
	margin: 0 1rem 1.25rem 0;
	max-width: 14.25rem;
}

.mod_author_profile .img {
	border: .0625rem solid #cdcdcd;
	display: block;
	margin-bottom: .25rem;
	padding: .25rem;
}

.mod_author_profile .img-wrapper .rights {
	color: #999999;
	font-size: .6875rem;
	line-height: 1.0625rem;
}

.mod_author_profile .meta-infos {
	float: left;
	margin-bottom: 1.25rem;
}

.mod_author_profile .head {
	padding-top: 1rem;
}

.mod_author_profile .head .name {
	font-size: 1rem;
	font-weight: bold;
	margin-bottom: .625rem;
}

.mod_author_profile .head .agentur,
.mod_author_profile .head .job {
	margin-bottom: 1rem;
}

.mod_author_profile .head .ilist li {
	border: none;
}

.mod_author_profile .info,
.mod_author_profile .list {
	margin-bottom: 1.25rem;
}

.mod_author_profile .info .txt {
	line-height: 1rem;
	margin-top: .3125rem;
}

/* 7.3.01_top-themen-box.html */
.mod_top_topics {
	color: #fff;
	height: 24.375rem;
	position: relative;
	width: 32.5rem;
}

.mod_top_topics .slider_img {
	background: #F2F1ED;
	height: 24.375rem;
	overflow: hidden;
	position: relative;
}

.mod_top_topics .slider_img .slide_cont {
	height: 24.375rem;
	position: absolute;
}

.mod_top_topics .slider_img .slide_cont .img {
	display: block;
	float: left;
	width: 32.5rem;
}

.mod_top_topics .slider_img .slide_cont .img_ad {
	height: 15.625rem;
	padding: 1.375rem 5.625rem 5.5rem 5.625rem;
	width: 18.75rem;
}

.mod_top_topics .control {
	bottom: .3125rem;
	left: .625rem;
	position: absolute;
	width: 31.25rem;
}

.mod_top_topics .slider_txt {
	bottom: 0;
	left: 3rem;
	position: absolute;
	width: 25.25rem;
}

.mod_top_topics .slider_txt .slide_cont {
	bottom: 0;
}

.mod_top_topics .slider_txt .hl {
	font-size: 1.625rem;
	font-weight: normal;
	line-height: 1.875rem;
}

.mod_top_topics .slider_txt .hl a {
	color: #fff;
}

.mod_top_topics .slider_txt .hl .ad {
	display: block;
	position: relative;
	padding: 0 3.125rem 0 0;
}

.mod_top_topics .slider_txt .hl .ad::after {
	color: #fff;
	content: "anzeige";
	font-size: .75rem;
	font-variant: small-caps;
	font-weight: normal;
	padding: 0 0 0 .3125rem;
	position: absolute;
	right: 0;
	top: 0;
}

.mod_top_topics .slider_txt .txt {
	font-size: .875rem;
	line-height: 1.125rem;
}

.mod_top_topics .slider_txt .txt a {
	color: #fff;
}

.mod_top_topics .prev,
.mod_top_topics .unit,
.mod_top_topics .next {
	background: rgba(0, 0, 0, .4);
}

.mod_top_topics .prev,
.mod_top_topics .next {
	bottom: 0;
	display: none;
	position: absolute;
	width: 3rem;
}

.mod_top_topics .unit {
	bottom: 0;
	left: 0;
	margin: 0 .0625rem;
	min-height: 5rem;
	padding: .0625rem .8125rem 0;
	position: absolute;
	visibility: hidden;
	width: 23.5rem;
}

.mod_top_topics .active {
	visibility: visible;
}

.mod_top_topics .next {
	right: 0;
}

.mod_top_topics .prev {
	left: 0;
}

.mod_top_topics .prev a,
.mod_top_topics .next a {
	cursor: pointer;
	display: block;
	height: 2.3125rem;
	margin: 0 auto;
	text-align: center;
	width: 2.3125rem;
}

.mod_top_topics .prev a:hover,
.mod_top_topics .next a:hover {
	text-decoration: none;
}

.mod_top_topics .prev a:before,
.mod_top_topics .next a:before {
	color: #FFF;
	font-size: 2rem;
	line-height: 2.3125rem;
}

.mod_top_topics .prev a {
	@include icon("angle-left");

	&:before {
		margin-left: -0.1875rem;
	}
}

.mod_top_topics .next a {
	@include icon("angle-right");

	&:before {
		margin-right: -0.1875rem;
	}
}

/* 7.3.01_top-themen-box.html Zoom */
.mod_top_topics_zoom {
	color: #fff;
	height: 24.375rem;
	position: relative;
	width: 32.5rem;
}

.mod_top_topics_zoom .slider_img {
	background: #F2F1ED;
	height: 24.375rem;
	overflow: hidden;
	position: relative;
}

.mod_top_topics_zoom .slider_img .slide_cont {
	height: 24.375rem;
	position: relative;
}

.mod_top_topics_zoom .slider_img .slide_cont .img {
	display: none;
	height: 32.5rem;
	left: 0;
	position: absolute;
	top: -3.75rem;
	width: 32.5rem;
}

.mod_top_topics_zoom .slider_img .slide_cont .img_ad {
	left: 0;
	height: 15.625rem;
	padding: 1.375rem 5.625rem 5.5rem 5.625rem;
	top: 0;
	width: 18.75rem;
}

.mod_top_topics_zoom .slider_img .slide_cont .active {
	display: block;
}

.mod_top_topics_zoom .slider_img .slide_cont .img img {
	height: 100%;
	width: 100%;
}

.mod_top_topics_zoom .slider_img .slide_cont .img_ad img {
	height: auto;
	width: auto;
}

.mod_top_topics_zoom .control {
	bottom: .3125rem;
	left: .625rem;
	position: absolute;
	width: 31.25rem;
}

.mod_top_topics_zoom .slider_txt {
	bottom: 0;
	left: 3rem;
	position: absolute;
	width: 25.25rem;
}

.mod_top_topics_zoom .slider_txt .slide_cont {
	bottom: 0;
}

.mod_top_topics_zoom .slider_txt .hl {
	font-family: Georgia, "Times New Roman", serif;
	font-size: 1.625rem;
	font-weight: normal;
	line-height: 1.875rem;
}

.mod_top_topics_zoom .slider_txt .hl a {
	color: #fff;
}

.mod_top_topics_zoom .slider_txt .txt {
	font-size: .875rem;
	line-height: 1.125rem;
}

.mod_top_topics_zoom .slider_txt .txt a {
	color: #fff;
}

.mod_top_topics_zoom .prev,
.mod_top_topics_zoom .unit,
.mod_top_topics_zoom .next {
	background: rgba(0, 0, 0, .4);
}

.mod_top_topics_zoom .prev,
.mod_top_topics_zoom .next {
	bottom: 0;
	display: none;
	position: absolute;
	width: 3rem;
}

.mod_top_topics_zoom .unit {
	bottom: 0;
	left: 0;
	margin: 0 .0625rem;
	min-height: 5rem;
	padding: .0625rem .8125rem 0;
	position: absolute;
	visibility: hidden;
	width: 23.5rem;
}

.mod_top_topics_zoom .active {
	visibility: visible;
}

.mod_top_topics_zoom .next {
	right: 0;
}

.mod_top_topics_zoom .prev {
	left: 0;
}

.mod_top_topics_zoom .prev a,
.mod_top_topics_zoom .next a {
	@include icon-styles();
	background: #c01d2e;
	border-radius: 50%;
	box-shadow: 0rem 0rem .125rem #fff;
	cursor: pointer;
	color: #FFF;
	display: block;
	font-size: 1.5rem;
	height: 2.3125rem;
	line-height: 2.3125rem;
	margin: 0 auto;
	text-align: center;
	width: 2.3125rem;
}

.mod_top_topics_zoom .prev a:before {
	content: icon-char("caret-left");
	margin-left: -0.1875rem;
}

.mod_top_topics_zoom .next a:before {
	content: icon-char("caret-right");
	margin-left: .1875rem;
}

/* Mod topics wide */

.mod_top_topics--wide {
	height: 29.5625rem;
	width: 52.625rem;
}

.mod_top_topics--wide .slider_img {
	height: 29.5625rem;
}

.mod_top_topics--wide .slider_img .slide_cont {
	height: 29.5625rem;
}

.mod_top_topics--wide .slider_img .slide_cont .img {
	width: 52.625rem;
}

.mod_top_topics--wide .control {
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	width: 39.375rem;
}

.mod_top_topics--wide .unit {
	width: 31.5625rem;
}

/* 7.3.02_a-z-element.html */
.mod_atoz .tabs .active {
	border-bottom: .0625rem solid #f7f7f4;
}

.mod_atoz .tabs a {
	padding-left: .625rem;
	padding-right: .625rem;
}

.mod_atoz .tabs .active a {
	background-color: #f7f7f4;
}

.mod_atoz .mod_tab {
	display: none;
}

.mod_atoz .active {
	display: block;
}

.mod_atoz .bd {
	background-color: #f7f7f4;
	border-color: #cccccc;
	border-style: solid;
	border-width: 0 .0625rem .0625rem .0625rem;
	padding-bottom: .3125rem;
	position: relative;
}

.mod_atoz .txt {
	font-size: .8125rem;
	line-height: 1.0625rem;
	padding: .5rem .625rem;
}

.mod_atoz .nav {
	margin: 0 0 0 .625rem;
	padding-bottom: .3125rem;
}

.mod_atoz .nav li {
	display: inline;
}

.mod_atoz .nav .btn {
	background: linear-gradient(180deg, #136fc0 0%, #0d4a80 100%);
	color: #fff;
	display: block;
	float: left;
	height: 1.0625rem;
	line-height: 1.0625rem;
	margin-right: .0625rem;
	text-align: center;
	width: 1rem;
}

.mod_atoz .nav .btn:hover {
	background: #0d4a80;
	text-decoration: none;
}

.mod_atoz .bd_open {
	background-color: #f7f7f4;
	height: auto;
}

.mod_atoz .bd_open .result {
	color: #409bb9;
	font-size: .875rem;
	font-weight: bold;
	line-height: 1.4375rem;
	padding: .1875rem .625rem .3125rem;
}

.mod_atoz .bd_open .ilist {
	border-top: .0625rem solid #e6e3dc;
	padding: 0 .625rem;
	width: 28rem;
}

.mod_atoz .bd_open .left {
	float: left;
	width: 13.375rem;
}

.mod_atoz .bd_open .right {
	float: right;
	width: 13.375rem;
}

.mod_atoz .bd_open .ilist li {
	border-top: .0625rem solid #e6e3dc;
	font-size: .8125rem;
	line-height: 1.125rem;
	padding: .125rem 0 .125rem 1.75rem;
}

.mod_atoz .bd_open .ilist li.first {
	border-top: none;
}

/* 7.4.03_visual.html */
.mod_visual {
	border-bottom: .1875rem solid #409bb9;
	margin-bottom: 1.5625rem;
	min-height: 1.875rem;
	padding-bottom: .625rem;
	position: relative;
}

.mod_visual a {
	display: block;
}

.mod_visual .txt {
	bottom: .625rem;
	color: #409BB9;
	display: block;
	font-size: 1.6875rem;
	font-weight: bold;
	left: 0;
	line-height: 1.6875rem;
	padding-left: .625rem;
	padding-top: 0;
	position: absolute;
	text-align: left;
	width: 100%;
}

.mod_visual .longtxt {
	bottom: .625rem;
	color: #409BB9;
	display: block;
	font-size: 1.6875rem;
	font-weight: bold;
	left: 0;
	line-height: 1.6875rem;
	padding-left: .625rem;
	padding-top: 0;
	text-align: left;
	width: 100%;
}

.mod_visual img {
	height: auto;
	max-width: 100%;
}

/* 7.3.04_teaser-clip-hauptrubriken.html */
.mod_teaser .sep {
	border-bottom: .0625rem solid #e3dfdc;
}

.mod_teaser .topic {
	padding-top: .8125rem;
	text-align: right;
}

.mod_teaser .topic .more {
	color: #6f6f6f;
}

.mod_teaser .hd {
	margin-bottom: .625rem;
}

.mod_teaser .bd {
	padding: .625rem 0;
}

.mod_teaser .media .bd {
	padding: 0;
}

.mod_teaser .first {
	padding-top: 0;
}

.mod_teaser .media .img {
	margin-right: .75rem;
}

.mod_teaser .kicker.ad {
	position: relative;
	padding-top: .9375rem;
}

.mod_teaser .kicker.ad::after {
	color: #333;
	content: "anzeige";
	font-variant: small-caps;
	font-size: .7rem;
	font-weight: normal;
	position: absolute;
	top: 0;
	left: .125rem;
}

.mod_teaser .hl {
	font-size: .875rem;
	font-weight: bold;
	line-height: 1.0625rem;
	margin-bottom: .5rem;
}

.mod_teaser .hl a {
	color: #000;
}

.mod_teaser .txt {
	font-size: .8125rem;
	line-height: 1rem;
}

.mod_teaser .txt a {
	color: #000;
	font-weight: bold;
}

.mod_teaser .ft {
	padding: .1875rem 0 .4375rem;
}

.mod_teaser .ilist li {
	margin-bottom: .25rem;
}

.mod_teaser .ilist li a {
	font-size: .8125rem;
	font-weight: bold;
	line-height: .9375rem;
}

.mod_teaser .ilist li a.ad {
	padding: 0;
}

.mod_teaser .ilist li a.ad::after {
	content: "";
}

.mod_teaser .paging {
	margin-top: .6875rem;
}

.content_small .mod_teaser .media .img {
	float: none;
	margin-right: 0;
}

.content_small .mod_teaser .media .img img {
	margin-bottom: .3125rem;
}

.mod_tabs .mod_tab .mod_teaser .first {
	padding-top: .625rem;
}

/* 7.3.05_teaser-clip-rubrik.html */

.mod_clip .list li {
	border-bottom: .0625rem solid #e3dfdc;
}

.mod_clip .media {
	padding: .9375rem 0 1rem;
}

.mod_clip .media .img {
	margin-right: .9375rem;
}

.mod_clip .media .hl {
	font-size: .875rem;
	font-weight: bold;
	line-height: 1.0625rem;
	margin: .125rem 0;
}

.mod_clip .media .kicker {
	color: #6f6f6f;
	display: block;
	font-size: .75rem;
	font-weight: normal;
	line-height: .875rem;
}

.mod_clip .media .kicker.ad {
	padding-top: .6875rem;
	position: relative;
}

.mod_clip .media .kicker.ad::before {
	content: "anzeige";
	font-size: .75rem;
	font-variant: small-caps;
	color: #333;
	position: absolute;
	top: -0.3125rem;
}

.mod_clip .media .txt {
	font-size: .8125rem;
	line-height: 1rem;
}

.mod_clip .txt a {
	font-weight: bold;
}

.mod_clip .topic {
	padding-top: .8125rem;
	text-align: right;
}

.mod_clip .topic .more {
	color: #6f6f6f;
}

.mod_clip .paging {
	margin-top: .6875rem;
}

/* 7.3.06_v1_themenspecial-zusammenfassung.html */
.mod_abstract .hd {
	border: none;
	font-size: 1.25rem;
	line-height: 1.5rem;
	margin-bottom: .5rem;
}

.mod_abstract .bd {
	background-color: #f2f1ed;
	font-size: .875rem;
	font-weight: bold;
	line-height: 1.0625rem;
	padding: .5625rem .625rem;
}

/* 7.3.07_doppelteaser-box.html */

.mod_double .line {
	border-bottom: .0625rem solid #e3dfdc;
	padding: .9375rem 0 .8125rem;
}

.mod_double .first {
	padding: .625rem 0 .8125rem;
}

.mod_double .media {
	width: 15.8125rem;
}

.mod_double .media .img {
	display: block;
	float: none;
	margin-bottom: .4375rem;
	width: auto;
}

.mod_double .left {
	float: left;
}

.mod_double .right {
	float: right;
}

.mod_double .bd {
	display: block;
}

.mod_double .hl {
	font-size: .8125rem;
	font-weight: bold;
	line-height: 1.125rem;
	margin: .1875rem 0;
}

.mod_double .hl a.ad {
	display: block;
	position: relative;
	padding: 0 3.125rem 0 0;
}

.mod_double .hl a.ad::after {
	color: #333;
	content: "anzeige";
	font-size: .75rem;
	font-variant: small-caps;
	font-weight: normal;
	padding: 0 0 0 .3125rem;
	position: absolute;
	right: 0;
	top: 0;
}

.mod_double .txt {
	font-size: .8125rem;
	line-height: 1rem;
}

.mod_double .txt a {
	color: #000;
	font-weight: bold;
}

/* Dreier-Teaser */
.mod_triple {
	border-bottom: .0625rem solid #e3dfdc;
	padding-bottom: .625rem;
}

.mod_triple.no_headline {
	border-top: .0625rem solid #e3dfdc;
	padding-top: .9375rem;
}

.mod_triple .hd {
	margin: 0 0 .9375rem 0;
}

.mod_triple .line {
	margin: 0 0 .3125rem 0;
}

.mod_triple .media {
	float: left;
	margin: 0 0 0 .9375rem;
	position: relative;
	width: 16.25rem;
}

.mod_triple .media.first {
	margin-left: .9375rem;
}

.mod_triple .img {
	display: block;
	float: none;
	margin: 0 0 .625rem 0;
	width: auto;
}

.mod_triple .media .bd {
	margin: 0 0 .3125rem 0;
}

.mod_triple .bd .hl {
	font-size: .9375rem;
	font-weight: bold;
	line-height: 1.25rem;
	margin: 0 0 .25rem 0;
}

.mod_triple .list .hl {
	@include icon("chevron-right");
	font-size: .8125rem;
	line-height: .875rem;
	padding-left: .875rem;
	position: relative;

	&:before {
		color: #0d497f;
		font-size: .75rem;
		left: -0.0625rem;
		position: absolute;
		top: 0;
	}
}

.mod_triple .list a {
	color: #000;
	font-size: .8125rem;
	font-weight: normal;
}

.mod_triple .media.ad:before {
	color: #000;
	content: "Anzeige";
	font-size: .7rem;
	font-weight: bold;
	display: inline-block;
	padding: 0 0 .125rem 0;
}

/* 7.3.08_fragen-antworten-box.html */

.mod_questions .tabs {
	margin-bottom: 1.5625rem;
}

.mod_questions .tabs li a {
	padding: 0 .625rem;
}

.mod_questions .hd {
	border-bottom: .1875rem solid #e3dfdc;
	font-size: 1rem;
	line-height: 1.25rem;
	margin-bottom: 1rem;
}

.mod_questions .uicon {
	left: .75rem;
	height: 3.3125rem;
	padding: .3125rem;
	position: absolute;
	top: 2.6875rem;
	width: 3.3125rem;
}

.mod_questions .list {
	border-top: .0625rem solid #e3dfdc;
	overflow: hidden;
}

.mod_questions .list li {
	position: relative;
}

.mod_questions .list .odd,
.mod_questions .list .even {
	border-top: none;
}

.mod_questions .list .odd .item,
.mod_questions .list .even .item {
	border-bottom: none;
}

.mod_questions .list .badge {
	color: #409bb9;
	font-size: .8125rem;
	font-weight: bold;
	left: .875rem;
	line-height: 1.875rem;
	position: absolute;
	top: 0;
}

.mod_questions .inner {
	font-size: .8125rem;
	line-height: 1.125rem;
	padding: 1.125rem .625rem 1.3125rem .625rem;
	position: relative;
}

.mod_questions .kicker {
	margin-bottom: .375rem;
}

.mod_questions .kicker,
.mod_questions .kicker a {
	color: #6f6f6f;
}

.mod_questions .date {
	float: right;
}

.mod_questions .hl {
	font-size: 1rem;
	font-weight: bold;
	line-height: 1.125rem;
	margin-bottom: .4375rem;
}

.mod_questions .hl a {
	color: #000;
}

.mod_questions .sub {
	color: #6f6f6f;
	font-size: .6875rem;
	margin-bottom: .4375rem;
}

.mod_questions .bd {
	color: #333333;
}

.mod_questions .bd a {
	color: #000;
	font-weight: bold;
}

.mod_questions .answers_overview {
	border-top: none;
	padding: .75rem .625rem;
}

.mod_questions .answers_overview.first {
	border-top: .0625rem solid #e3dfdc;
}

.mod_questions .answers_overview ul {
	list-style: none outside none;
	margin-left: 0;
}

.mod_questions .answers_overview ul ul {
	margin-left: 0;
}

.mod_questions .answers_overview > ul > li {
	padding-top: .1875rem;
	padding-left: .3125rem;
	position: relative;

	&:before {
		background-color: #000;
		content: "";
		left: 0;
		height: .3125rem;
		top: .5625rem;
		position: absolute;
		width: .3125rem;
	}
}

.mod_questions .answers_overview li li {
	padding-left: 1.125rem;
	position: relative;

	&:before {
		background: #000;
		content: "";
		display: block;
		position: absolute;
		left: 1.0625rem;
		top: .3125rem;
		height: .375rem;
		width: .375rem;
	}

	&:after {
		border-left: .125rem solid #000;
		border-bottom: .125rem solid #000;
		display: block;
		content: "";
		height: .625rem;
		left: 0;
		top: -.1875rem;
		position: absolute;
		width: .75rem;
	}
}

.mod_questions .answers_overview li .inner {
	font-size: .6875rem;
	line-height: 1rem;
	margin-left: .625rem;
	padding: 0;
}

.mod_questions .answers_overview li .inner .title {
	font-weight: bold;
}

.mod_questions .answers_overview li .inner.level1 .title {
	font-weight: bold;
	font-size: .875rem;
	line-height: 1.125rem;
}

.mod_questions .answers_overview li .inner .help {
	color: #000;
	font-weight: bold;
}

.mod_questions .answers_overview li .inner .meta {
	font-size: .6875rem;
	line-height: .8125rem;
}

.mod_questions .answers_overview li .inner a {
	font-weight: bold;
}

.mod_questions .answers_overview li .inner.level1 .title a {
	font-weight: bold;
}

.mod_questions .answers_overview li .inner a,
.mod_questions .answers_overview li .inner a:active,
.mod_questions .answers_overview li .inner a:hover {
	color: #000;
}

.mod_questions .answers_overview li .inner a:visited {
	color: #9a9a9a;
}

.mod_questions .answers_overview li .inner.owner .title a {
	color: #354497;
}

.mod_questions .answers_overview li .inner a.user {
	color: #F61212;
	font-weight: normal;
}

.mod_questions .paging {
	margin-top: .6875rem;
}

/* Offene Fragen Experte */

.mod_expert_open_questions .tabs {
	margin-bottom: 1.5625rem;
}

.mod_expert_open_questions .tabs li a {
	padding: 0 .625rem;
}

.mod_expert_open_questions .uicon {
	left: .75rem;
	height: 3.3125rem;
	padding: .3125rem;
	position: absolute;
	top: 2.6875rem;
	width: 3.3125rem;
}

.mod_expert_open_questions .list li {
	padding: .3125rem .625rem !important;
}

.mod_expert_open_questions .list .odd,
.mod_expert_open_questions .list .even {
	border-top: none;
}

.mod_expert_open_questions .list .odd .item,
.mod_expert_open_questions .list .even .item {
	border-bottom: none;
}

.mod_expert_open_questions .list .badge {
	color: #409bb9;
	font-size: .8125rem;
	font-weight: bold;
	left: .875rem;
	line-height: 1.875rem;
	position: absolute;
	top: 0;
}

.mod_expert_open_questions .kicker {
	margin-bottom: .125rem;
}

.mod_expert_open_questions .kicker,
.mod_expert_open_questions .kicker a {
	color: #6f6f6f;
}

.mod_expert_open_questions .date {
	float: right;
}

.mod_expert_open_questions .hl {
	font-size: .875rem !important;
	font-weight: bold;
	line-height: 1.125rem !important;
	margin-bottom: .4375rem;
}

.mod_expert_open_questions .hl a {
	color: #000;
}

.mod_expert_open_questions .sub {
	color: #6f6f6f;
	font-size: .6875rem;
}

.mod_expert_open_questions .bd {
	color: #333333;
	margin-top: .4375rem;
}

.mod_expert_open_questions .bd a {
	color: #000;
	font-weight: bold;
}

.mod_expert_open_questions .paging {
	margin-top: .6875rem;
}

.aside .mod_expert_open_questions .kicker {
	line-height: inherit !important;
}

/* 7.3.09_kommentarmodul_v1.html */
.mod_comment form {
	background-color: #f0eeea;
	border-bottom: .0625rem solid #c9c2bc;
	border-top: .0625rem solid #c9c2bc;
	margin: 0 0 1.25rem 0;
	padding: .625rem;
}

.mod_comment legend {
	padding: .1875rem 0 .75rem;
}

.mod_comment textarea {
	width: 28rem;
}

.mod_comment input[type=submit] {
	float: right;
	margin-top: .75rem;
}

.mod_comment form .txt {
	color: #6f6f6f;
	font-size: .8125rem;
	line-height: 1.0625rem;
	margin: .625rem 0 0;
}

.mod_comment form .txt a {
	color: #f61212;
}

/* 7.3.11_index-box_ergebnistabelle.html */
.content .mod_index .bd .left {
	float: left;
	width: 15.3125rem;
}

.content .mod_index .bd .right {
	float: right;
	width: 15.3125rem;
}

.content .mod_index .withImg .bd .left,
.content .mod_index .withImg .bd .right {
	width: 50%;
}

.content .mod_index .ft {
	padding-top: .8125rem;
	text-align: right;
}

.content .mod_index .ft .more {
	color: #6F6F6F;
}

/* 7.3.12_fragen-beantworten-modul.html - 13.2.7 */
.mod_answers .answerActionbar {
	font-size: .8125rem;
	font-weight: bold;
	height: 1.6875rem;
	line-height: 1.6875rem;
	margin: 0 0 1.875rem 0;
	position: relative;
}

.mod_answers .answerActionbar a.report {
	font-weight: normal;
	margin-left: 5.875rem;
}

.mod_answers .answerActionbar .btnBack {
	float: left;
	margin-left: -0.1875rem;
	margin-right: .6875rem;
}

.mod_answers .answerActionbar .btnNew {
	float: left;
}

.mod_answers .answerActionbar .btnReply {
	position: absolute;
	right: 0;
	top: 0;
}

.mod_answers .list {
	margin: 0 0 1.25rem 0;
}

.mod_answers .list .item {
	padding-top: .9375rem;
}

.mod_answers .answer_content {
	overflow: hidden;
	position: relative;
}

.mod_answers .uicon {
	height: 3.3125rem;
	left: .75rem;
	position: absolute;
	top: 0;
	width: 3.3125rem;
}

.mod_answers .inner {
	margin-left: 5.875rem;
}

.mod_answers .level3 .inner {
	margin-left: 7.5rem;
}

.mod_answers .level4 .inner {
	margin-left: 9.125rem;
}

.mod_answers .inner .report {
	float: right;
	margin-right: .625rem;
}

.mod_answers .inner .sub {
	color: #6f6f6f;
	font-size: .75rem;
	line-height: .875rem;
	margin-bottom: .625rem;
}

.mod_answers .inner .bd {
	margin-bottom: 1.5rem;
}

.mod_answers .inner .bd p {
	margin-bottom: .625rem;
}

.mod_answers .inner .ft {
	margin-bottom: 1.25rem;
}

.mod_answers .inner .ft .more {
	color: #000;
}

.mod_answers .inner .ft .open {
	background-position: right -35.4375rem;
}

.mod_answers .inner form {
	background-color: #f0eeea;
	border-bottom: .0625rem solid #c9c2bc;
	border-top: .0625rem solid #c9c2bc;
	display: none;
	margin: 1.25rem .625rem 1.25rem 0;
	padding: .625rem;
}

.mod_answers .inner legend {
	padding: .1875rem 0 .75rem;
}

.mod_answers .inner textarea {
	width: 21.5rem;
}

.mod_answers .inner .inner textarea {
	width: 19.875rem;
}

.mod_answers .inner input[type=submit] {
	float: right;
	margin-top: .75rem;
}

.mod_answers .inner form .txt {
	color: #6f6f6f;
	font-size: .8125rem;
	line-height: 1.0625rem;
	margin: .625rem 0 0;
}

.mod_answers .inner form .txt a {
	color: #f61212;
}

.mod_answers .paging {
	margin: 0 0 1.25rem 0;
}

.mod_answers_overview ul {
	list-style: none outside none;
	margin-left: .625rem;
}

.mod_answers_overview ul ul {
	margin-left: 0;
}

.mod_answers_overview li {
	padding-top: .1875rem;
	padding-left: .3125rem;
	position: relative;

	&:before {
		background: #000;
		content: "";
		display: block;
		position: absolute;
		left: .3125rem;
		top: .5rem;
		height: .375rem;
		width: .375rem;
	}
}

.mod_answers_overview li li {
	padding-left: 1.125rem;

	&:before {
		left: 1.125rem;
	}

	&:after {
		border-left: .125rem solid #000;
		border-bottom: .125rem solid #000;
		display: block;
		content: "";
		height: .625rem;
		left: .0625rem;
		top: 0;
		position: absolute;
		width: .75rem;
	}
}

.mod_answers_overview li .inner {
	margin-left: .625rem;
}

.mod_answers_overview li .inner a {
	font-weight: bold;
}

.mod_answers_overview li .inner a,
.mod_answers_overview li .inner a:active,
.mod_answers_overview li .inner a:hover {
	color: #000;
}

.mod_answers_overview li .inner a:visited {
	color: #9a9a9a;
}

.mod_answers_overview li .inner.owner a {
	color: #354497;
}

.mod_answers_overview li .inner a.user {
	color: #F61212;
	font-weight: normal;
}

.mod_reply {
	background: #fff;
	margin: 0;
	padding: 1.25rem 0 1.25rem 0;
}

.mod_reply .hd {
	font-size: 1rem;
	font-weight: bold;
	line-height: 1.125rem;
	margin: 0 0 .625rem 0;
	padding-bottom: .1875rem;
	width: 100%;
}

.mod_reply p {
	margin-bottom: 1.25rem;
}

.mod_reply form {
	background: transparent;
	margin: 0 0 1.25rem 0;
}

.mod_reply legend {
	display: none;
}

.mod_reply label,
.mod_reply .label {
	font-size: .8125rem;
	font-weight: bold;
	line-height: 1.125rem;
}

.mod_reply .line {
	margin-bottom: .9375rem;
}

.mod_reply .line p {
	font-size: .8125rem;
	line-height: 1.125rem;
	margin-bottom: 0;
}

.mod_reply .error_message {
	color: #cc0000;
	font-weight: bold;
}

.mod_reply .success_message {
	color: #23987D;
	font-weight: bold;
}

.mod_reply .line .left {
	float: left;
	text-align: right;
	width: 3.125rem;
}

.mod_reply .line .right {
	float: right;
	width: 25.625rem;
}

.mod_reply .line select {
	margin-top: .3125rem;
	width: 8.3125rem;
}

.mod_reply .line input[type=text] {
	width: 25.25rem;
}

.mod_reply .line input[type=radio] {
	margin-left: .3125rem;
}

.mod_reply .line textarea {
	height: 25rem;
	padding: .0625rem;
	width: 25.25rem;
}

.mod_reply .editortoolbar a {
	font-weight: bold;
}

.mod_reply .editortoolbar .btnToggleSmileys {
	@include icon("caret-down");
	display: block;
	float: right;
	padding-left: .75rem;
	position: relative;

	&:before {
		color: #c01d2e;
		position: absolute;
		left: 0;
		top: 0;
		font-size: 1rem;
		line-height: .875rem;
	}
}

.mod_reply .editortoolbar .btnToggleSmileys.opened {
	&:before {
		content: icon-char("caret-up");
	}
}

.mod_reply .editortoolbar .btnToggleSmileys img {
	display: block;
	float: right;
	margin: -0.375rem .1875rem 0 .3125rem;
}

.mod_reply .smileys a {
	display: inline-block;
	margin: 0;
	padding: 0 .25rem 0 .1875rem;
}

.mod_reply .actionbar {
	font-size: .75rem;
}

.mod_reply .actionbar .right {
	border-bottom: .0625rem solid #c9c2bc;
	border-top: .0625rem solid #c9c2bc;
	line-height: 1.6875rem;
	padding: .625rem 0;
	position: relative;
}

.mod_reply .actionbar a {
	float: left;
	font-weight: normal;
}

.mod_reply .actionbar a.btnHtmlMode {
	margin-left: .625rem;
}

.mod_reply .actionbar a.btnCancel {
	float: right;
	margin-right: 1.25rem;
}

.mod_reply .actionbar #createbtn {
	float: right;
}

.mod_reply .actionbar #blogPostPreviewFormBtn {
	float: left;
}

.mod_reply #blogPostPreviewContent {
	float: right;
	padding: 0;
	width: 26.625rem;
}

.mod_reply #blogPostPreviewContent .post_content {
	margin: 0;
	padding: 0;
}

.level2 .mod_reply #blogPostPreviewContent {
	width: 25rem;
}

.level3 .mod_reply #blogPostPreviewContent {
	width: 23.375rem;
}

/* 7.3.15_tag-cloud-modul.html */
.mod_tag_cloud .hd {
	border: none;
	font-size: .875rem;
	line-height: 1.125rem;
}

.mod_tag_cloud .bd {
	background-color: #f7f7f4;
	border-bottom: .0625rem solid #e6e3dc;
	border-top: .0625rem solid #e6e3dc;
	padding: 0 .625rem;
}

.mod_tag_cloud .bd a {
	line-height: 1.5rem;
}

.mod_tag_cloud .tag1 {
	color: #000;
	font-size: .75rem;
}

.mod_tag_cloud .tag2 {
	color: #000;
	font-size: .75rem;
	font-weight: bold;
}

.mod_tag_cloud .tag3 {
	color: #f61212;
	font-size: .75rem;
}

.mod_tag_cloud .tag4 {
	color: #000;
	font-size: .875rem;
	font-weight: bold;
}

.mod_tag_cloud .tag5 {
	color: #f61212;
	font-size: 1.125rem;
	font-weight: bold;
}

.mod_tag_cloud .tag6 {
	color: #000;
	font-size: 1.1875rem;
	font-weight: bold;
}

.mod_tag_cloud .tag7 {
	color: #f61212;
	font-size: 1.25rem;
	font-weight: bold;
}

.mod_tag_cloud .tag8 {
	color: #000;
	font-size: 1.375rem;
}

.mod_tag_cloud .tag9 {
	color: #f61212;
	font-size: 1.5rem;
	font-weight: bold;
}

.mod_tabs .mod_tag_cloud .hd {
	display: block;
	padding-top: .9375rem;
}

/* 7.4.01_daily-dosis.html */
.mod_daily_dosis {
	display: none;
	margin-bottom: .625rem;
	padding-bottom: 2.0625rem;
}

.mod_daily_dosis .aside .mod {
	margin-bottom: 1.25rem !important;
}

.mod_dd_teaser {
	margin-bottom: 1.25rem;
}

.mod_dd_teaser .hd {
	margin-bottom: .5625rem;
}

.mod_dd_teaser .hl {
	color: #000;
	font-size: .875rem;
	font-weight: bold;
	line-height: 1.25rem;
}

.mod_dd_teaser .hl a {
	color: #000;
}

.mod_dd_teaser .hl_ad,
.mod_dd_teaser .hl a.ad {
	display: block;
	position: relative;
	padding: 0 3.125rem 0 0;
}

.mod_dd_teaser .hl_ad::after, .mod_dd_teaser .hl a.ad::after {
	color: #333;
	content: "anzeige";
	font-variant: small-caps;
	font-size: .75rem;
	font-weight: normal;
	padding: 0 0 0 .3125rem;
	position: absolute;
	right: 0;
	top: 0;
}

.mod_dd_teaser .inline_left {
	float: left;
	margin: .25rem .9375rem .625rem 0;
	width: auto;
}

.mod_dd_teaser .inline_right {
	float: right;
	margin: .25rem 0 .625rem .9375rem;
	width: auto;
}

.mod_dd_teaser div.img {
	float: left;
	margin: .25rem .9375rem 1.25rem 0;
}

.mod_dd_teaser .txt {
	font-size: .8125rem;
	line-height: 1.0625rem;
}

.mod_dd_teaser .txt a {
	font-weight: bold;
}

.mod_dd_quiz {
	margin-bottom: 1.25rem;
}

.mod_dd_quiz .hd {
	margin-bottom: .5625rem;
}

.mod_dd_quiz .bd {
	background-color: #e6e3dc;
	min-height: 10.3125rem;
	padding: .9375rem .9375rem 3.125rem;
	position: relative;
}

.mod_dd_quiz form {
	background-color: transparent;
}

.mod_dd_quiz .legend {
	color: #000;
	font-size: 1rem;
	font-weight: normal;
	line-height: 1.1875rem;
	margin-bottom: 1.1875rem;
	white-space: normal;
}

.mod_dd_quiz .line {
	margin-bottom: .25rem;
}

.mod_dd_quiz input[type=radio],
.mod_dd_quiz input[type=checkbox] {
	float: left;
	margin-top: .0625rem;
}

.mod_dd_quiz label {
	display: block;
	font-size: .8125rem;
	line-height: 1.0625rem;
	margin-left: 1.5625rem;
}

.mod_dd_quiz input[type=submit] {
	bottom: .9375rem;
	margin-top: .6875rem;
	position: absolute;
	right: .9375rem;
}

.mod_dd_quiz .txt {
	font-size: .8125rem;
	line-height: 1rem;
}

.mod_dd_quiz .back {
	bottom: .9375rem;
	color: #fff;
	cursor: pointer;
	display: block;
	height: 1.6875rem;
	left: .9375rem;
	line-height: 1.6875rem;
	position: absolute;
	text-align: center;
	width: 5.75rem;
}

.mod_dd_quiz .wrong .result {
	color: #f61212;
	font-size: 1.3125rem;
	font-weight: bold;
	margin-bottom: .1875rem;
}

.mod_dd_quiz .right .result {
	color: #6dc64b;
	font-size: 1.3125rem;
	font-weight: bold;
}

.mod_dd_quiz .right .hl {
	font-size: 1rem;
	line-height: 1.1875rem;
	margin-bottom: .5rem;
}

.mod_dd_rect {
	margin-bottom: 1.25rem;
}

.mod_dd_horoscope .nav {
	border-bottom: .0625rem solid #e6e3dc;
	font-size: .75rem;
	font-weight: bold;
	line-height: 1rem;
	margin-bottom: .625rem;
	padding: .3125rem 0 .1875rem;
}

.mod_dd_horoscope .nav .date {
	float: left;
	width: 10.5625rem;
}

.mod_dd_horoscope .nav .control {
	color: #409bb9;
	float: right;
	position: relative;
	text-align: center;
	width: 8.1875rem;
}

.mod_dd_horoscope .nav .control a {
	background: #c01d2e;
	border-radius: 50%;
	cursor: pointer;
	color: #fff;
	display: block;
	height: 1.1875rem;
	line-height: 1.1875rem;
	position: absolute;
	text-align: center;
	width: 1.1875rem;
}

.mod_dd_horoscope .nav .control .previous {
	@include icon('caret-left');
	left: 0;
	text-indent: -0.125rem;
	top: -0.125rem;
}

.mod_dd_horoscope .nav .control .next {
	@include icon('caret-right');
	right: 0;
	text-indent: .125rem;
	top: -0.125rem;
}

.mod_dd_horoscope .items {
	width: 18.75rem;
	overflow: hidden;
	position: relative;
}

.mod_dd_horoscope .items ul {
	list-style-type: none;
	margin-left: 0;
	margin-right: 0;
	position: relative;
	/* 12 * 300 + .3125rem tolerance... */
	width: 225.3125rem;
}

.mod_dd_horoscope .items ul li {
	float: left;
	list-style-type: none;
	padding: 0 .8125rem;
	width: 17.125rem;
}

.mod_dd_horoscope .items .txt {
	font-size: .8125rem;
	line-height: 1.0625rem;
	white-space: normal;
}

/* 7.4.02_karussell.html */
.mod_caroussel {
	margin: 0;
	padding-bottom: 1.25rem;
}

.mod_caroussel .head {
	position: relative;
}

.mod_caroussel .hd {
	border-color: #e6e3dc;
	line-height: 1.4375rem;
	padding-bottom: .375rem;
}

.mod_caroussel .paging {
	display: none;
	position: absolute;
	right: 0;
	top: .1875rem;
}

.mod_caroussel .paging .previous {
	cursor: pointer;
	height: 1.1875rem;
	padding: 0;
	position: relative;
	width: .9375rem;

	&:before {
		color: #c01d2e;
		font-size: 1.25rem;
	}
}

.mod_caroussel .paging .next {
	cursor: pointer;
	height: 1.1875rem;
	padding: 0;
	position: relative;
	width: .9375rem;

	&:after {
		color: #c01d2e;
		font-size: 1.25rem;
	}
}

.mod_caroussel .paging .btns a {
	margin: 0;
}

.mod_caroussel .wrapper {
	background-color: #f7f7f4;
	border-bottom: .0625rem solid #e6e3dc;
	margin: .0625rem 0;
	padding: 0 .3125rem;
	overflow: hidden;
	position: relative;
	width: 51.875rem;
}

.mod_caroussel .kicker {
	font-size: .8125rem;
	font-weight: bold;
	margin: .75rem 0 -0.4375rem;
}

.mod_caroussel .hl {
	color: #409bb9;
	font-size: 1rem;
	font-weight: bold;
	line-height: 1.4375rem;
	margin: .75rem 0 .4375rem;
}

.mod_caroussel .slider {
	left: 0;
	position: relative;
	width: 3125rem;
}

.mod_caroussel .group {
	float: left;
	width: 51.875rem;
}

.mod_caroussel .group .slot {
	float: left;
	padding: 1.25rem .625rem .8125rem;
}

.mod_caroussel .group .slot.mod_ad::before {
	position: absolute;
	background: #EEE;
	padding: .0625rem;
}

.mod_caroussel .group .slot img {
	height: auto;
	width: 100%;
}

.mod_caroussel .group_col2 .slot {
	width: 24.6875rem;
}

.mod_caroussel .group_col3 .slot {
	width: 16rem;
}

.mod_caroussel .ilist li {
	font-size: .8125rem;
	line-height: .875rem;
	margin-bottom: .3125rem;
}

.mod_caroussel .ilist a:hover {
	color: #cccccc;
}

.mod_caroussel .more {
	float: right;
	margin-top: .3125rem;
}

.content_main .mod_caroussel .wrapper {
	width: 31.8125rem;
}

.content_main .mod_caroussel .group {
	width: 31.8125rem;
}

.content_main .mod_caroussel .group .slot {
	padding-left: .3125rem;
	padding-right: .3125rem;
	width: 9.9375rem;
}

.content_main .mod_caroussel .group_col2 .slot {
	width: 15.25rem;
}

.content_main .mod_caroussel .group_col3 .slot {
	width: 9.9375rem;
}

/* 7.4.04_themenspecial-buttons.html inkl. Themenspecial-Reiter */
.specials {
	margin-bottom: 1.25rem;
}

.specials .bg {
	border-bottom: .0625rem solid #cccccc;
	position: relative;
}

.specials .bg .bg_layer {
	background: linear-gradient(90deg, rgba(44,104,158,1) 0%, rgba(255,255,255,1) 50%);
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.specials .bg .bg_layer,
.specials .bg img {
	border-top-left-radius: .6875rem;
	border-top-right-radius: .6875rem;
}

.specials .bg .txt {
	top: .9375rem;
	display: block;
	font-size: .875rem;
	font-weight: bold;
	left: .625rem;
	letter-spacing: .125rem;
	line-height: .875rem;
	position: absolute;
	text-transform: uppercase;
}

.specials .bg .dark {
	color: #000;
}

.specials .bg .light {
	color: #fff;
}

.specials .bg .tabs {
	border-bottom: none;
	bottom: 0;
	left: 0;
	position: absolute;
}

.specials .hl {
	color: #409bb9;
	font-size: 1.25rem;
	font-weight: bold;
	line-height: 1.5rem;
	margin-bottom: 1.125rem;
	margin-top: .75rem;
}

.specials .buttons {
	border-bottom: .0625rem solid #cccccc;
	padding: .4375rem .25rem .75rem;
}

.specials .buttons li {
	background: linear-gradient(190deg, rgba(238, 238, 238, 1) 0%, rgba(223, 220, 229, 1) 100%);
	display: block;
	float: left;
	height: 4.375rem;
	margin: .1875rem .25rem 0 0;
	width: 9.375rem;
}

.specials .buttons a {
	display: block;
	font-weight: bold;
	height: 4.375rem;
	line-height: .875rem;
	position: relative;
	width: 9.375rem;
}

.specials .buttons a:hover span {
	text-decoration: underline;
}

.specials .buttons .img {
	bottom: 2rem;
	left: 0;
	position: absolute;
	text-align: center;
	width: 9.375rem;
}

.specials .buttons .txt {
	bottom: .875rem;
	display: block;
	left: 0;
	position: absolute;
	text-align: center;
	width: 9.375rem;
}

.content_wide .specials .buttons {
	padding: .4375rem .625rem .75rem;
}

/* 7.5.01_kuechenzuruf.html */
.abstract {
	background-color: #f2f1ed;
	padding: .625rem;
}

/* 7.3.03_stoerer-clipv.html */
.mod_eyecatcher {
	height: 7.8125rem;
	margin-left: -0.25rem;
	position: relative;
	width: 29.8125rem;
}

.mod_eyecatcher .bd {
	border: none !important;
	padding: 1.0625rem .625rem .9375rem 1.25rem !important;
}

.mod_eyecatcher .bd .media {
	padding: 0;
}

.mod_eyecatcher .bd .media .img {
	background-color: #fff;
	border: .0625rem solid #d0cfce;
	-webkit-box-shadow: 0 0 .1875rem #bebcb6;
	-moz-box-shadow: 0 0 .1875rem #bebcb6;
	box-shadow: 0 0 .1875rem #bebcb6;
	height: auto;
	margin-right: .75rem;
	padding: .25rem;
}

.mod_eyecatcher .bd .hl {
	font-weight: bold;
	margin-bottom: .625rem;
}

.mod_eyecatcher .bd .hl a {
	color: #fb0000;
	font-size: 1.25rem;
	line-height: 1.5rem;
}

.mod_eyecatcher .bd .hl span {
	color: #fb0000;
	font-size: 1.25rem;
	line-height: 1.5rem;
}

.mod_eyecatcher .bd .hl a.ad {
	display: block;
	padding: .9375rem 0 0 0;
	position: relative;
}

.mod_eyecatcher .bd .hl a.ad::before {
	color: #333;
	content: "anzeige";
	font-size: .75rem;
	font-variant: small-caps;
	font-weight: normal;
	position: absolute;
	top: -0.3125rem;
}

.mod_eyecatcher .bd .txt {
	font-size: .875rem;
	line-height: 1.0625rem;
}

.mod_eyecatcher .bd .button {
	background: linear-gradient(190deg, rgba(245, 121, 121, 1) 0%, rgba(246, 23, 23, 1) 100%);
	border-radius: .3125rem;
	color: #fff;
	display: block;
	float: right;
	font-size: .75rem;
	height: 1.6875rem;
	line-height: 1.6875rem;
	margin: .625rem 0 0 .625rem;
	text-align: center;
	width: 7.1875rem;
}

.mod_eyecatcher .bd .button:hover {
	text-decoration: none;
}

.mod_eyecatcher_txt .bd {
	padding: 1.0625rem .625rem 0 1.875rem;
}

.mod_teaser .mod_eyecatcher {
	margin-bottom: .8125rem;
	margin-top: .8125rem;
}

.mod_clip .mod_eyecatcher {
	margin-bottom: 1.4375rem;
	margin-top: 1.4375rem;
}

.mod_search_result .mod_eyecatcher {
	margin-bottom: .5rem;
	margin-top: .75rem;
}

/* 7.2.12_stoerer-clip.html */
.aside .mod_eyecatcher {
	height: 8.875rem;
	margin-left: 0;
	width: 18.75rem;
}

.aside .mod_eyecatcher .bd {
	padding: 1.0625rem .9375rem .9375rem .9375rem !important;
}

.aside .mod_eyecatcher .img {
	margin-right: .3125rem;
}

.aside .mod_eyecatcher .hl {
	font-weight: normal;
	margin-bottom: .3125rem;
}

.aside .mod_eyecatcher .txt {
	font-size: .75rem;
	line-height: 1rem;
}

.aside .mod_eyecatcher_txt .bd {
	padding-left: 1.875rem !important;
}

.aside .mod_eyecatcher_txt .txt {
	font-size: .875rem;
}

/* special */
.mod_eyecatcher_sp {
	background: #dcedf2;
	height: 9.1875rem;
}

.mod_eyecatcher_sp .bd {
	padding: .875rem .625rem 0 1.25rem !important;
}

.mod_eyecatcher_sp .bd .sp {
	color: #409bb9;
	font-size: 2rem;
	line-height: 2.125rem;
	margin: -0.125rem 0 .25rem;
}

.mod_eyecatcher_sp .bd .hl,
.mod_eyecatcher_sp .bd .hl a {
	color: #000;
	font-size: 1rem;
	font-weight: bold;
	line-height: 1.125rem;
	margin: 0;
}

.mod_eyecatcher_sp .bd .hl {
	margin-bottom: .25rem;
}

.mod_eyecatcher_sp .bd .hl a:hover {
	text-decoration: underline;
}

.mod_eyecatcher_sp .bd .hl a.ad {
	display: inline-block;
	padding: 0 0 0 3.125rem;
	position: relative;
}

.mod_eyecatcher_sp .bd .hl a.ad::before {
	color: #333;
	content: "anzeige";
	font-size: .75rem;
	font-variant: small-caps;
	font-weight: normal;
	padding: 0 .3125rem 0 0;
	position: absolute;
	left: 0;
	top: 0
}

.mod_eyecatcher_sp .bd .media .img {
	border-color: #a6cad5;
	margin: .1875rem .75rem .625rem 0;
	-webkit-box-shadow: 0 0 .1875rem #a6cad5;
	-moz-box-shadow: 0 0 .1875rem #a6cad5;
	box-shadow: 0 0 .1875rem #a6cad5;
}

.mod_eyecatcher_sp .bd .txt {
	color: #000;
}

/* Doxter-Box */
.mod_doxter {
	border: .125rem solid #0d497f;
	height: 7.8125rem;
	overflow: hidden;
	position: relative;
}

.mod_doxter .bd {
	padding: 1.0625rem .625rem 0 1.875rem;
}

.mod_doxter .bd .media {
	padding: 0;
}

.mod_doxter .bd .hl {
	font-weight: bold;
	margin-bottom: .625rem;
}

.mod_doxter .bd .hl a {
	color: #fb0000;
	font-size: 1.25rem;
	line-height: 1.5rem;
}

.mod_doxter .bd .txt {
	font-size: .875rem !important;
	line-height: 1.0625rem;
}

.mod_doxter .bd .button {
	background: linear-gradient(190deg, rgba(245, 121, 121, 1) 0%, rgba(246, 23, 23, 1) 100%);
	border-radius: .3125rem;
	color: #fff;
	bottom: .625rem;
	display: block;
	font-size: .75rem;
	height: 1.6875rem;
	line-height: 1.6875rem;
	position: absolute;
	right: .6875rem;
	text-align: center;
	width: 7.1875rem;
}

.mod_doxter .bd .button:hover {
	text-decoration: none;
}

/* aside */
.aside .mod {
	font-size: .75rem;
	margin-bottom: 3.125rem;
}

.aside .mod .hd {
	border-bottom: .1875rem solid;
	color: #409bb9;
	font-size: 1rem;
	font-weight: bold;
}

.aside .mod .kicker {
	color: #6f6f6f;
	line-height: 1.125rem;
}

.aside .mod .hl {
	display: block;
	font-size: .875rem;
	font-weight: bold;
}

.aside .mod .img img {
	display: block;
}

.aside .mod .txt {
	color: #000;
	font-size: .75rem;
	line-height: 1rem;
}

.aside .mod .txt .more {
	color: #000;
	font-weight: bold;
}

.aside .mod .ft {
	padding-top: .8125rem;
	text-align: right;
}

.aside .mod .ft .less,
.aside .mod .ft .more {
	@include icon("caret-right", "after");
	color: #6f6f6f;
	font-size: .75rem;
	line-height: 1.125rem;
	padding: 0 .9375rem 0 0;
	position: relative;

	&:after {
		color: #c01d2e;
		position: absolute;
		right: .3125rem;
		top: .0625rem;
		line-height: 1;
	}
}

.aside .mod .ft .less {
	display: none;
}

.aside .mod .list li {
	padding: 0 .625rem;
}

.aside .mod .ilist li {
	border-bottom: .0625rem solid #e3dfdc;
	font-size: .75rem !important;
	line-height: 1.5rem !important;
	padding: 0 .625rem 0 1.5rem !important;

	&:before {
		font-size: inherit;
		left: .625rem;
		top: .375rem;
	}
}

.aside .mod .list.expert_list .uicon {
	margin-top: .8125rem;
}

.aside .mod .list.expert_list .ft {
	clear: both;
	padding-bottom: .375rem;
}

/* 7.2.01_binnen-navigation.html */

.mod_nav .list {
	line-height: 1.8125rem;
}

.mod_nav .list li {
	border-bottom: .0625rem solid #e0e0e0;
	height: 1.8125rem;
	padding: 0 .625rem;
}

.mod_nav .list li a {
	display: block;
	font-size: .75rem;
	height: 1.8125rem;
	line-height: 1.8125rem;
	padding: 0 .625rem 0 .875rem;
}

.mod_nav .list li a span {
	float: left;
}

.mod_nav .list li a span.adMarker {
	color: #000;
	float: none;
	font-size: .625rem;
	font-weight: normal;
	margin-left: .5rem;
	text-transform: uppercase;
}

.mod_nav .list li a img {
	float: right;
}

.mod_nav .list li.first {
	border-top: .0625rem solid #e0e0e0;
}

.mod_nav .list li.active {
	background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(230, 227, 220, 1) 100%);
	padding: 1rem .625rem 1rem .625rem;
}

.mod_nav .list li.active a {
	color: #f61212;
	font-weight: bold;
	padding: 0 1.875rem 0 .875rem;
}

.mod_nav .list li a.icon {
	position: relative;
}

.mod_nav .list li a.icon:after {
	@include icon-styles();
	color: #424242;
	font-size: .875rem;
	position: absolute;
	right: .7em;
	top: 0;
}

.mod_nav .list li a.icon:before {
	@include icon-styles();
	color: #424242;
	margin-right: .3125rem;
	text-decoration: none;
}

.mod_nav .list li a.icon_arzt_erwartung:after {
	content: icon-char("user-md");
}

.mod_nav .list li a.icon_diagnose:after {
	content: icon-char("stethoscope");
}

.mod_nav .list li a.icon_grundlagen:after {
	content: icon-char("tag");
}

.mod_nav .list li a.icon_lexikon:after {
	content: icon-char("book");
}

.mod_nav .list li a.icon_medikamente:after {
	content: icon-char("eraser");
}

.mod_nav .list li a.icon_patientenaustausch:after {
	content: icon-char("users");
}

.mod_nav .list li a.icon_symptome:after {
	content: icon-char("eye");
}

.mod_nav .list li a.icon_therapie:after {
	content: icon-char("briefcase-medical");
}

.mod_nav .list li a.icon_ursachen:after {
	content: icon-char("question-circle");
}

.mod_nav .list li a.icon_verlauf:after {
	content: icon-char("thermometer-full");
	margin-right: .1875rem;
}

.mod_nav .list li a.icon_verwandte_krankheiten:after {
	content: icon-char("random");
}

.mod_nav .list li a.icon_vorbeugung:after {
	content: icon-char("umbrella");
}

.mod_nav .list li a.icon_weitere_infos:after {
	content: icon-char("info-circle");
}

.mod_nav .list li a.icon_wer_kann_helfen:after {
	content: icon-char("address-book");
}

/* 7.2.03_specials-box.html */
.mod_special .bg {
	background: #f7f7f4;
}

.mod_special ul {
	border-bottom: .0625rem solid #e6e3dc;
}

.mod_special li {
	border-bottom: .0625rem solid #fff;
	border-top: .0625rem solid #fff;
	padding: .75rem 0;
}

.mod_special .img {
	float: left;
	margin-right: 1.25rem;
	width: 6.6875rem;
}

.mod_special .hl {
	display: block;
}

.mod_special .txt {
	font-size: .8125rem;
	line-height: 1.125rem;
}

.mod_special .txt a {
	color: #000;
	font-weight: bold;
}

.mod_special .ad {
	padding: .9375rem 0 0 0;
	position: relative;
}

.mod_special .ad::before {
	color: #333;
	content: "anzeige";
	font-size: .75rem;
	font-variant: small-caps;
	font-weight: normal;
	position: absolute;
	top: 0;
}

.mod_special .noimg .ad {
	background-position: 0 .3125rem;
}

.mod_special .ft {
	padding-top: .8125rem;
	text-align: right;
}

.content .mod_special .hl {
	color: #000;
	font-size: .875rem;
	font-weight: bold;
	line-height: 1.0625rem;
	margin-bottom: .3125rem;
}

.content .mod_special .ft .more {
	color: #6F6F6F;
}

.aside .mod_special.fitHeightToCarousel {
	min-height: 24.375rem;
	position: relative;
}

.aside .mod_special.fitHeightToCarousel .ft {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
}

/* 7.2.04_news-ticker-box.html */
.mod_news .list .item {
	padding: .25rem 0 .4375rem;
}

.mod_news .list .kicker {
	margin-bottom: .0625rem;
}

.mod_news .list .hl {
	margin-bottom: .125rem;
}

.mod_news .list .ad::before {
	content: "anzeige";
	color: #333;
	font-size: .75rem;
	font-variant: small-caps;
	font-weight: normal;
	float: right;
}

.mod_news.mod_ad {
	padding: .9375rem 0 0 0;
}

/* 7.2.05_video-box.html */
.mod_video {
	border-bottom: .0625rem solid #e3dfdc;
}

.mod_video .bd {
	border-bottom: .0625rem solid #e3dfdc;
	position: relative;
}

.mod_video .inner {
	background-color: #f7f7f4;
	border-bottom: .0625rem solid #fff;
	border-top: .0625rem solid #fff;
	padding: .9375rem 0 .4375rem;
}

.mod_video .prev,
.mod_video .next {
	background: #c01d2e;
	cursor: pointer;
	color: #fff;
	display: block;
	height: 1.6875rem;
	position: absolute;
	text-align: center;
	top: 4.375rem;
	width: 1.4375rem;

	&:before {
		font-size: 1.25rem;
		line-height: 1.6875rem;
		position: relative;
	}
}

.mod_video .prev {
	@include icon("caret-left");
	border-radius: 0 .3125rem .3125rem 0;
	left: 0;

	&:before {
		left: -0.0625rem;
	}
}

.mod_video .next {
	@include icon("caret-right");
	border-radius: .3125rem 0 0 .3125rem;
	right: 0;

	&:before {
		left: .0625rem;
	}
}

.mod_video .inner .img_container {
	margin: 0 auto .3125rem;
	position: relative;
	width: 15rem;
}

.mod_video .inner .img {
	background: #969696;
	border: .1875rem solid black;
	display: block;
	height: 8.3125rem;
	width: 14.625rem;
}

.mod_video .inner .img:hover {
	border-color: red;
}

.mod_video .bd .txt {
	color: #409bb9;
	font-size: 1rem;
	font-weight: bold;
	line-height: 1.1875rem;
	text-align: center;
	padding: 0 .9375rem;
}

.mod_video .ft {
	background-color: #faf9f8;
	border-bottom: .0625rem solid #fff;
	border-top: .0625rem solid #fff;
	overflow: hidden;
	padding-top: .8125rem;
}

* + html .mod_video .ft {
	position: relative;
}

/* Workaround fuer IE7 */
.mod_video .ft .txt {
	color: #409bb9;
	font-size: .625rem;
	line-height: .75rem;
}

.mod_video .ft .list {
	margin: 0 1.25rem;
	width: 62500rem;
}

.mod_video .ft .list li {
	display: block;
	float: left;
	margin-left: 1.25rem;
	overflow: hidden;
	padding: 0;
	width: 5rem;
}

.mod_video .ft .list .first {
	margin-left: 0;
}

.mod_video .ft .list .img {
	background: #969696;
	border: .0625rem solid black;
	display: block;
	height: 2.75rem;
	margin: 0 0 .25rem 0;
	width: 4.875rem;
}

.mod_video .ft .list .img:hover {
	border-color: red;
}

.mod_video .ft .list .txt {
	text-align: left;
	margin: .3125rem 0 .6875rem 0;
}

.mod_video.hideThumbs .bd {
	border-bottom: none;
}

.mod_video.hideThumbs .ft {
	display: none;
}

.aside .mod_video .ft .list {
	margin: 0 .9375rem;
}

.aside .mod_video .ft .list li {
	margin-left: .9375rem;
}

.aside .mod_video .ft .list .first {
	margin-left: 0;
}

/* 7.2.07_fragen-antworten-box.html */
.mod_question {
	color: #6f6f6f;
	font-size: .75rem;
	line-height: 1rem;
	overflow: hidden;
}

.mod_question .list {
	display: none;
}

.mod_question .active {
	display: block;
}

.mod_question .ft .more {
	cursor: pointer;
	display: none;
}

.mod_question .ft .prev {
	background-position: -51.375rem -28.5rem;
	float: left;
	padding: 0 0 0 .875rem;
}

.mod_question .ft .next {
	background-position: right -37.25rem;
	float: right;
}

/* 7.2.08_social-bookmark-box.html */
.mod_social li {
	float: left;
	height: 1.25rem;
	margin: .875rem 0 .0625rem .9375rem;
	width: 1.25rem;
}

.mod_social li img {
	border: .0625rem solid #cdcdcd;
	display: block;
}

/* password forgotten: */

.mod_pw_forgotten .hd {
	margin-bottom: .625rem;
}

.mod_pw_forgotten form {
	background: transparent;
}

.mod_pw_forgotten .txt {
	margin: 0 0 1.25rem 0;
}

.mod_pw_forgotten .line {
	margin-bottom: .625rem;
}

.mod_pw_forgotten .line .left {
	float: left;
	text-align: right;
	width: 8.3125rem;
}

.mod_pw_forgotten .line .left label {
	color: #000;
	font-size: .8125rem;
	line-height: 1.4375rem;
	font-weight: bold;
}

.mod_pw_forgotten .line .right {
	float: right;
	width: 20.125rem;
}

.mod_pw_forgotten .line .right input[type=text] {
	margin-bottom: .4375rem;
	width: 13.75rem;
}

.mod_pw_forgotten .line .right input[type=text].error {
	border-color: #973544;
}

.mod_pw_forgotten .line .right label {
	margin-left: .1875rem;
	margin-right: .375rem;
	vertical-align: middle;
}

.mod_pw_forgotten .line .left label.error,
.mod_pw_forgotten .line .right label.error {
	color: #973544;
}

.mod_pw_forgotten .line .right label a {
	color: #973544;
}

/* 7.2.10_kategorienuebersicht.html */
.mod_category .hd {
	font-size: .8125rem;
	line-height: 1rem;
	padding-bottom: .25rem;
}

.mod_category table {
	margin: .9375rem 0 .5625rem;
	width: 100%;
}

.mod_category caption {
	color: #000;
	font-size: .8125rem;
	font-weight: bold;
	line-height: 1.0625rem;
	text-align: left;
}

.mod_category caption a {
	color: #000;
}

.mod_category th {
	border-bottom: .0625rem solid #e3dfdc;
	color: #6f6f6f;
	font-size: .75rem;
	font-weight: normal;
	line-height: 1rem;
	padding: .25rem 0;
	text-align: left;
	width: 3.75rem;
}

.mod_category th.category {
	width: 11.25rem;
}

.mod_category td {
	border-bottom: .0625rem solid #e3dfdc;
	color: #6f6f6f;
	font-size: .8125rem;
	line-height: 1.0625rem;
	padding: .25rem 0;
}

.mod_category .ilist_item {
	@include icon('chevron-right');
	color: #000;
	margin-left: .625rem;
	padding-left: .9375rem;
	position: relative;

	&:before {
		left: .3125rem;
		top: 0;
		color: #0d497f;
		position: absolute;
		font-size: .6875rem;
	}
}

/* 7.2.15_nutzer-ranking.html */
.mod_ranking {
	color: #6f6f6f;
	font-size: .6875rem;
}

.mod_ranking .list li {
	padding: .25rem .625rem;
}

.mod_ranking .uicon {
	float: left;
}

.mod_ranking .info {
	float: left;
	padding: .4375rem 0 0 .625rem;
	width: 13.125rem;
}

.mod_ranking .info .user {
	font-size: .875rem;
	line-height: 1.1875rem;
}

.mod_ranking .points {
	float: left;
	padding: .4375rem 0;
	text-align: right;
	width: 3rem;
}

.mod_ranking .points .count {
	color: #409bb9;
	font-size: 1.0625rem;
	line-height: 1.1875rem;
}

/* 7.2.16_index-box.html */
.mod_index .hl {
	border-bottom: none;
	color: #409bb9;
	font-size: .875rem !important;
	font-weight: bold;
	line-height: 1.4375rem !important;
	margin-top: .625rem;
}

.mod_index .left {
	float: left;
	width: 50%;
}

.mod_index .right {
	float: right;
	width: 50%;
}

.mod_index .active {
	color: #f61212;
	font-weight: bold;
}

.mod_index .hide {
	display: none;
}

.mod_index .bd {
	border-bottom: .0625rem solid #e3dfdc;
	border-top: none;
	margin-bottom: 1.4375rem;
	width: 32.5rem;
}

.mod_index .withImg .bd {
	width: 23rem;
}

.mod_index .media .img {
	margin-right: .75rem;
}

.mod_index .bd .ilist li {
	border-bottom: none !important;
	border-top: .0625rem solid #e3dfdc;
	font-size: .8125rem;
	line-height: 1.25rem;
	padding: .125rem 0 .125rem 1.6875rem;

	&:before {
		left: .625rem;
		top: .4375rem;
	}
}

.mod_index .bd .ilist li.lastButNotBottom {
	border-bottom: .0625rem solid #E3DFDC !important;
}

.mod_index .bd .ilist li a.ad {
	display: block;
	padding: 0 3.125rem 0 0;
	position: relative;
}

.mod_index .bd .ilist li a.ad::after {
	position: absolute;
	top: 0;
	right: 0;
}

.mod_index .bd .ilist li li,
.mod_index .bd .ilist .first {
	border: none;
}

.mod_index .bd_more {
	margin-bottom: 0;
}

.mod_index .loginBtn {
	margin-top: .375rem;
}

.aside .mod_index .bd {
	width: 18.75rem;
}

/* Navigationsbox */
.mod_nav .hl {
	border-bottom: none;
	color: #409bb9;
	font-size: .875rem !important;
	font-weight: bold;
	line-height: 1.4375rem !important;
	margin-top: .625rem;
}

.mod_nav .left {
	float: left;
	width: 50%;
}

.mod_nav .right {
	float: right;
	width: 50%;
}

.mod_nav .active {
	color: #f61212;
}

.mod_nav a.active {
	font-weight: bold;
}

.mod_nav .hide {
	display: none;
}

.mod_nav .bd {
	border: none;
}

.mod_nav .bd .ilist li {
	border-bottom: .0625rem solid #e3dfdc;
	border-top: none !important;
	font-size: .8125rem;
	line-height: 1.25rem;
	padding: .125rem 0 .125rem 1.6875rem;

	&:before {
		top: .375rem;
		left: .625rem;
	}
}

.mod_nav .bd .ilist li li {
	border: none;
}

.mod_nav .bd .ilist li.sub {
	@include icon("plus");
}

.mod_nav .bd .ilist li.open:before {
	content: icon-char("minus");
}

.mod_nav .bd_more {
	margin-bottom: 0;
}

.mod_nav .bd .toggle .toggle {
	display: none;
}

.mod_nav .bd .toggle li.initopen > .toggle {
	display: block;
}

/* 7.2.17_info-modul-nutzerstatus.html */
.mod_status .lft {
	text-align: left;
}

.mod_status .div {
	border-left: .0625rem solid #e6e3dc;
	padding: 0 .625rem;
}

.mod_status th:first-child .div,
.mod_status td:first-child .div {
	border: none;
}

.mod_status .txt {
	color: #6f6f6f;
	font-size: .8125rem;
	line-height: 1.125rem;
	padding: .375rem .625rem .625rem;
}

.mod_status table {
	border-collapse: separate;
	width: 100%;
}

.mod_status td,
.mod_status th {
	border-bottom: .0625rem solid #e6e3dc;
	padding: .125rem 0;
	text-align: center;
}

.mod_status th {
	color: #6f6f6f;
	font-size: .6875rem;
	font-weight: normal;
	line-height: .8125rem;
}

.mod_status td {
	border-top: .0625rem solid #fff;
	font-size: 1rem;
	line-height: 1.125rem;
}

/* mod_shortcuts.html */
.mod_shortcuts {
	overflow: hidden;
}

.mod_shortcuts .bd {
	padding-top: .625rem;
}

.mod_shortcuts .ilist {
	display: none;
}

.mod_shortcuts .active .ilist {
	display: block;
}

.mod_shortcuts .hl {
	background: linear-gradient(180deg, rgba(16, 107, 189, 1) 0%, rgba(13, 73, 127, 1) 100%);
	color: #fff;
	display: block;
	font-size: .75rem;
	height: 1.5625rem;
	line-height: 1.5625rem;
	padding: 0 .5rem;

	&:hover {
		background: linear-gradient(180deg, rgba(48, 139, 221, 1) 0%, rgba(45, 105, 159, 1) 100%);
	}
}

.mod_shortcuts .active .hl,
.mod_shortcuts .active .hl:hover {
	background: linear-gradient(180deg, rgba(206, 206, 206, 1) 0%, rgba(143, 143, 143, 1) 100%);
}

.mod_shortcuts .list {
	margin-bottom: .0625rem;
}

.mod_shortcuts .ilist li {
	border-top: none;
	font-size: .6875rem;
}

.mod_shortcuts .ilist li a.ad {
	display: flex;
	justify-content: space-between;
}

.mod_shortcuts .ilist li a.ad::after {
	position: relative;
}

.mod_shortcuts .ilist .odd {
	background-color: #f7f7f4;
}

/* Medikamentenbestellung */
.mod_medicine_order.mod_ad {
	display: flex;
	flex-direction: column;
}

.mod_medicine_order.mod_ad::before {
	content: "anzeige";
	background: transparent;
	position: relative;
	font-variant: small-caps;
	right: 0;
	align-self: flex-end;
	padding: 0 .125rem 0 0;
}

.mod_medicine_order .inner {
	border: .0625rem solid #e6e3dc;
	padding: .3125rem .625rem;
}

.mod_medicine_order .hd {
	border-bottom: none !important;
	margin-bottom: .75rem;
}

.mod_medicine_order .img {
	float: left;
	margin-right: .9375rem;
}

.mod_medicine_order p {
	margin-bottom: .1875rem;
}

.mod_medicine_order .hl,
.mod_medicine_order .txt,
.mod_medicine_order .link {
	margin-left: 6.5625rem;
}

.mod_medicine_order .price {
	color: #f61212;
	font-size: 1rem;
	font-weight: bold;
	line-height: 1.25rem;
	margin-left: .3125rem;
}

.mod_medicine_order .link a {
	@include icon("caret-right", "after");
	padding: 0 .875rem 0 0;
	position: relative;

	&:after {
		color: #c01d2e;
		font-size: 1rem;
		line-height: 1;
		position: absolute;
		right: .125rem;
		top: 0;
	}
}

.mod_medicine_order .noimg .hl,
.mod_medicine_order .noimg .txt,
.mod_medicine_order .noimg .link {
	margin-left: 0;
}

/* mod_most_discussed (01_Einzelartikel_final_verm)*/
.mod_most_discussed {
	overflow: hidden;
}

.mod_most_discussed .hd {
	border-bottom: .0625rem solid #e3dfdc;
}

.mod_most_discussed .kicker,
.mod_most_discussed .sub {
	color: #6f6f6f;
	font-size: .8125rem;
	line-height: 1.125rem;
}

.mod_most_discussed .hl {
	color: #333333;
	font-size: .8125rem !important;
	font-weight: bold !important;
	line-height: 1.125rem !important;
}

.mod_most_discussed .txt {
	color: #000;
	font-size: .8125rem;
	line-height: 1.125rem;
}

.mod_most_discussed .txt a {
	color: #000;
	font-weight: bold;
}

.mod_most_discussed .list li {
	padding: .4375rem .625rem .625rem 2.0625rem;
	position: relative;
}

.mod_most_discussed .icon {
	@include icon("comment-dots");
	color: #0d497f;
	font-size: .9375rem;
	height: .9375rem;
	left: .5625rem;
	position: absolute;
	top: 1.875rem;
	width: 1rem;
}

.aside .mod_most_discussed .hl {
	margin-left: 1.25rem;
}

.aside .mod_most_discussed .icon {
	top: 1.375rem;
}

/* Verwandte Artikel */
.mod_related .hd {
	margin-bottom: .625rem;
}

.mod_related .media {
	padding: .625rem 0;
}

.mod_related .first {
	padding-top: 0;
}

.mod_related .img {
	margin-right: .75rem;
}

.mod_related .kicker {
	color: #6f6f6f;
	display: block;
	font-size: .75rem;
	font-weight: normal;
	line-height: .875rem;
}

.mod_related .kicker.ad {
	padding-top: .8125rem;
	position: relative;
}

.mod_related .kicker.ad::before {
	color: #333;
	content: "anzeige";
	font-size: .75rem;
	font-variant: small-caps;
	font-weight: normal;
	position: absolute;
	top: 0;
}

.mod_related .hl {
	font-size: .875rem;
	font-weight: bold;
	line-height: 1.0625rem;
	margin-bottom: .5rem;
}

.mod_related .txt {
	font-size: .8125rem;
	line-height: 1rem;
}

.mod_related a, .mod_related a:hover {
	color: #409BB9 !important;
}

.mod_related .sep {
	border-bottom: .0625rem solid #e3dfdc;
}

.mod_related .ilist li {
	margin-bottom: .25rem;

	&:before {
		top: .375rem;
	}
}

.mod_related .ilist li .kicker.ad {
	padding-top: .9375rem;
}

.mod_related .ilist li a {
	color: #000 !important;
	font-size: .8125rem;
	font-weight: bold;
	line-height: .9375rem;
}

.mod_related .ilist li a.ad {
	padding: 0;
}

.mod_related .ilist li a.ad::after {
	content: "";
}

.article div.mod_ad {
	clear: both;
	margin-top: .9375rem;
	margin-bottom: .9375rem;
}

.article .mod_related .inner {
	background: #f2f1ed;
	padding: .5rem;
}

.article .mod_related .img img,
.aside .mod_related .img img {
	height: 5rem;
	width: 6.6875rem;
}

.aside .mod_related .ilist li {
	background-position: -51.5rem -39.1875rem !important;
	border: none !important;
	font-size: inherit !important;
	line-height: inherit !important;
	padding: 0 0 0 .875rem !important;
}

/* Suchfeld mit ueberschrift (fuer Haupt- und Marginalspalte */
.mod_searchfield form {
	background-color: transparent;
}

.mod_searchfield .legend {
	color: #000;
	font-size: .875rem;
	font-weight: bold;
	line-height: 1rem;
	margin-bottom: .4375rem;
}

.mod_searchfield label {
	color: #333333;
	float: right;
	font-size: .8125rem;
	line-height: 1.5rem;
}

.mod_searchfield .bd {
	background-color: #f7f7f4;
	border-bottom: .0625rem solid #e6e3dc;
	border-top: .0625rem solid #e6e3dc;
	padding: .3125rem .625rem;
}

.mod_searchfield .line {
	padding: .1875rem 0;
}

.mod_searchfield input.text {
	vertical-align: middle;
	width: 18.4375rem;
}

.mod_searchfield button.submit {
	display: inline !important;
	vertical-align: middle;
}

.aside .mod_searchfield input.text {
	width: 7.8125rem;
}

/* Promille-Rechner */
#promillerechner {
	padding: .625rem;
}

#promillerechner h1 {
	color: #fff;
	font-size: 1.25rem;
	font-weight: bold;
}

#promillerechner form {
	margin: 0 -0.625rem;
	padding: 0 .625rem .625rem .625rem;
}

#promillerechner .tabelle1 {
	width: 32.5rem;
}

#promillerechner .td1 {
	width: 18.75rem;
}

#promillerechner .td2 {
	width: 9.375rem;
}

#promillerechner .progress1 {
	width: 7.8125rem;
}

/* mod_experts */
.mod_experts .h1 {
	border-bottom: .1875rem solid #409bb9;
	font-size: 1rem;
	margin-bottom: .625rem;
}

.mod_experts .hd {
	border-bottom: .0625rem solid #ccc;
	font-size: .875rem;
	margin-bottom: .3125rem;
}

.mod_experts .ilist {
	margin-bottom: 1.25rem;
}

.mod_experts .ilist li {
	background-image: none;
	border-bottom: .0625rem solid #ccc;
	padding: .3125rem 0 .5rem 0;
}

.mod_experts .question {
	font-size: .75rem;
	margin-bottom: .3125rem;
}

.mod_experts .user {
	@include icon("chevron-right");

	font-size: .6875rem;
	line-height: .9375rem;
	margin-bottom: .3125rem;
	padding-left: .875rem;
	position: relative;

	&:before {
		color: #0d497f;
		font-size: .6875rem;
		line-height: 1;
		position: absolute;
		top: .0625rem;
		left: 0;
	}
}

.mod_experts .user .red {
	color: #f61212;
	font-size: .6875rem;
	font-weight: normal;
	line-height: .9375rem;
}

.mod_experts .text {
	color: #000;
	font-size: .6875rem;
	padding-left: .875rem;
}

.mod_experts .ilist li .switch {
	text-align: right;
}

.mod_experts .ilist li .more {
	cursor: pointer;
	color: #6f6f6f;
	font-size: .6875rem;
	font-weight: normal;
	line-height: .875rem;
}

.content .mod_experts .ilist li:before {
	display: none;
}

.aside .mod_experts .hd {
	border-bottom: .0625rem solid #ccc;
	font-size: .8125rem;
	margin-bottom: .3125rem;
}

.aside .mod_experts .user,
.aside .mod_experts .text {
	display: none;
}

.aside .mod_experts .ilist li {
	border-bottom: .0625rem solid #ccc !important;

	&:before {
		left: 0;
	}
}

/* Textbox mit Formatierung */
.mod_richtext h1 {
	border-bottom: .1875rem solid;
	color: #409BB9;
	font-size: 1rem;
	font-weight: bold;
	line-height: 1.25rem;
	margin-bottom: .5rem;
}

.highlightbox {
	background: #eeeeee;
	color: #000;
	padding: 0 .625rem;
	margin-bottom: 1.25rem;
	display: table;
}

.highlightbox h2, .highlightbox h3 {
	margin-top: .625rem;
}

/* footer */
.footer {
	font-size: .6875rem;
	line-height: 1.0625rem;
}

.footer .col {
	border-left: .0625rem solid #cbc7bf;
	float: left;
	padding: 0 .9375rem 0 1.25rem;
	width: auto;
}

.footer .col_1 {
	border-left: 0;
	width: 10.875rem;
}

.footer .col_2 {
	width: 10.875rem;
}

.footer .col_3 {
	width: 10.875rem;
}

.footer .col_4 {
	width: 10.9375rem;
}

.footer a, .footer a:active, .footer a:visited, .footer a:hover {
	color: #000;
}

.footer .service {
	background-color: #fff;
	font-size: .8125rem;
	line-height: 1.375rem;
	margin: .125rem 0 .75rem;
	padding: 0 1.25rem .625rem;
	position: relative;
}

.footer .service .inner {
	padding: .75rem 0 0 0;
}

.footer .service .hd {
	border-bottom: 0;
	color: #0d467f;
	font-size: 1rem;
	font-weight: bold;
	line-height: 1.25rem;
	margin-bottom: .75rem;
}

.footer .service .hd .more {
	font-size: .6875rem;
	margin-left: .4375rem;
}

.footer .service .hl {
	font-size: .875rem;
	font-weight: bold;
}

/*.footer*/
.footer .service .rounded {
	display: none;
}

.footer .service .icon {
	padding: 0 0 0 1.25rem;
	position: relative;

	&:before {
		@include icon-styles();
		font-size: 1rem;
		left: 0;
		position: absolute;
		top: .0625rem;
	}
}

.footer .service .icon_arztsuche:before {
	content: icon-char("briefcase-medical");
}

.footer .service .icon_apothekensuche:before {
	content: icon-char("search");
}

.footer .service .icon_gesundheit:before {
	content: icon-char("plus-square");
}

.footer .service .icon_figurtrainer:before {
	content: icon-char("heart-rate");
	font-size: .875rem;
}

.footer .service .icon_person:before {
	content: icon-char("user");
}

.footer .service .icon_biowetter:before {
	content: icon-char("thunderstorm");
}

.footer .service .icon_suche:before {
	content: icon-char("radar");
}

.footer .service .icon_horoskop:before {
	content: icon-char("eye");
}

.footer .service .icon_promille:before {
	content: icon-char("calculator");
}

.footer .service .icon_rechner:before {
	content: icon-char("calculator");
}

.footer .service .icon_vorsorgeplaner:before {
	content: icon-char("calendar-alt");
}

.footer .service img {
	margin-right: .25rem;
	vertical-align: middle;
}

.footer .service a {
	vertical-align: middle;
}

.footer .links {
	padding: 0 1.25rem;
}

.footer .copyright {
	margin-bottom: .5rem;
}

/* dev styles */
.dev {
	color: #cdcdcd;
	display: none;
	font-size: .625rem;
}

.dev_q {
	color: #f61212;
	display: none;
	font-size: .625rem;
}

/* skeleton */
.page {
	margin: 0 auto 0 auto;
	padding: 0 10.625rem 1.25rem 10.625rem;
	position: relative;
	width: $page-width;
}

/* 10.625rem rechts: Platzhalter fuer Skyscraper */
.page_outside {
	position: relative;
}

.page_inside {
	margin-top: .625rem;
	position: relative;
}

/*.main */
.main {
	background: #fff;
	padding-top: .625rem;
}

.section {
	clear: both;
}

.section_shadow {
	padding-bottom: .875rem;
}

.content {
	float: left;
	margin: 0 0 0 1.25rem;
	width: 32.5rem;
}

.content .content_left {
	float: left;
	width: 14.0625rem;
}

.content .content_right {
	float: right;
	width: 14.0625rem;
}

.section--content-aside {
	display: flex;
	gap: 1.25rem;
	justify-content: center;

	.content {
		margin-left: 0;
	}

	.aside {
		margin-right: 0;
	}

	.mod_sticky:is(:last-child) {
		position: sticky;
		top: 0;
		margin-bottom: 1rem;
	}
}

.content_wide {
	float: none;
	margin: 0 1.25rem;
	padding-bottom: .0625rem;
	width: auto;
}

.aside {
	float: right;
	margin: 0 1.25rem 0 0;
	width: 18.75rem;
}

.footer {
	clear: both;
}

.content .content {
	margin-left: 0;
}

.content .content_wide {
	margin-left: 0;
	margin-right: 0;
}

.content .aside {
	margin-right: 0;
}

/*******************/
/* sitewide styles */
body {
	background-color: #9cc5db;
	font-family: arial, helvetica, sans-serif;
	font-size: .75rem;
	padding-top: .625rem;
}

body.iframe {
	background: none;
}

a {
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

a.button:hover {
	text-decoration: none;
}

.help {
	cursor: help;
}

/* font-styles */
.h1 {
	color: #409bb9;
	font-size: 1.25rem;
	font-weight: bold;
}

.red,
a.red,
a.red:active,
a.red:visited,
a.red:hover {
	color: #f61212;
}

a.dictionary_link {
	border-bottom: .0625rem dotted !important;
	text-decoration: none !important;
}

a.dictionary_link:hover {
	border-bottom: .0625rem solid !important;
	text-decoration: none !important;
}

.bld {
	font-weight: bold;
}

.clickable {
	cursor: pointer;
}

/* layer */
.layer {
	background: rgba(0, 0, 0, .5);
	display: none;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 5503000;
}

.layer2 {
	z-index: 5503100;
}

.layer .layer_inner {
	-webkit-box-shadow: 0 0 .8125rem;
	-moz-box-shadow: 0 0 .8125rem;
	box-shadow: 0 0 .8125rem;
	cursor: default;
	margin: 0 auto;
	position: relative;
	visibility: hidden;
}

.layer .layer_inner .close {
	@include icon("times");
	background: #c01d2e;
	border-radius: .3125rem;
	cursor: pointer;
	height: 1.5625rem;
	position: absolute;
	text-align: center;
	right: 1.25rem;
	top: .875rem;
	width: 1.5625rem;

	&:before {
		color: #FFF;
		line-height: 1.5625rem;
		font-size: 1rem;
	}
}

.layer .qq-uploader {
	height: 4.6875rem;
}

.layer .qq-upload-list {
	margin: 0;
}

.layer .qq-upload-drop-area {
	display: block !important;
	height: 2.5rem;
	min-height: auto;
	top: 1.875rem;
	width: 25rem;
}

.layer .qq-upload-button {
	border-bottom: none;
	top: 1.875rem;
}

.layer .qq-upload-button .sepText {
	color: #6F6F6F;
	font-size: .8125rem;
	font-weight: normal;
	height: 1.5625rem;
	line-height: 1.5625rem;
	position: absolute;
	right: 9.6875rem;
	z-index: 5510000;
}

.layer .layer_login {
	background-color: #fff;
	color: #6f6f6f;
	padding: 1.75rem 1.25rem 1.875rem;
	width: 28.75rem;
}

.layer .layer_login form {
	background-color: transparent;
}

.layer .layer_login fieldset {
	padding: .8125rem 0 0 0;
}

.layer .layer_login legend {
	border-bottom: .1875rem solid #aaa9a5;
	padding-bottom: .25rem;
	width: 100%;
}

.layer .layer_login label {
	display: block;
	font-size: .8125rem;
	line-height: 1.0625rem;
	margin-bottom: .1875rem;
}

.layer .layer_login input[type=text],
.layer .layer_login input[type=password] {
	margin-bottom: .5625rem;
	width: 97%;
}

.layer .layer_login input[type=text].error,
.layer .layer_login input[type=password].error {
	border-color: #f61212;
}

.layer .layer_login p.error.red {
	padding-bottom: 1em;
	display: none;
}

.layer .layer_login input[type=submit] {
	float: right;
	margin-bottom: .1875rem;
}

.layer .layer_login .left {
	border-right: .0625rem solid #6f6f6f;
	float: left;
	padding: 0 2.1875rem .625rem 0;
	width: 12.375rem;
}

.layer .layer_login .right {
	float: right;
	padding: 0 0 .625rem 2.1875rem;
	width: 11.9375rem;
}

.layer .layer_login .right #community_layer_facebook_login {
	display: block;
	margin: .625rem 0 .5rem 0;
}

.layer .layer_login .right .txt {
	font-size: .8125rem;
	line-height: 1.0625rem;
	margin-bottom: .625rem;
}

.layer .layer_login .forgot {
	margin: 0 0 .8125rem;
}

.layer .layer_login .forgot a {
	color: #6f6f6f;
	font-size: .6875rem;
}

.layer .layer_login .saveLogin {
	margin: 0 0 .8125rem;
}

.layer .layer_login .saveLogin input {
	vertical-align: middle;
}

.layer .layer_login .saveLogin label {
	color: #6f6f6f;
	display: inline;
	font-size: .6875rem;
	margin-left: .3125rem;
	vertical-align: middle;
}

.layer .layer_login .hd {
	border-bottom: .1875rem solid #aaa9a5;
	margin-bottom: 1rem;
	padding-bottom: .375rem;
}

.layer .layer_login .register_hl {
	margin: .9375rem 0 .9375rem 0;
}

.layer .layer_login .register {
	line-height: 1.5625rem;
	padding: 0 2.5rem 0 0;
}

.layer .layer_login .register a {
	float: right;
}

.layer .layer_login .right .register {
	padding: 0 0 .625rem 0;
}

.layer .layer_login .right .register a {
	float: none;
	margin-top: .625rem;
}

.layer .layer_recommend_article {
	background-color: #fff;
	color: #6f6f6f;
	padding: 1.75rem 1.25rem 1.875rem;
	width: 28.75rem;
}

.layer .layer_recommend_article form {
	background-color: transparent;
}

.layer .layer_recommend_article fieldset {
	padding: .8125rem 0 1.375rem;
}

.layer .layer_recommend_article legend {
	border-bottom: .1875rem solid #aaa9a5;
	padding-bottom: .25rem;
	width: 100%;
}

.layer .layer_recommend_article label {
	display: block;
	font-size: .8125rem;
	line-height: 1.0625rem;
	margin-bottom: .1875rem;
}

.layer .layer_recommend_article p {
	margin: 0 0 .3125rem 0;
}

.layer .layer_recommend_article label {
	color: #409BB9;
	margin-top: .1875rem;
}

.layer .layer_recommend_article p.error label {
	display: inline-block;
	width: 8.3125rem;
	font-weight: bold;
}

.layer .layer_recommend_article input[type=text],
.layer .layer_recommend_article input[type=password] {
	margin-bottom: .5625rem;
	width: 97%;
}

.layer .layer_recommend_article input[type=text].error,
.layer .layer_recommend_article input[type=password].error {
	border-color: #f61212;
}

.layer .layer_recommend_article label.error {
	color: #f61212;
}

.layer .layer_recommend_article input[type=submit] {
	float: right;
	margin-bottom: .1875rem;
}

.layer .layer_recommend_article textarea {
	width: 28.5625rem;
	height: 3.625rem;
	margin-bottom: 2em;
}

.layer .layer_recommend_article .left {
	float: left;
	padding: 0 2.1875rem .625rem 0;
	width: 12.375rem;
}

.layer .layer_recommend_article .right {
	float: right;
	width: 14.125rem;
}

.layer .layer_recommend_article p.mandatories {
	float: left;
	width: 14.125rem;
}

.layer .layer_recommend_article br.clear {
	clear: both;
}

.layer .layer_profile_image_reset_upload {
	background-color: #fff;
	color: #6f6f6f;
	padding: 1.75rem 1.25rem 1.875rem;
	width: 25rem;
}

.layer .layer_profile_image_reset_upload p {
	margin: 0 0 2em 0;
}

.layer .layer_profile_image_reset_upload .submit {
	float: left;
	margin-bottom: .1875rem;
}

.layer .layer_profile_image_reset_upload form {
	background-color: transparent;
}

.layer .layer_profile_image_reset_upload fieldset {
	padding: .8125rem 0 1.375rem;
}

.layer .layer_profile_image_reset_upload legend {
	border-bottom: .1875rem solid #aaa9a5;
	padding-bottom: .25rem;
	width: 100%;
}

.layer .layer_profile_image_reset_upload input[type=submit] {
	margin-right: 7.375rem;
}

.layer .layer_profile_image_reset_upload p.error, .layer .layer_profile_image_upload p.success {
	display: inline-block;
	width: auto;
	font-weight: bold;
}

.layer .layer_profile_image_reset_upload p.error {
	color: #f61212;
}

.layer .layer_profile_image_reset_upload p.success {
	color: #1cab00;
}

.layer .layer_profile_image_upload {
	background-color: #fff;
	color: #6f6f6f;
	padding: 1.75rem 1.25rem 1.875rem;
	width: 37.5rem;
}

.layer .layer_profile_image_upload form {
	background-color: transparent;
}

.layer .layer_profile_image_upload fieldset {
	padding: .8125rem 0 1.375rem;
}

.layer .layer_profile_image_upload legend {
	border-bottom: .1875rem solid #aaa9a5;
	padding-bottom: .25rem;
	width: 100%;
}

.layer .layer_profile_image_upload input[type=submit] {
	float: right;
	margin-bottom: .1875rem;
}

.layer .layer_profile_image_upload p.error, .layer .layer_profile_image_upload p.success {
	width: auto;
	font-weight: bold;
}

.layer .layer_profile_image_upload p.error {
	color: #f61212;
}

.layer .layer_profile_image_upload p.success {
	color: #1cab00;
}

body.layer_inner.iframe {
	background-color: #fff;
	color: #6f6f6f;
}

body.layer_inner.iframe form {
	background-color: transparent;
}

body.layer_inner.iframe fieldset {
	padding: .8125rem 0 1.375rem;
}

body.layer_inner.iframe legend {
	border-bottom: .1875rem solid #aaa9a5;
	padding-bottom: .25rem;
	width: 100%;
}

body.layer_inner.iframe input[type=submit] {
	float: right;
	margin-bottom: .1875rem;
}

body.layer_inner.iframe p.error,
body.layer_inner.iframe p.success {
	width: auto;
	font-weight: bold;
}

body.layer_inner.iframe p.error {
	color: #f61212;
}

body.layer_inner.iframe p.success {
	color: #1cab00;
}

.layer .layer_img {
	background-color: #fff;
	padding: 1rem .875rem .9375rem;
}

.layer .layer_img .close {
	right: .875rem;
}

.layer .layer_img .hl {
	color: #409bb9;
	font-size: 1rem;
	line-height: 1.4375rem;
	margin-bottom: .75rem;
}

.layer .layer_img .img {
	margin-bottom: .6875rem;
}

.layer .layer_img .txt {
	margin-bottom: .3125rem;
}

.layer .layer_img .rights {
	color: #9a9a9a;
	font-size: .6875rem;
	line-height: 1.0625rem;
}

.layer .layer_video {
	background-color: #fff;
	padding: 1rem .8125rem .6875rem .875rem;
	width: 29.5625rem;
}

.layer .layer_video .hl {
	color: #409bb9;
	font-size: 1rem;
	line-height: 1.4375rem;
	margin-bottom: .6875rem;
}

.layer .layer_video .video {
	height: 23.125rem;
	margin-bottom: .6875rem;
	width: 29.25rem;
}

.layer .layer_link {
	background-color: #fff;
	padding: 1.5625rem 1.25rem 2.6875rem;
	width: 28.75rem;
}

.layer .layer_link .hl {
	border-bottom: .1875rem solid #aaa9a5;
	color: #409bb9;
	font-size: 1rem;
	line-height: 1.4375rem;
	margin-bottom: 1.125rem;
	padding-bottom: .1875rem;
}

.layer .layer_link .left {
	border-right: .0625rem solid #aaa9a5;
	float: left;
	height: 9.1875rem;
	width: 14.3125rem;
}

.layer .layer_link .right {
	float: right;
	height: 9.1875rem;
	width: 13rem;
}

.layer .layer_link .link {
	color: #fff;
	display: block;
	font-size: .8125rem;
	font-weight: bold;
	height: 3.125rem;
	line-height: 1.0625rem;
	margin-top: 1.8125rem;
	padding: 1.0625rem 1.875rem 0;
	text-align: center;
	width: 9.25rem;
}

.layer .layer_ask {
	background-color: #fff;
	padding: 1.5625rem 1.25rem 1.375rem;
	visibility: visible;
	width: 37.5rem;
}

.layer .layer_ask .hd {
	font-size: 1.25rem;
	line-height: 1.4375rem;
	margin-bottom: 1.125rem;
	padding-bottom: .1875rem;
}

.layer .layer_ask p {
	margin-bottom: 1.25rem;
}

.layer .layer_ask form {
	background: transparent;
	margin-bottom: 1.25rem;
}

.layer .layer_ask legend {
	display: none;
}

.layer .layer_ask label,
.layer .layer_ask .label {
	font-size: .8125rem;
	font-weight: bold;
	line-height: 1.125rem;
}

.layer .layer_ask .line {
	margin-bottom: .9375rem;
}

.layer .layer_ask .line p {
	font-size: .8125rem;
	line-height: 1.125rem;
	margin-bottom: 0;
}

.layer .layer_ask .error_message {
	color: #f61212;
	font-weight: bold;
}

.layer .layer_ask .success_message {
	color: #1cab00;
	font-weight: bold;
}

.layer .layer_ask .left {
	float: left;
	text-align: right;
	width: 5rem;
}

.layer .layer_ask .right {
	float: right;
	width: 31.875rem;
}

.layer .layer_ask .right label {
	font-weight: normal;
}

.layer .layer_ask .ask_who {
	float: left;
}

.layer .layer_ask .ask_expertId {
	float: right;
}

.layer .layer_ask select {
	width: 15.8125rem;
}

.layer .layer_ask input[type=text] {
	width: 31.5rem;
}

.layer .layer_ask input[type=radio],
.layer .layer_ask input[type=checkbox] {
	vertical-align: middle;
}

.layer .layer_ask input[type=radio] + label,
.layer .layer_ask input[type=checkbox] + label {
	margin-right: .9375rem;
	vertical-align: middle;
}

.layer .layer_ask textarea {
	height: 15.625rem;
	padding: .0625rem;
	width: 31.5rem;
}

.layer .layer_ask .editortoolbar a {
	font-weight: bold;
}

.layer .layer_ask .editortoolbar .btnToggleSmileys {
	@include icon('caret-down');
	display: block;
	float: right;
	padding-left: .75rem;
	position: relative;

	&:before {
		color: #c01d2e;
		position: absolute;
		left: 0;
		top: 0;
	}
}

.layer .layer_ask .editortoolbar .btnToggleSmileys.opened {
	@include icon('caret-up');
}

.layer .layer_ask .editortoolbar .btnToggleSmileys img {
	display: block;
	float: right;
	margin: -0.375rem .1875rem 0 .3125rem;
}

.layer .layer_ask .btnInsertGalleryImage {
	font-weight: bold;
}

.layer .layer_ask .smileys a {
	display: inline-block;
	margin: 0;
	padding: 0 .25rem 0 .1875rem;
}

.layer .layer_ask .actionbar .right {
	border-bottom: .125rem solid #fff;
	border-top: .125rem solid #fff;
	line-height: 1.6875rem;
	padding: .625rem 0;
	position: relative;
}

.layer .layer_ask .actionbar a {
	float: left;
}

.layer .layer_ask .actionbar a.btnHtmlMode {
	margin-left: .625rem;
}

.layer .layer_ask .actionbar a.btnCancel {
	float: right;
	margin-right: 1.25rem;
}

.layer .layer_ask .actionbar #createbtn {
	float: right;
}

.layer .layer_ask .actionbar #blogPostPreviewFormBtn {
	float: left;
}

.layer .layer_ask #blogPostPreviewContent {
	margin-left: 4.375rem;
	padding: 0;
	width: 33.125rem;
}

.layer .layer_ask #blogPostPreviewContent .post_content {
	margin: 0;
	padding: .3125rem;
	width: 32.5rem;
}

.layer .layer_ask .mod_most_discussed .hd {
	border-bottom: 0;
	font-size: .875rem;
	line-height: 1.5rem;
	margin-bottom: 0;
}

.layer .layer_ask .mod_most_discussed ul {
	border-bottom: .0625rem solid #cccccc;
}

.layer .layer_ask .mod_most_discussed li {
	border-top: .0625rem solid #cccccc;
	padding: .4375rem .625rem .4375rem 2.0625rem;
}

.layer .layer_inactivity_teaser {
	background-color: #fff;
	width: 37.5rem;
	padding: .9375rem;
}

.layer .layer_inactivity_teaser .close {
	right: .9375rem;
}

.layer .layer_inactivity_teaser .fb_like {
	font-size: .6875rem;
	height: 1.3125rem;
	position: absolute;
	right: 3.125rem;
	top: .5625rem;
	width: 8.75rem;
}

.layer .layer_inactivity_teaser .fb_like_txt {
	margin: 0 0 .125rem 0;
}

.layer .layer_inactivity_teaser .fb_like_content {
	width: 100%;
}

.layer .layer_inactivity_teaser .info {
	margin-bottom: .625rem;
}

.layer .layer_inactivity_teaser .hd {
	margin-bottom: .75rem;
}

.layer .layer_inactivity_teaser .img {
	display: block;
	margin-bottom: .75rem;
}

.layer .layer_inactivity_teaser .txt {
	margin-bottom: .625rem;
}

.layer .layer_inactivity_teaser .back {
	font-weight: bold;
	float: right;
}

.layer .layer_inactivity_teaser .home {
	font-weight: bold;
}

.badge {
	background-color: #fff;
	border: .0625rem solid #e3dfdc;
	border-bottom-left-radius: .3125rem;
	border-bottom-right-radius: .3125rem;
	height: 2rem;
	text-align: center;
	width: 3.6875rem;
}

/* Post-Content */
.post_content .cite,
.post_content address,
.post_content p,
.layer .layer_ask .post_content p,
.post_content pre,
.post_content ol,
.post_content ul,
.post_content table {
	margin: .1875rem 0 .5625rem 0;
	/* fuer border-bottom der Links */
	padding-bottom: .0625rem;
}

.post_content img {
	height: auto;
	max-width: 100%;
}

.post_content > * {
	overflow: hidden;
}

.post_content ol,
.post_content ul {
	padding-left: 2em;
}

.post_content address {
	font-style: italic;
}

.post_content pre {
	padding: .125rem .3125rem .125rem .9375rem;
}

.post_content h1 {
	font-size: 1.5rem;
	margin: 1em 0 .4em 0;
}

.post_content h2 {
	font-size: 1.125rem;
	margin: 1em 0 .4em 0;
}

.post_content h3 {
	font-size: .875rem;
	margin: 1em 0 .4em 0;
}

.post_content h4 {
	font-size: .75rem;
	margin: 1em 0 .4em 0;
}

.post_content h5 {
	font-size: .625rem;
	margin: 1em 0 .4em 0;
}

.post_content h6 {
	font-size: .5625rem;
	margin: 1em 0 .4em 0;
}

.post_content *:first-child {
	margin-top: .1875rem;
}

.post_content .cite {
	background: #F0F6FA;
	border: .0625rem solid #D8D2CD;
	padding: .3125rem .625rem;
}

.post_content .cite .cite {
	background: #F6FAF0;
}

.post_content .cite .cite .cite {
	background: #F0F6FA;
}

.post_content .cite .cite .cite .cite {
	background: #F6FAF0;
}

.post_content .post_edit_info {
	color: #666666;
	font-style: italic;
	font-weight: bold;
	margin: .625rem 0 .3125rem 0;
}

#blogPostPreviewContent .post_content {
	background: #fff;
	padding: .3125rem;
}

/* tabs */
.mod_tabs {
	margin-bottom: 1.25rem;
}

.mod_tabs .mod_tab {
	display: none;
}

.mod_tabs .active {
	display: block;
}

.mod_tabs .hd {
	display: none;
}

.mod_tabs .tabbingTitle {
	display: block;
	margin-bottom: .625rem;
}

.tabs {
	border-bottom: .0625rem solid #cccccc;
	height: 2.3125rem;
	padding: 0 .625rem;
}

.tabs li {
	background: #f6f5f2;
	border: .0625rem solid #cccccc;
	border-bottom: none;
	display: block;
	float: left;
	height: 1.75rem;
	margin-left: -0.0625rem;
	margin-top: .5rem;
	position: relative;
	width: auto;
}

.tabs li a {
	color: #000;
	cursor: pointer;
	display: block;
	font-weight: bold;
	height: 1.75rem;
	line-height: 1.75rem;
	outline: none;
	padding: 0 1.25rem;
	width: auto;
}

.tabs li.first {
	border-top-left-radius: .375rem;
}

.tabs li.last {
	border-top-right-radius: .375rem;
}

.tabs li.active {
	background: #fff;
	border-bottom: .0625rem solid #fff;
	border-top-left-radius: .375rem;
	border-top-right-radius: .375rem;
	height: 2.25rem;
	margin-top: 0;
}

.tabs li.active a {
	color: #f61212;
	padding-top: .5rem;
}

/* Abweichende Tabs beim Themenspecial-Reiter */
.specials .tabs li {
	background: #5b9abd;
	background-image: linear-gradient(to bottom, rgb(91, 153, 189) 0%, rgb(133, 183, 210) 100%);
	background-image: -o-linear-gradient(to bottom, rgb(91, 153, 189) 0%, rgb(133, 183, 210) 100%);
	background-image: -moz-linear-gradient(to bottom, rgb(91, 153, 189) 0%, rgb(133, 183, 210) 100%);
	background-image: -webkit-linear-gradient(to bottom, rgb(91, 153, 189) 0%, rgb(133, 183, 210) 100%);
	background-image: -ms-linear-gradient(to bottom, rgb(91, 153, 189) 0%, rgb(133, 183, 210) 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgb(91, 153, 189)), color-stop(1, rgb(133, 183, 210)));
	border-color: #fff;
}

.specials .tabs li a {
	color: #fff;
	font-weight: normal;
}

.specials .tabs li.active {
	background: #fff;
}

.specials .tabs li.active a {
	color: #f61212;
	font-weight: bold;
}

/* list styles */
.ilist li,
.ilist_li {
	@include icon("chevron-right");
	padding-left: .875rem;
	position: relative;

	&:before {
		color: #0d497f;
		font-size: .6875rem;
		line-height: 1;
		position: absolute;
		top: .0625rem;
		left: 0;
	}
}

.ilist li a {
	color: #000;
}

.ilist li a.ad::after {
	color: #333;
	content: "anzeige";
	font-size: .75rem;
	font-variant: small-caps;
	font-weight: normal;
	cursor: default;
	pointer-events: none;
	padding: 0 0 0 .3125rem;
	display: inline-block;
}

.odd {
	background-color: #f7f7f4;
	border-bottom: .0625rem solid #e6e3dc;
	border-top: .0625rem solid #fff;
}

.even {
	border-bottom: .0625rem solid #e6e3dc;
	border-top: .0625rem solid #fff;
}

.item {
	border-bottom: .0625rem solid #fff;
	font-size: .75rem;
}

/* media */
.media .bd {
	display: table-cell;
	margin: 0;
}

.media .img {
	float: left;
	width: auto;
}

.media .img img {
	display: block;
}

/* mod styles */
.hd {
	border-bottom: .1875rem solid;
	color: #409bb9;
	font-size: 1rem;
	font-weight: bold;
	line-height: 1.25rem;
}

.hd_1 {
	border-bottom: .1875rem solid;
	color: #409bb9;
	font-size: 1.25rem;
	font-weight: bold;
	line-height: 1.5rem;
	margin-bottom: .9375rem;
}

/* user icon */
.uicon {
	background-color: #fff;
	border: .0625rem solid #cdcdcd;
	height: 2.625rem;
	padding: .25rem;
	width: 2.625rem;
}

.boxshadow .uicon {
	-webkit-box-shadow: 0 0 .1875rem #cdcdcd;
	-moz-box-shadow: 0 0 .1875rem #cdcdcd;
	box-shadow: 0 0 .1875rem #cdcdcd;
}

/* paging buttons */
.paging {
	margin-bottom: .6875rem;
}

.paging p {
	float: right;
}

.paging a {
	color: #969696;
	cursor: pointer;
	float: left;
	line-height: 1.125rem;
}

.paging p a.active {
	color: #000;
}

.paging p a.previous {
	@include icon("caret-left");
	cursor: auto;
	margin-right: .4375rem;
	padding-left: .875rem;
	position: relative;

	&:before {
		position: absolute;
		left: .125rem;
		font-size: 1rem;
	}
}

.paging p a.previous:hover {
	text-decoration: none;
}

.paging p a.prev_active {
	color: #000;
	cursor: pointer;

	&:before {
		color: #c01d2e;
	}
}

.paging p a.prev_active:hover {
	text-decoration: underline;
}

.paging p a.next {
	@include icon("caret-right", "after");
	cursor: auto;
	margin-left: .4375rem;
	padding-right: .875rem;
	position: relative;

	&:after {
		position: absolute;
		right: .125rem;
		font-size: 1rem;
	}
}

.paging p a.next:hover {
	text-decoration: none;
}

.paging p a.next_active {
	color: #000;
	cursor: pointer;

	&:after {
		color: #c01d2e;
	}
}

.paging p a.next_active:hover {
	text-decoration: underline;
}

.paging p .btns a.btn {
	border: .0625rem solid #969696;
	color: #969696;
	display: block;
	height: 1rem;
	margin: 0 .0625rem;
	overflow: hidden;
	text-align: center;
	min-width: 1.375rem;
}

.paging p .btns span.btn {
	color: #969696;
	display: block;
	float: left;
	height: 1rem;
	line-height: 1rem;
	margin: 0 .0625rem;
	overflow: hidden;
	text-align: center;
	width: 1.375rem;
}

.paging p .btns a.active {
	background-color: #F2F1ED;
	background-position: -1.5625rem -32.375rem;
	color: #000;
}

.paging .btns .gap {
	color: #969696;
	float: left;
	line-height: 1.125rem;
	margin: 0 .125rem;
}

/* ads */
.banner_top {
	text-align: center;
	position: relative;
}

.banner_right {
	left: 50%;
	margin-left: 28.125rem;
	position: absolute;
	top: 0;
}

.banner_right--sticky.fixed {
	// +.625rem spacing
	margin-left: ($page-width / 2) + .625rem;
	position: fixed;
}

.banner_left {
	right: 50%;
	margin-right: 28.125rem;
	position: absolute;
	top: 0;
}

.banner_left--sticky.fixed {
	// +.625rem spacing
	margin-right: ($page-width / 2) + .625rem;
	position: fixed;
}

.wallpaper_top {
	margin-left: 6.125rem;
	margin-right: -0.625rem;
	text-align: right;
	width: 45.5rem;
}

.wallpaper_right {
	left: 50%;
	margin-left: 26.5625rem;
	position: absolute;
	top: 0;
}

.hockey_top {
	margin-bottom: .625rem;
	margin-left: 6.125rem;
	margin-right: -0.625rem;
	text-align: right;
	width: 45.5rem;
}

.hockey_right {
	left: 50%;
	margin-left: 26.5625rem;
	position: absolute;
	top: 9.5rem;
}

.billboard {
	margin-bottom: 1.875rem;
}

/* Anzeige-Markierung */
div.mod_ad::before,
table.mod_ad td::before {
	color: #333;
	content: "anzeige";
	font-size: .75rem;
	font-variant: small-caps;
	font-weight: normal;
}

div.mod_ad {
	position: relative;
}

div.billboard.mod_ad::before,
div.mod_ad_bottom::before,
div.mod_ad_left::before {
	background: #ddd;
	color: #333;
	content: "Anzeige";
	font-size: .65rem;
	font-variant: normal;
	font-weight: normal;
	text-shadow: 0 0 .0625rem #fff;
	line-height: .65rem;
	padding: .125rem;
	position: absolute;
	left: 0;
	z-index: 9999;
}

.ad_mobile.mod_ad_left::before {
	bottom: 0;
}

.mod_ad.mod_rectangle {
	background: #F9F9F7;
	border: 1px solid #EEEDE8;
	text-align: center;
	width: 100%;
}

div.ad_mobile.mod_ad_bottom_sticky {
	left: 0;
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 999;
	margin: 0;
	padding: .0625rem;
	background-color: #fff;

	&.social_padding {
		padding-bottom: 34px;
	}

	&:before {
		content: "Anzeige";
		font-size: .6rem;
		font-variant: normal;
		line-height: 24px;
	}
}

div.ad_bottom_close_button {
	position: absolute;
	top: 0;
	right: .125rem;
	display: inline-block;
	width: 1.75em;
	line-height: 1.75em;
	font-size: 1.2em;
	color: #fff;
	background-color: #1e5188;
	cursor: pointer;

	& > * {
		vertical-align: middle;
	}
}

div.mod_ad_bottom.inactive::before,
div.mod_ad_left.inactive::before,
.ad_asmi.inactive::before {
	content: "" !important;
	padding: 0 !important;
}

div.mod_ad_bottom_sticky.inactive {
	display: none;
}

/* Teads-Werbung enthaelt nur Code, nichts Sichtbares: */
div.ad_asmi_richmedia,
div.ad_asmi_teads {
	background: none;
	margin: 0;
	padding: 0;
}

/* Anzeige-Markierung des Werbemittels ausblenden: */
.mod_ad .dc_headline {
	display: none;
}

/* Damit "ANZEIGE"-Markierung und Abstand entfallen, wenn AdServer nichts ausliefert: */
.content .mod_adcode,
.aside .mod_adcode {
	margin: 0;
}

.content div.mod_adcode,
.content table.mod_adcode td {
	padding-bottom: 1.25rem;
}

.aside div.mod_adcode,
.aside table.mod_adcode td {
	padding-bottom: 3.125rem;
}

.content .mod_tabs .mod_ad {
	margin-top: .9375rem;
}

.ad_mobile {
	margin: 1.25rem 0 1.875rem 0;
	text-align: center;
}

.ad_mobile.ad_asmi_content_ad,
.ad_mobile.ad_asmi_footer_ad,
.ad_mobile.ad_asmi_reminder {
	margin: .625rem 0 .9375rem 0;
}

ins.adsbygoogle {
	background-color: inherit;
	color: inherit;
	text-decoration: inherit;
}

/* Werbemittel: Text-/Bild-Anzeige */
.mod_textimage_ad li {
	border-bottom: .0625rem solid #e6e3dc;
	padding: .3125rem 0 .75rem 0;
}

.mod_textimage_ad .img {
	width: 100%;
}

.mod_textimage_ad .floating .img {
	float: left;
	margin-right: 1.25rem;
	width: auto;
}

.mod_textimage_ad .floating .txt {
	margin-left: 7.9375rem;
}

.mod_textimage_ad .txt a,
.mod_textimage_ad .txt a:hover {
	display: block;
	color: #000;
	text-decoration: none;
}

/* SEO-Vermarktung */
.mod_seo .inner {
	background: #f2f1ed;
	padding: .5rem;
}

.mod_seo .hd {
	margin-bottom: .3125rem;
}

.mod_seo .hd a:hover {
	text-decoration: none;
}

.mod_seo .img {
	height: auto;
	width: 6.6875rem;
}

.mod_seo .img_left {
	float: left !important;
	margin: .125rem .3125rem 0 0 !important;
}

.mod_seo .img_right {
	float: right !important;
	margin: .125rem 0 0 .3125rem !important;
}

.mod_seo ul {
	margin-bottom: 0 !important;
}

.mod_seo li {
	border: none !important;
	padding: 0 !important;
}

.mod_seo .bd a,
.mod_seo .bd a:active,
.mod_seo .bd a:visited,
.mod_seo .bd a:hover {
	color: #409BB9 !important;
}

.mod_seo p {
	font-size: .75rem;
	line-height: 1rem;
	margin-bottom: .3125rem !important;
}

.mod_seo p.link {
	text-align: right;
}

.mod_seo p.link a {
	@include icon("caret-right");
	padding: 0 .875rem 0 0;
	position: relative;

	&:before {
		color: #c01d2e;
		position: absolute;
		right: 0;
	}
}

/* forms */
form {
	background-color: #f7f7f4;
}

legend {
	color: #409bb9;
	font-size: 1rem;
	font-weight: bold;
	line-height: 1.125rem;
	white-space: normal;
}

textarea {
	resize: none;
}

textarea,
input[type=text],
input[type=email],
select {
	border: .0625rem solid #aaa;
}

.button,
.submit,
input[type="submit"],
a.button:active,
a.submit:active,
a.button:hover,
a.submit:hover,
a.button:visited,
a.submit:visited {
	background: linear-gradient(180deg, rgba(19, 111, 192, 1) 0%, rgba(13, 74, 128, 1) 100%);
	border: none;
	border-radius: .3125rem;
	color: #fff !important;
	display: block;
	font-size: .75rem;
	font-weight: bold;
	height: 1.6875rem;
	line-height: 1.6875rem;
	padding: 0;
	text-align: center;
	text-decoration: none;
	width: 8.8125rem;
}

.bigButton,
a.bigButton:active,
a.bigButton:hover,
a.bigButton:visited {
	background: linear-gradient(180deg, rgba(19, 111, 192, 1) 0%, rgba(13, 74, 128, 1) 100%);
	border-radius: .3125rem;
	border: none;
	color: #fff;
	display: block;
	font-size: .75rem;
	font-weight: bold;
	height: 1.6875rem;
	line-height: 1.6875rem;
	padding: 0;
	text-align: center;
	text-decoration: none;
	width: 15.625rem;
}

.mediumButton,
a.mediumButton:active,
a.mediumButton:hover,
a.mediumButton:visited {
	background: linear-gradient(180deg, rgba(19, 111, 192, 1) 0%, rgba(13, 74, 128, 1) 100%);
	border: none;
	border-radius: .3125rem;
	color: #fff;
	display: block;
	font-size: .75rem;
	font-weight: bold;
	height: 1.6875rem;
	line-height: 1.6875rem;
	padding: 0;
	text-align: center;
	text-decoration: none;
	width: 10.75rem;
}

.smallButton,
a.smallButton:active,
a.smallButton:hover,
a.smallButton:visited {
	background: linear-gradient(180deg, rgba(19, 111, 192, 1) 0%, rgba(13, 74, 128, 1) 100%);
	border: none;
	border-radius: .3125rem;
	color: #fff;
	display: block;
	font-size: .75rem;
	font-weight: bold;
	height: 1.6875rem;
	line-height: 1.6875rem;
	padding: 0;
	text-align: center;
	text-decoration: none;
	width: 5.75rem;
}

/**
 * Non-semantic helper classes: please define your styles before this section.
 */

/* For image replacement */
.ir {
	display: block;
	text-indent: -999em;
	overflow: hidden;
	background-repeat: no-repeat;
	text-align: left;
	direction: ltr;
}

/* Hide for both screenreaders and browsers:
	css-discuss.incutio.com/wiki/Screenreader_Visibility */
.hidden {
	display: none;
	visibility: hidden;
}

.versteckt {
	display: none !important;
}

/* Hide only visually, but have it available for screenreaders: by Jon Neal.
	www.webaim.org/techniques/css/invisiblecontent/ & j.mp/visuallyhidden */
.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: .0625rem;
	margin: -0.0625rem;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: .0625rem;
}

/* Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: drupal.org/node/897638 */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
}

/* Hide visually and from screenreaders, but maintain layout */
.invisible {
	visibility: hidden;
}

/* The Magnificent Clearfix: Updated to prevent margin-collapsing on child elements. j.mp/bestclearfix */
.clearfix:before, .clearfix:after {
	content: "\0020";
	display: block;
	height: 0;
	overflow: hidden;
}

.clearfix:after {
	clear: both;
}

/* Fix clearfix: blueprintcss.lighthouseapp.com/projects/15318/tickets/5-extra-margin-padding-bottom-of-page */
.clearfix {
	zoom: 1;
}

.medsearch_form {
	padding: .9375rem .625rem .3125rem;
}

.medsearch_form .legend {
	color: #409bb9;
	font-size: 1rem;
	font-weight: bold;
	line-height: 1.125rem;
	padding-bottom: .3125rem;
}

.medsearch_form p {
	padding-bottom: .3125rem;
}

.medsearch_form .medsearch_label {
	padding-bottom: .3125rem;
	font-weight: bold;
	font-size: .875rem;
}

.medsearch_form .left {
	float: left;
	text-align: left;
	width: 11.875rem;
}

.medsearch_form .right {
	float: right;
	text-align: left;
	width: 15.3125rem;
}

.medsearch_form .medsearch_input {
	width: 11.875rem;
}

.medsearch_form .medsearch_select {
	width: 11.875rem;
	height: 1.3125rem;
}

.medsearch_form .medsearch_submit {
	padding-top: .3125rem;
	padding-bottom: .3125rem;
}

.medsearch_form .medsearch_submit_line {
	padding-top: .3125rem;
	border-bottom: .0625rem solid black;
	padding-bottom: .3125rem;
}

.medsearch_form .medsearch_manufacturer {
	width: 18.75rem;
	height: 1.3125rem;
}

#upload_image_editform img {
	margin: 1.5625rem auto;
	display: block;
	min-width: 8.125rem;
	min-height: 8.125rem;
	max-height: 24.375rem;
	max-width: 24.375rem;
}

/* FW-Debug-Layer vor den Header und alles andere (GESUND-1366) */
#fw-debug-toolbar {
	z-index: 9999999 !important;
}

/* Cookie-Zustimmungs-Layer vor die Werbung (GESUND-1359) */
.cc_banner-wrapper {
	z-index: 99001 !important;
}

/**
 * Media queries for responsive design.
 *
 * These follow after primary styles so they will successfully override.
 */

@media all and (orientation: portrait) {
	/* Style adjustments for portrait mode goes here */

}

@media all and (orientation: landscape) {
	/* Style adjustments for landscape mode goes here */

}

/* Grade-A Mobile Browsers (Opera Mobile, Mobile Safari, Android Chrome)
	consider this: www.cloudfour.com/css-media-query-for-mobile-is-fools-gold/ */
@media screen and (max-device-width: 30rem) {
	/* Uncomment if you don't want iOS and WinMobile to mobile-optimize the text for you: j.mp/textsizeadjust */
	/* html { -webkit-text-size-adjust:none; -ms-text-size-adjust:none; } */
}

/**
 * Print styles.
 *
 * Inlined to avoid required HTTP connection: www.phpied.com/delay-loading-your-print-css/
 */
@media print {
	* {
		background: transparent !important;
		color: black !important;
		text-shadow: none !important;
		filter: none !important;
		-ms-filter: none !important;
	}

	/* Black prints faster: sanbeiji.com/archives/953 */

	/* Don't show links for images, or javascript/internal links */
	pre, blockquote {
		border: .0625rem solid #999;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group;
	}

	/* css-discuss.incutio.com/wiki/Printing_Tables */
	tr, img {
		page-break-inside: avoid;
	}

	@page {
		margin: .5cm;
	}

	p, h2, h3 {
		orphans: 3;
		widows: 3;
	}

	h2, h3 {
		page-break-after: avoid;
	}

	/* fix top margin of the page */
	.page {
		padding: 0 !important;
	}

	.header_meta {
		top: 0 !important;
	}

	/* Hauptspalte mit voller Breite, da die Marginale beim Druck ausgeblendet wird: */
	.content_main {
		width: auto;
	}

	/* Damit sich Teaser-Boxen nicht ueberlagern oder ineinander verschieben. */
	.content .mod,
	.aside .mod {
		clear: both;
	}

	/* hide elements */
	.article_bottom,
	.aside,
	.banner_left,
	.banner_right,
	.banner_top,
	.billboard,
	.bottom,
	.breadcrumb,
	.content .paging,
	.footer,
	.header,
	.header_ad,
	.header_meta,
	.head_nav,
	.header .search,
	.hockey_right,
	.hockey_top,
	.page > .nav,
	.page_inside > .nav,
	.page_outside,
	.wallpaper_right,
	.wallpaper_top {
		display: none !important;
	}

	.mod_ad,
	.mod_ad_bottom,
	.mod_ad_left,
	.mod_ph {
		display: none;
	}

	/* print all article pages at once */
	.article .paging_group {
		display: block;
	}

	/* show default list-style */
	.ilist {
		list-style: disc;
	}

	.mod_gallery .control {
		display: none !important;
	}

	.slideshow .control {
		display: none !important;
	}

	.slideshow .list {
		width: auto;
	}

	.slideshow .list li {
		display: none;
	}

	.slideshow .list li:first-child {
		display: block;
	}
}

/* Imedo-Arztsuche ------------------------------------------------------------------------------ */

/**
* @note		CSS-Datei fuer die Arztsuche (gemeinsame Elemente und Klassen)
*/
.docsearch-map div,
.docsearch-map span,
.docsearch-map h3,
.docsearch-map h4,
.docsearch-map h5,
.docsearch-map h6,
.docsearch-map p,
.docsearch-map blockquote,
.docsearch-map pre,
.docsearch-map a,
.docsearch-map abbr,
.docsearch-map acronym,
.docsearch-map address,
.docsearch-map big,
.docsearch-map cite,
.docsearch-map code,
.docsearch-map del,
.docsearch-map dfn,
.docsearch-map em,
.docsearch-map font,
.docsearch-map img,
.docsearch-map ins,
.docsearch-map kbd,
.docsearch-map q,
.docsearch-map s,
.docsearch-map samp,
.docsearch-map small,
.docsearch-map strike,
.docsearch-map strong,
.docsearch-map sub,
.docsearch-map sup,
.docsearch-map tt,
.docsearch-map var,
.docsearch-map dl,
.docsearch-map dt,
.docsearch-map dd,
.docsearch-map ol,
.docsearch-map ul,
.docsearch-map li,
.docsearch-map fieldset,
.docsearch-map form,
.docsearch-map label,
.docsearch-map legend,
.docsearch-map table,
.docsearch-map caption,
.docsearch-map tbody,
.docsearch-map tfoot,
.docsearch-map thead,
.docsearch-map tr,
.docsearch-map th,
.docsearch-map td {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-weight: inherit;
	font-style: inherit;
	font-size: 100%;
	font-family: inherit;
	vertical-align: baseline;
}

.docsearch-map input,
.docsearch-map select,
.docsearch-map th,
.docsearch-map td {
	font-size: 1em;
}

/* for IE6 */
.docsearch-map li li,
.docsearch-map li p,
.docsearch-map td p,
.docsearch-map blockquote p {
	font-size: 1em;
}

* html .docsearch-map {
	overflow: hidden;
}

.cf:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}

.cf {
	display: inline-block;
}

/* Hides from IE-mac \*/
* html .cf {
	height: 1%;
}

.cf {
	display: block;
}

/* End hide from IE-mac */

.imedo-content .padder {
	padding: 1em;
}

.imedo-content .check-green {
	padding-left: 2em !important;
}

.imedo-content .provider-breadcrumb {
	display: none;
	padding: 1em;
}

.imedo-content p {
	margin-bottom: 1em;
}

.imedo-content form {
	margin: 0 0 1.25rem 0;
	width: 100%;
}

.imedo-content fieldset {
	padding: .625rem;
	border: none;
}

.imedo-content legend {
	padding: 1em 1em 0 0;
}

.imedo-content label,
.imedo-content select,
.imedo-content .radio-button,
.imedo-content .check-box,
.imedo-content .submit {
	cursor: pointer;
}

.imedo-content .password-field,
.imedo-content .text-field,
.imedo-content textarea,
.imedo-content select,
.imedo-content .labeled,
.imedo-content .submit {
	margin-bottom: 1em;
}

.imedo-content label,
.imedo-content .label {
	display: block;
	font-size: 1.2em;
	padding: 0 0 .2em;
	position: relative;
}

.imedo-content .text-field,
.imedo-content .password-field,
.imedo-content textarea {
	width: 98%;
}

.imedo-content select {
	display: block;
}

.imedo-content label span {
	bottom: .1875rem;
	font-size: .8em;
	position: absolute;
	right: .3125rem;
	white-space: nowrap;
}

.imedo-content .docsearch-submitbox {
	padding-top: .6em;
}

.imedo-content .button-container {
	float: left;
}

.imedo-content .display-options {
	float: right;
	width: 12em;
	text-align: right;
}

.imedo-content button.button-style,
.imedo-content a.button-style {
	margin: 0;
	padding: 0;
	display: inline-block;
	border-color: #E0E0E0;
	border-width: 0 .0625rem .0625rem 0;
	border-style: solid;
	text-decoration: none;
	float: none;
}

.imedo-content button.button-style span,
.imedo-content a.button-style span {
	display: inline-block;
	border: .0625rem solid #aaa;
	padding: .3125rem .9375rem;
	color: #333;
	font-size: 1em;
	cursor: pointer;
	text-decoration: none;
	background: none;
}

/* IE7 Hacks */
.imedo-content button.button-style {
	*width: 14.6875rem;
}

.imedo-content button.button-style span {
	*width: 12.5rem;
}

.imedo-content button.button-style:hover,
.imedo-content a.button-style:hover {
	text-decoration: none;
}

.imedo-content button.button-style:hover span,
.imedo-content a.button-style:hover span {
	color: #000;
	border-color: #888;
	text-decoration: none;
}

.imedo-content a.button-style.recommend span {
	padding-left: 1.375rem;
}

.imedo-content .docsearch-header .imedo-logo {
	float: right;
	width: 15.625rem;
}

.imedo-content .docsearch-header p {
	text-align: right;
	margin: 0 0 1em 0;
	font-style: italic;
}

.imedo-content .docsearch-header .hd,
.mod_med_search_imedo .hd {
	margin-bottom: .625rem;
}

.imedo-content .docsearch-header h2 {
	margin: .8em 0 0 .8em;
}

.imedo-content .docsearch-header h3 {
	margin: 0 0 .5em 1em;
	font-size: 1em;
	font-weight: normal;
}

.imedo-content .map-img {
	width: 100%;
	height: 100%;
	border: none;
	z-index: 10;
	position: relative;
	top: -26.25rem;
	background: none !important;
}

.imedo-content .map-display {
	position: relative;
	z-index: 0;
	top: -52.5rem !important;
	width: 18.75rem !important;
	height: 26.25rem !important;
}

.imedo-content h2,
.imedo-content h3,
.imedo-content h4,
.imedo-content h5,
.imedo-content h6 {
	margin: 1.5em 0;
}

.imedo-content p {
	margin: 1em 0;
}

.imedo-content ul {
	margin: 1em 0;
	padding: 0 0 0 2em;
}

.imedo-content ul.city-list {
	float: left;
	font-size: .75rem;
}

.imedo-content ul.city-list.map-overlay {
	float: none;
	height: 100%;
	left: -5%;
	margin: 0;
	padding: 0;
	position: relative;
	top: 0;
	width: 5%;
}

.imedo-content ul.city-list img {
	border: none;
	margin-bottom: .15em;
}

.imedo-content ul.city-list.map-overlay li {
	list-style-type: none;
	z-index: 15;
}

.imedo-content li.toplevel a.dropdown-tab {
	text-decoration: none;
	padding-left: .8em;
	z-index: 500;
	padding-top: .2em;
	padding-bottom: .2em;
	font-weight: bold;
}

.imedo-content li.toplevel a.dropdown-tab:hover {
	text-decoration: none;
}

.imedo-content li.toplevel a.dropdown-tab span {
	padding-right: .2em;
	padding-top: .4em;
	margin-top: -0.375rem;
	padding-bottom: 0;
	display: block;
	white-space: nowrap;
}

.imedo-content li.toplevel:hover a.dropdown-tab {
	position: relative;
	z-index: 500;
}

.imedo-content li.toplevel:hover a.dropdown-tab span {
	padding-right: .8em;
}

.imedo-content li.toplevel:hover a.dropdown-tab img {
	display: none;
}

.imedo-content ul.city-list.map-overlay li {
	list-style-type: none !important;
	padding: 0 !important;
	margin: 0 !important;
}

.imedo-content ul.city-list.map-overlay li ul {
	padding: 0 !important;
	margin: 0 !important;
}

.imedo-content ul.city-list.map-overlay #area-bw,
.imedo-content ul.city-list.map-overlay #area-1 {
	position: absolute;
	z-index: 150;
}

.imedo-content ul.city-list.map-overlay #area-by,
.imedo-content ul.city-list.map-overlay #area-2 {
	position: absolute;
	z-index: 180;
}

.imedo-content ul.city-list.map-overlay #area-be,
.imedo-content ul.city-list.map-overlay #area-3 {
	position: absolute;
	z-index: 195;
}

.imedo-content ul.city-list.map-overlay #area-bb,
.imedo-content ul.city-list.map-overlay #area-4 {
	position: absolute;
	z-index: 194;
}

.imedo-content ul.city-list.map-overlay #area-hb,
.imedo-content ul.city-list.map-overlay #area-5 {
	position: absolute;
	z-index: 197;
}

.imedo-content ul.city-list.map-overlay #area-hh,
.imedo-content ul.city-list.map-overlay #area-6 {
	position: absolute;
	z-index: 198;
}

.imedo-content ul.city-list.map-overlay #area-he,
.imedo-content ul.city-list.map-overlay #area-7 {
	position: absolute;
	z-index: 190;
}

.imedo-content ul.city-list.map-overlay #area-mv,
.imedo-content ul.city-list.map-overlay #area-8 {
	position: absolute;
	z-index: 199;
}

.imedo-content ul.city-list.map-overlay #area-ni,
.imedo-content ul.city-list.map-overlay #area-9 {
	position: absolute;
	z-index: 196;
}

.imedo-content ul.city-list.map-overlay #area-nw,
.imedo-content ul.city-list.map-overlay #area-10 {
	position: absolute;
	z-index: 192;
}

.imedo-content ul.city-list.map-overlay #area-rp,
.imedo-content ul.city-list.map-overlay #area-11 {
	position: absolute;
	z-index: 182;
}

.imedo-content ul.city-list.map-overlay #area-sl,
.imedo-content ul.city-list.map-overlay #area-12 {
	position: absolute;
	z-index: 181;
}

.imedo-content ul.city-list.map-overlay #area-sn,
.imedo-content ul.city-list.map-overlay #area-13 {
	position: absolute;
	z-index: 192;
}

.imedo-content ul.city-list.map-overlay #area-st,
.imedo-content ul.city-list.map-overlay #area-14 {
	position: absolute;
	z-index: 193;
}

.imedo-content ul.city-list.map-overlay #area-sh,
.imedo-content ul.city-list.map-overlay #area-15 {
	position: absolute;
	z-index: 200;
}

.imedo-content ul.city-list.map-overlay #area-th,
.imedo-content ul.city-list.map-overlay #area-16 {
	position: absolute;
	z-index: 189;
}

.imedo-content ul.map-dropdown li {
	background-image: none !important;
	line-height: 1.5;
	text-align: left;
}

.imedo-content ul.map-dropdown li a {
	border-top: solid .0625rem transparent;
	border-bottom: solid .0625rem transparent;
}

.imedo-content ul.map-dropdown li.dropdown-last {
	padding: .25em .25em 0 0;
	margin-top: .25em;
	text-align: right;
}

.imedo-content ul.map-dropdown li.dropdown-last a {
	margin-right: .5em;
	padding-left: 1.6em !important;
	border: none;
	display: inline;
}

.imedo-content ul.map-dropdown li.dropdown-last a:hover {
	border: none;
	padding-left: 1.6em;
}

.imedo-content ul.city-list ul {
	padding: 0;
	margin: 0;
	z-index: 200;
}

.imedo-content ul.city-list table {
	border-collapse: collapse;
}

.imedo-content ul.city-list li a {
	display: block;
}

.imedo-content ul.city-list li:hover {
	position: relative;
	z-index: 300;
}

.imedo-content ul.city-list a:hover {
	position: relative;
	z-index: 300;
}

.imedo-content ul.city-list li:hover ul {
	left: 0;
	top: 1rem;
}

* + html .imedo-content ul.city-list li:hover ul {
	left: 0;
	top: 1.60em;
}

.imedo-content ul.city-list ul {
	position: absolute;
	left: -624.9375rem;
	top: -624.9375rem;
}

.imedo-content ul.map-dropdown {
	width: 15em;
}

.imedo-content ul.map-dropdown a {
	display: block;
	width: 14em;
	padding-left: 1em;
}

/* IE6-Anpassungen */
* html .imedo-content a.dropdown-tab {
	padding-bottom: .3em !important;
	position: relative;
	z-index: 250;
}

* html .imedo-content a.dropdown-tab:hover {
	position: relative;
	z-index: 500;
}

* html .imedo-content a.dropdown-tab:hover span {
	padding-right: .8em;
	margin-top: -0.375rem;
}

* html .imedo-content a.dropdown-tab:hover img {
	display: none;
}

* html .imedo-content a.dropdown-tab span {
	margin-top: 0;
	padding-bottom: 0;
}

* html .imedo-content ul.map-dropdown li a {
	border: none;
}

* html .imedo-content ul.map-dropdown li a:hover {
	border: none;
}

* html .imedo-content ul.city-list.map-overlay a:hover ul.map-dropdown {
	position: absolute;
	top: 1.25rem;
	left: -0.6875rem;
	z-index: 0;
}

* html .imedo-content ul.city-list.map-overlay {
	float: none;
	height: 100%;
	left: -5%;
	margin: 0;
	padding: 0;
	position: relative;
	top: 0;
	width: 5%;
	z-index: 500;
}

/* IE7-Anpassungen */
* + html .imedo-content a.dropdown-tab {
	padding-bottom: .3em !important;
	position: relative;
	z-index: 250;
}

* + html .imedo-content a.dropdown-tab:hover {
	position: relative;
	z-index: 500;
}

* + html .imedo-content a.dropdown-tab:hover span {
	padding-right: .8em;
	margin-top: -0.375rem;
}

* + html .imedo-content a.dropdown-tab:hover img {
	display: none;
}

* + html .imedo-content a.dropdown-tab span {
	margin-top: 0;
	padding-bottom: 0;
}

* + html .imedo-content ul.map-dropdown li a {
	border: none;
}

* + html .imedo-content ul.map-dropdown li a:hover {
	border: none;
}

* + html .imedo-content ul.city-list.map-overlay a:hover ul.map-dropdown {
	position: absolute;
	top: 1.25rem;
	left: -0.6875rem;
	z-index: 0;
}

* + html .imedo-content ul.city-list.map-overlay {
	float: none;
	height: 100%;
	left: -5%;
	margin: 0;
	padding: 0;
	position: relative;
	top: 0;
	width: 5%;
	z-index: 500;
}

.imedo-content .docsearch-results {
	padding: 0 1em 1em 1em;
}

.imedo-content .pager-container {
	margin: 1em auto;
	text-align: center;
}

.imedo-content ul.pagination,
.imedo-content ul.pager {
	margin-bottom: 1.5em;
}

.imedo-content ul.pagination li,
.imedo-content ul.pager li {
	display: inline;
	list-style-type: none;
	margin-right: .4em;
}

.imedo-content ul.pagination a,
ul.pagination span,
.imedo-content ul.pager a,
ul.pager span {
	padding: .3em .5em;
	text-decoration: none;
}

.imedo-content ul.pager a.pager-first {
	padding-left: 1.5em;
}

.imedo-content ul.pager a.pager-last {
	padding-right: 1.5em;
}

.imedo-content .tab-list {
	font-size: 1.16em;
	list-style: none outside none;
	margin: 0;
	padding: 0;
}

.imedo-content .tab-list li {
	float: left;
	padding: 0;
	border-bottom: none;
	margin: 0 .2em 0 0;
}

.imedo-content .tab-list li > a,
.imedo-content .tab-list li > span {
	display: block;
	padding: .2em .4em;
	text-decoration: none !important;
}

.imedo-content .tab-list li > a:hover {
	display: block;
	padding: .2em .4em;
	text-decoration: none !important;
}

.imedo-content .tab-list-alphabet {
	font-size: 1.16em;
	list-style: none outside none;
	margin: 0;
	padding: 0;
}

.imedo-content .tab-list-alphabet li {
	float: left;
	padding: 0;
	margin: 0 .2em 0 0 !important;
	list-style-type: none !important;
}

.imedo-content .tab-list-alphabet li a,
.imedo-content .tab-list-alphabet li span {
	display: block;
	padding: .1em .2em;
	text-decoration: none !important;
}

.imedo-content .tab-list-alphabet li a:hover {
	display: block;
	padding: .1em .2em;
	text-decoration: none !important;
}

.imedo-content .details ul.icons {
	list-style-type: none;
	padding: 0;
}

.imedo-content .details ul.icons li {
	float: left;
	margin: 0 1em .2em 0;
}

.imedo-content .table-style {
	border-collapse: collapse;
	margin: 0 0 1em;
	font-size: 1em;
}

.imedo-content .table-style tr,
.imedo-content .table-style tbody,
.imedo-content .table-style tfoot,
.imedo-content .table-style thead {
	width: 100%;
}

.imedo-content .table-style th {
	font-weight: bold;
}

.imedo-content .table-style th,
.imedo-content .table-style td {
	width: auto;
	padding: .5em;
	text-align: left;
	vertical-align: top;
}

.imedo-content td.provider-picture {
	width: 5%;
	text-align: center;
}

.imedo-content td.provider-name {
	width: 40%;
}

.imedo-content td.provider-views {
	width: 15%;
}

.imedo-content td.provider-recommendations {
	width: 20%;
}

.imedo-content td.provider-certificates {
	width: 15%;
}

.docsearch-mini td.provider-picture {
	width: 10%;
	text-align: center;
}

.docsearch-mini td.provider-name {
	width: 30%;
}

.docsearch-mini td.provider-recommendations {
	width: 20%;
}

.docsearch-big td.provider-picture {
	width: 10%;
	margin: 0 1% 0 0;
	text-align: center;
}

.docsearch-big td.provider-distance {
	width: 10%;
	margin: 0 1% 0 0;
}

.docsearch-big td.provider-name {
	width: 50%;
	margin: 0 1% 0 0;
}

.docsearch-big td.provider-recommendations {
	width: 20%;
}

.imedo-content .doc-info {
	width: 100%;
	padding-top: .5em;
}

.imedo-content .doc-info .image {
	float: left;
	padding: 0;
	margin: 0 .3125rem 0 0;
}

.imedo-content .doc-info .desc-left {
	float: left;
	padding: 0;
	margin: 0 .3125rem 0 0;
	min-width: 38%;
}

.imedo-content .doc-info .desc-right {
	float: left;
	padding: 0;
	min-width: 38%;
}

.imedo-content .docsearch-big .doc-info .image {
	width: 7.5rem;
}

.imedo-content .docsearch-big .doc-info .desc-left {
	width: 46%;
}

.imedo-content .docsearch-big .doc-info .desc-right {
	width: 30%;
}

.imedo-content .docsearch-big .doc-info .image {
	width: 6.25rem;
}

.imedo-content .docsearch-big .doc-info .image img {
	width: 6.25rem;
	margin: 0;
}

.imedo-content .docsearch-big .doc-info .desc-left {
	width: 67%;
}

.imedo-content .docsearch-big .doc-info .desc-right {
	width: 11.25rem;
	margin-left: 6.5625rem;
}

.imedo-content .docsearch-mini .doc-info .image {
	width: 4.375rem;
}

.imedo-content .docsearch-mini .doc-info .image img {
	width: 4.375rem;
	margin: 0;
}

.imedo-content .docsearch-mini .doc-info .desc-left {
	width: 69%;
}

.imedo-content .docsearch-mini .doc-info .desc-right {
	width: 11.25rem;
	margin-left: 4.6875rem;
}

.imedo-content .doc-info .desc-right ul {
	padding: 0;
	list-style-type: none;
}

.imedo-content .doc-info .desc-right ul li {
	margin: 0 0 .6em;
}

.imedo-content .doc-info .desc-left h3 {
	padding: 0;
	margin: 0;
}

.imedo-content ul.zebra-list {
	line-height: 1.5;
	padding: 0;
	margin: 0;
	list-style-type: none;
}

.imedo-content ul.zebra-list li {
	overflow: hidden;
	padding: 0 .4em .8em .4em;
}

.imedo-content .zebra-list li .caption {
	float: left;
	font-weight: bold;
	margin-right: 1em;
	text-align: right;
	width: 4.375rem;
}

.imedo-content .desc-left .zebra-list .odd {
	background: none;
	border: none;
}

.imedo-content .desc-left .zebra-list .even {
	background: none;
	border: none;
}

.imedo-content .doc-info .desc-left .labeled {
	margin-bottom: 0;
	margin-left: 5.3125rem;
}

.imedo-content .doc-info .desc-left .label {
	float: left;
	text-align: right;
	width: 5rem;
	font-size: 1em;
	font-weight: bold;
}

.imedo-content p.deactivated {
	margin: 0 0 1em;
}

.imedo-content .doc-info .desc-right .button-container {
	float: none;
	width: auto;
}

.imedo-content .doc-info-footer {
	margin: 0 0 1em;
}

.imedo-content ul.provider-infrastructure {
	margin-bottom: .5em;
	list-style-type: none;
	padding: 0;
}

.imedo-content ul.provider-infrastructure li {
	float: left;
	margin-right: .2em;
}

.imedo-content .recommendation-links,
.imedo-content .rating-container {
	list-style-type: none;
	padding: 0;
}

.imedo-content .rating-container li {
	margin: 0 0 .3em;
}

.imedo-content table.recommendations .stars .description {
	float: left;
	width: 12em;
	margin: 0 .2em 0 0;
	white-space: nowrap;
}

.recommendations-container .stars,
.imedo-content table.recommendations .stars {
	margin: 0;
	white-space: nowrap;
	clear: left;
}

.imedo-content table.recommendations ul.stars li,
.imedo-content table.recommendations-stars ul.stars li {
	display: inline;
	float: left;
	padding: 0;
	width: 1.25rem;
	margin: 0 !important;
}

.imedo-content table.recommendations-stars ul.stars li.na {
	width: auto;
}

.recommendations-container .stars li {
	list-style-type: none;
	display: inline;
}

.imedo-content .recommendation-form .image-right {
	float: right;
	padding: 0;
	margin: 0 0 0 .3125rem;
	width: 7.5rem;
	height: 7.5rem;
}

.imedo-content .recommendation-form label,
.imedo-content .recommendation-form .label {
	display: inline;
	font-size: 1em;
}

.imedo-content .recommendation-form br {
	display: none;
}

.imedo-content .recommendation-form h3 {
	margin: 1.2em 0;
}

.imedo-content .healthcenter span.ad {
	text-transform: capitalize;
	float: right;
	font-size: .8em;
	font-weight: normal;
	display: block;
	margin-bottom: .5em;
}

.premium-provider-box {
	margin: 2em 0;
}

.premium-provider-box .premium-item {
	width: 29.5%;
	padding: 1%;
	min-height: 9.5em;
}

.premium-provider-box .premium-item.item-left {
	float: left;
}

.premium-provider-box .premium-item.item-middle {
	float: left;
	margin: 0 2.2%;
}

.premium-provider-box .premium-item.item-right {
	float: left;
}

.premium-provider-box .premium-item h4 {
	margin: 0;
	font-size: 1.2em;
	font-weight: bold;
	word-wrap: break-word;
}

.premium-provider-box .premium-item p {
	margin-bottom: .5em;
}

.premium-provider-box .premium-item img {
	float: left;
	margin-right: 1em;
	height: 3.125rem;
	width: 3.125rem;
	border: 0;
}

.premium-provider-box .premium-item .description {
	float: left;
	width: 62%;
	position: relative;
}

.premium-provider-box .premium-item .premium-box-link {
	display: none;
}

.foot-ad.img {
	float: left;
}

.premium-ad {
	margin-left: 2em;
	margin-right: 3em;
	width: 40em;
}

.docsearch .logo {
	float: right;
	padding: .3em;
}

.docsearch .logo a img {
	border: none;
}

.docsearch .button {
	float: right;
	padding: .3em;
}

.docsearch div.inline .button {
	margin: 0;
}

.imedo-content ul.federations_small {
	padding: 0;
	margin: 0 1.25rem 0 0;
	float: right;
}

.imedo-content ul.federations_small li {
	list-style: none;
}

.imedo-content ul.federations_thumb,
.imedo-content ul.federations_large {
	margin: 0;
	padding: 0;
}

.imedo-content ul.federations_thumb li,
.imedo-content ul.federations_large li {
	list-style: none;
	margin: 0;
	padding: 0;
}

.imedo-content .desc-left ul.federations_small {
	float: none;
}

.imedo-content .desc-left ul.federations_small li {
	float: left;
}

.imedo-content .related-providers {
	margin: .625rem 0;
}

.imedo-content .related-providers ol {
	margin: 0;
	padding: 0;
}

.imedo-content .related-providers ol li {
	float: left;
	width: 32%;
	list-style: none;
}

.imedo-content .related-providers ol li .rp_images {
	float: left;
	width: 3rem;
	margin-right: .625rem;
	text-align: center;
}

.imedo-content .related-providers ol li .rp_details {
	float: left;
	width: 70%;
}

.imedo-content .related-providers ol li .rp_details p {
	margin: 0;
}

.imedo-content .errorExplanation h2,
.imedo-content .errorExplanation p,
.imedo-content .errorExplanation li {
	color: #c20;
}

.imedo-content .errorExplanation h2 {
	font-size: 120%;
	margin-bottom: 1em;
	font-weight: bold;
}

.imedo-content .errorExplanation p,
.imedo-content .errorExplanation ul {
	margin-left: 1em;
}

.imedo-content .errorExplanation li {
	margin-bottom: 1em;
	list-style: disc;
	margin-left: 1em;
	padding-left: 0;
	background-image: none;
}

.imedo-content .errorExplanation {
	margin-bottom: 2em;
}

.imedo-content #terms {
	margin-top: 2em;
	padding: 1em;
	border: .0625rem solid #dddddd;
}

.imedo-content table.recommendations {
	table-layout: fixed;
}

.imedo-content table.recommendations col.recommendation-owner {
	width: 6.25rem;
}

.imedo-content table.recommendations col.recommendation-opinion {
	width: 36%;
}

.imedo-content table.recommendations td {
	font-weight: normal;
}

.imedo-content blockquote.recommendation-reply {
	font-style: italic;
	margin: 1em 0 0 1em;
	color: #555;
}

.imedo-content blockquote.recommendation-reply p::before {
	content: "\00BB";
}

.imedo-content blockquote.recommendation-reply p::after {
	content: "\00AB";
}

.imedo-content table.recommendations ul.recommendation-actions li {
	float: right;
	list-style: none;
	margin-left: 1em;
	font-size: .9em;
}

.imedo-content .healthcenter {
	border-top: .0625rem dashed #999;
	border-bottom: .0625rem dashed #999;
	margin-top: .5em;
	padding: .5em .5em 0;
}

.imedo-content div.download-section {
	margin-top: 1em;
}

.imedo-content table.downloads {
	table-layout: fixed;
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 4em;
}

.imedo-content table.downloads col.download {
	width: 25%;
}

.imedo-content table.downloads tr {
	border-bottom: .0625rem dashed #cccccc;
}

.imedo-content table.downloads td {
	padding: .5em .5em;
	display: table-cell;
	background: none;
}

.imedo-content table.downloads td.download {
	vertical-align: middle;
}

.imedo-content table.downloads h4,
.imedo-content p {
	margin-bottom: .5em;
}

.imedo-content table.downloads span.download-size,
.imedo-content a.filename {
	color: #555;
	font-style: italic;
	font-weight: normal;
	text-decoration: none;
}

.imedo-content table.downloads a.filename {
	padding: .3125rem 0 .3125rem 1.25rem;
	line-height: 1.25rem;
}

.imedo-content table.downloads .download a {
	padding: .3125rem 1.0625rem;
	color: #4790cc;
	text-decoration: underline;
}

.docsearch-big input.placeholder-input {
	color: #999999;
}

.docsearch-big #get-current-location {
	font-size: 78%;
	padding-left: 1.25rem;
}

.docsearch-big #city_choices,
.docsearch-big #expertise_choices {
	border-style: solid;
	border-color: #cccccc;
	border-width: 0 .0625rem .0625rem .0625rem;
	background: #fff;
	opacity: .95;
	z-index: 1000;
}

.docsearch-big #city_choices ul,
.docsearch-big #expertise_choices ul {
	text-align: left;
}

.docsearch-big #city_choices li,
.docsearch-big #expertise_choices li {
	cursor: pointer;
	padding: .3125rem;
	color: #000;
	font-size: .9em;
}

.docsearch-big #city_choices li.selected,
.docsearch-big #expertise_choices li.selected {
	color: #000;
	background: #d5e9ff;
}

.docsearch-big #expertise_choices li.query {
	color: #6e2b2c;
	font-style: normal;
}

.docsearch-big #expertise_choices li.query.selected {
	color: #6e2b2c;
}

.docsearch-big #expertise_choices li.suggestion.first {
	border-top: .0625rem dashed #8db2e7;
}

.docsearch-big #disambiguation {
	border-top: .0625rem dashed #cccccc;
	margin-top: 1em;
	padding-top: 1em;
}

.docsearch-big .thc2-toggle-widget {
	padding-left: 1rem;
}

.docsearch-big #advanced-search-options {
	border-top: .0625rem solid #ddd;
	border-bottom: .0625rem solid #ddd;
	padding: .5em;
	text-align: left;
	margin-bottom: 2em;
}

.docsearch-big #advanced-search-options .sorters {
	float: left;
	width: 40%;
}

.docsearch-big #advanced-search-options .filters {
	float: left;
	width: 55%;
	margin-left: 4%;
}

.docsearch-big #advanced-search-options .filter,
.docsearch-big #advanced-search-options .sorter {
	padding: .4375rem 0 0 .3125rem;
	margin: 0;
	height: 1.5625rem;
	position: relative;
	border-bottom: .0625rem solid #eee;
}

.docsearch-big #advanced-search-options .filter .name,
.docsearch-big #advanced-search-options .sorter .name {
	float: left;
	width: 6.25rem;
	font-style: normal;
	margin-top: 0;
	margin-left: 0;
	color: #555555;
	display: block;
	padding: 0 0 .2em;
	font-size: 1.2em;
}

.docsearch-big #advanced-search-options .filter.last,
.docsearch-big #advanced-search-options .sorter.last {
	border: none;
}

.docsearch-big #advanced-search-options .filter label {
	display: inline-block;
}

.docsearch-big #advanced-search-options .filter .reset {
	margin-left: .625rem;
}

.docsearch-big #advanced-search-options .filter .container {
	margin-left: 6.25rem;
	width: 20rem;
}

.docsearch-big #advanced-search-options .filter .text-field {
	padding: .0625rem;
}

.docsearch-big #advanced-search-options .filter ul.stars {
	float: left !important;
	width: auto;
	cursor: pointer;
}

.docsearch-big #advanced-search-options .filter ul.stars li {
	margin-right: 0;
}

.docsearch-big #advanced-search-options .filter ul.stars li div {
	top: 0;
	padding: .0625rem .9375rem .125rem 0;
}

.docsearch-big #advanced-search-options .filter ul.stars li.star-label {
	display: none;
}

.docsearch-big #advanced-search-options label.thc2-input-rating-reset,
.docsearch-big #advanced-search-options .thc2-radio-links label {
	margin-right: .3em;
	padding: .3em;
	font-style: normal;
	color: #1485ba;
	text-decoration: underline;
	border-radius: .6em;
	border-style: solid;
	border-width: .0625rem;
	border-color: transparent;
}

.docsearch-big #advanced-search-options .thc2-radio-links label.selected {
	background: #3e97d0;
	color: #fff;
	text-decoration: none;
	border-color: #1485ba;
}

.docsearch-big #advanced-search-options .thc2-radio-links label.reset.selected {
	color: #333333;
	background: none;
	border-color: transparent;
	text-decoration: underline;
}

.docsearch-big #advanced-search-options .sorter .name {
	width: 15rem;
}

.docsearch-big .thc2-input-rating-reset {
	color: #333333;
	float: right;
	padding: 0;
	margin-right: 0;
}

.docsearch-big #advanced-search-options .filter label.reset a,
.docsearch-big #advanced-search-options .filter label.reset {
	color: #333333;
	float: right;
	padding: 0;
	margin-right: 0;
}

.docsearch-big .thc2.search-links a,
.docsearch-big .thc2-disambiguation-links a {
	line-height: 1.6;
	padding: .3em;
	margin-right: .3em;
	border: .0625rem solid transparent;
	white-space: pre;
	font-size: .8em;
	color: #aaa;
}

.docsearch-big .thc2-search-links a:hover,
.docsearch-big .thc2-disambiguation-links a:hover,
.docsearch-big .thc2-radio-group-links a:hover {
	color: #777;
}

.docsearch-big .thc2-search-links a.active,
.docsearch-big .thc2-disambiguation-links a.active,
.docsearch-big .thc2-radio-group-links a.active {
	background: #3E97D0;
	color: #fff;
	text-decoration: none;
	border-radius: .6em;
	border: .0625rem solid #1485ba;
}

.docsearch-big #expertise-in-location {
	border-top: .0625rem solid #cce5ff;
	margin-top: 1em;
	padding-top: 1em;
	clear: left;
}

.docsearch-big #expertise-in-location h4 {
	font-size: 1.2em;
	font-weight: bold;
}

.imedo-content #filter-indicator {
	position: absolute;
	text-align: center;
	width: 100%;
	top: .625rem;
}

.imedo-content #map_canvas {
	height: 31.25rem;
	border: .0625rem solid #aaa;
}

.imedo-content table#provider_container {
	margin-top: 2%;
	width: 100%;
	border-collapse: collapse;
	table-layout: fixed;
	border: .0625rem solid #fff;
}

.imedo-content table#provider_container td {
	border: .0625rem solid #ccc;
	background: #fff;
}

.imedo-content table#provider_container td address {
	display: none;
}

.imedo-content table#provider_container td h3 {
	font-size: 110%;
	clear: none;
}

.imedo-content table#provider_container td a.pin_no {
	float: right;
	margin: .25rem .25rem 0 0;
}

.imedo-content table#provider_container td div.provider_image {
	float: left;
	width: 20%;
}

.imedo-content table#provider_container td div.provider_details {
	margin-left: 22%;
	width: 67%;
	padding: .125rem 0 0 .3125rem;
}

.imedo-content table#provider_container td .rating {
	margin: .3125rem 0;
}

.imedo-content .generic-featured #provider_container h3,
.imedo-content .generic-featured .provider-name h3 {
	padding-left: 0;
}

.imedo-content #display_buttons {
	float: right;
}

.imedo-content #display_buttons li {
	float: left;
}

.imedo-content .provider_info_window h3 {
	font-size: 110%;
	padding: 0;
	clear: none;
}

.imedo-content .provider_info_window .pin_no {
	display: none;
}

.imedo-content .provider_info_window .provider_image {
	float: left;
	width: 3.125rem;
}

.imedo-content .provider_info_window .provider_details {
	margin-left: 3.4375rem;
}

.imedo-content #provider_listing h3 {
	margin-top: 0;
	clear: none;
}

.imedo-content .provider-recommendations .video-icon {
	display: block;
	margin-top: .3125rem;
}

.imedo-content .provider-search-results table tr.sponsored-link td {
	border-bottom: .0625rem dashed #c2c0bd;
}

.imedo-content .provider-search-results table tr.sponsored-link td.provider-compare {
	border-bottom: none;
}

.imedo-content .provider-search-results table tr.sponsored-link td p {
	font-weight: normal;
}

.imedo-content .provider-search-results table tr.sponsored-link td p.teaser {
	font-weight: normal;
}

.imedo-content .provider-search-results table tr.sponsored-link span.metainfo {
	color: #585752;
	font-weight: normal;
}

.imedo-content .rating-stars {
	height: .9375rem;
	text-align: left;
	display: inline-block;
	width: 4.25rem;
}

.imedo-content .rating-stars .full-stars {
	height: .9375rem;
}

/**
* @note CSS-Datei fuer die Farben der Arztsuche (gemeinsame Elemente und Klassen)
*/
.imedo-content .member-thumb img {
	border: none;
	text-decoration: none;
}

.imedo-content .check-green {
}

.imedo-content label span {
	color: #777;
}

.imedo-content .docsearch-header p {
	color: #585754;
}

.imedo-content .docsearch-header h3 {
	color: #585754;
}

.imedo-content a.dropdown-tab:hover,
.imedo-content li.toplevel:hover a.dropdown-tab {
	background-color: #fff;
	border-style: solid;
	border-color: #C2C0BD;
	border-width: .0625rem .0625rem 0 .0625rem;
}

.imedo-content ul.city-list ul {
	border-left: .0625rem solid #C2C0BD;
	border-right: .0625rem solid #C2C0BD;
	border-top: .0625rem solid #C2C0BD;
}

.imedo-content ul.city-list ul a {
	text-decoration: none;
}

.imedo-content ul.map-dropdown li a:hover {
	color: #000 !important;
	border-top: solid .0625rem #edbe00;
	border-bottom: solid .0625rem #edbe00;
}

.imedo-content ul.map-dropdown li.dropdown-last {
	border-bottom: solid .0625rem #C2C0BD;
}

.imedo-content ul.map-dropdown li.dropdown-last a:hover {
	color: #f08201 !important;
	background: none;
}

.imedo-content .act {
	color: #4790cc;
}

.imedo-content .tab-list-alphabet,
.imedo-content .tab-list {
	border-bottom: .0625rem solid #4790cc;
}

.imedo-content .tab-list-alphabet li,
.imedo-content .tab-list li {
	border: .0625rem solid #bce542;
	border-bottom: none;
}

.imedo-content .tab-list-alphabet li a,
.imedo-content .tab-list-alphabet li span,
.imedo-content .tab-list li a,
.imedo-content .tab-list li span {
	color: #75990b;
}

.imedo-content .tab-list-alphabet li a:hover,
.imedo-content .tab-list li a:hover {
	color: #F08201;
}

.imedo-content .tab-list-alphabet li.selected,
.imedo-content .tab-list li.selected {
	border: .0625rem solid #4790cc;
	border-bottom: none;
}

.imedo-content .tab-list-alphabet li.selected a,
.imedo-content .tab-list-alphabet li.selected span,
.imedo-content .tab-list li.selected a,
.imedo-content .tab-list li.selected span {
	color: #4790cc;
}

.imedo-content .table-style td,
.imedo-content .table-style th {
	border-bottom: .0625rem solid #C2C0BD;
	font-weight: bold;
}

.imedo-content .odd,
.imedo-content .odd-alt {
	background-color: #fff;
}

.imedo-content .even,
.imedo-content .even-alt {
	background-color: #fff;
}

.imedo-content p.deactivated {
	color: #777;
}

.imedo-content .doc-info-footer {
	border-top: .0625rem solid #C2C0BD;
}

.imedo-content .premium-provider-box .premium-item {
	border-color: #4790CC;
	border-width: .0625rem;
	border-style: solid;
	background: #fff;
}

.imedo-content .imedo-logo img {
	border: 0 solid;
}

/**
* @note CSS-Datei fuer die Arztsuche (medium)
*/

.docsearch-medium .features {
	display: none;
}

.docsearch-medium .docsearch-map {
	width: 23.75rem;
	height: 33.125rem;
	margin: 0 auto 2em auto;
	position: relative;
}

.docsearch-medium .suggest_new_entry {
	margin-left: 1em;
}

.docsearch-medium .docsearch-content {
	padding: 1em;
}

.docsearch-medium .description h4 {
	color: #000;
	font-size: 100%;
	font-weight: bold;
}

.docsearch-medium .button-container {
	width: 8em;
}

.docsearch-medium .display-options {
	width: 18em;
}

.docsearch-medium .map-display {
	width: 23.75rem !important;
	height: 33.125rem !important;
	position: relative;
	z-index: 0;
	top: -66.25rem !important;
}

.docsearch-medium .map-img {
	top: -33.125rem !important;
}

.docsearch-medium ul.city-list.map-overlay #area-bw,
.docsearch-medium ul.city-list.map-overlay #area-1 {
	top: 26.25rem;
	left: 4.125rem;
}

.docsearch-medium ul.city-list.map-overlay #area-by,
.docsearch-medium ul.city-list.map-overlay #area-2 {
	top: 25.625rem;
	left: 15rem;
}

.docsearch-medium ul.city-list.map-overlay #area-be,
.docsearch-medium ul.city-list.map-overlay #area-3 {
	top: 9.6875rem;
	left: 18.75rem;
}

.docsearch-medium ul.city-list.map-overlay #area-bb,
.docsearch-medium ul.city-list.map-overlay #area-4 {
	top: 12.1875rem;
	left: 19.0625rem;
}

.docsearch-medium ul.city-list.map-overlay #area-hb,
.docsearch-medium ul.city-list.map-overlay #area-5 {
	top: 7.5rem;
	left: 7.5rem;
}

.docsearch-medium ul.city-list.map-overlay #area-hh,
.docsearch-medium ul.city-list.map-overlay #area-6 {
	top: 5.9375rem;
	left: 10rem;
}

.docsearch-medium ul.city-list.map-overlay #area-he,
.docsearch-medium ul.city-list.map-overlay #area-7 {
	top: 18.4375rem;
	left: 7.5rem;
}

.docsearch-medium ul.city-list.map-overlay #area-mv,
.docsearch-medium ul.city-list.map-overlay #area-8 {
	top: 5rem;
	left: 14.0625rem;
}

.docsearch-medium ul.city-list.map-overlay #area-ni,
.docsearch-medium ul.city-list.map-overlay #area-9 {
	top: 9.6875rem;
	left: 5.625rem;
}

.docsearch-medium ul.city-list.map-overlay #area-nw,
.docsearch-medium ul.city-list.map-overlay #area-10 {
	top: 15rem;
	left: 1.875rem;
}

.docsearch-medium ul.city-list.map-overlay #area-rp,
.docsearch-medium ul.city-list.map-overlay #area-11 {
	top: 19.8125rem;
	left: 1.9375rem;
}

.docsearch-medium ul.city-list.map-overlay #area-sl,
.docsearch-medium ul.city-list.map-overlay #area-12 {
	top: 23rem;
	left: 1.875rem;
}

.docsearch-medium ul.city-list.map-overlay #area-sn,
.docsearch-medium ul.city-list.map-overlay #area-13 {
	top: 16.25rem;
	left: 18.75rem;
}

.docsearch-medium ul.city-list.map-overlay #area-st,
.docsearch-medium ul.city-list.map-overlay #area-14 {
	top: 13.625rem;
	left: 13.0625rem;
}

.docsearch-medium ul.city-list.map-overlay #area-sh,
.docsearch-medium ul.city-list.map-overlay #area-15 {
	top: 3.625rem;
	left: 7.8125rem;
}

.docsearch-medium ul.city-list.map-overlay #area-th,
.docsearch-medium ul.city-list.map-overlay #area-16 {
	top: 17.5rem;
	left: 12.5rem;
}

.docsearch-medium .provider-certificates {
	display: none;
}

.docsearch-medium .premium-provider-box .premium-item {
	width: 29%;
}

.docsearch-medium .premium-provider-box .premium-item p {
	display: none;
}

.docsearch-medium .premium-provider-box .premium-item .description {
	width: 100%;
}

.docsearch .about-container img {
	height: auto;
	max-width: 100%;
}

/* Social-Media-Leiste -------------------------------------------------------------------------- */

[class^="icon-"]:before, [class*=" icon-"]:before {
	@include icon-styles();
}

.icon-email:before {
	content: icon-char("envelope");
}

.icon-facebook:before {
	content: icon-char("facebook-square");
}

.icon-pinterest:before {
	content: icon-char("pinterest");
}

.icon-twitter:before {
	content: icon-char("twitter");
}

.icon-whatsapp:before {
	content: icon-char("whatsapp");
}

.socialbar {
	list-style: none;
	margin: 0 0 1.25rem 0;
	overflow: hidden;
	padding: 0;
	width: 100%;
}

.socialbar__button {
	color: #fff;
	cursor: pointer;
	height: 1.875rem;
	float: left;
	font-size: .75rem;
	line-height: 1.875rem;
	margin-right: .625rem;
	min-width: 7.625rem;
	text-align: left;
}

.socialbar__button:last-child {
	margin-right: 0;
}

.socialbar__button:before {
	font-size: 1.5em;
	line-height: inherit;
	padding: 0 .3125rem 0 .625rem;
}

.share-button__text {
	color: inherit;
	display: inline-block;
	font-size: inherit;
	text-decoration: none;
	line-height: inherit;
	vertical-align: top;
}

a.share-button__text, a.share-button__text:active, a.share-button__text:visited, a.share-button__text:hover {
	color: #fff;
}

.socialbar__button--email {
	background-color: #999;
}

.socialbar__button--facebook {
	background-color: #3b5998;
}

.socialbar__button--pinterest {
	background-color: #e60023;
}

.socialbar__button--twitter {
	background-color: #55acee;
}

.socialbar__button--whatsapp {
	background-color: #5cbe4a;
	display: none;
}

/* TinyMCE -------------------------------------------------------------------------------------- */

.mceListBoxMenu {
	/* Groesser als z-index der Lightbox (z.Zt. ~5 Mio)! */
	z-index: 2000000000 !important;
}

/* Tooltip -------------------------------------------------------------------------------------- */

/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
	position: relative;
	z-index: 2;
	cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before, [data-tooltip]:after {
	visibility: hidden;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
	opacity: 0;
	pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
	background-color: #000;
	background-color: hsla(0, 0%, 20%, .9);
	border-radius: .1875rem;
	bottom: 150%;
	color: #fff;
	content: attr(data-tooltip);
	font-size: .8125rem;
	left: 50%;
	line-height: 1.5;
	margin-bottom: .3125rem;
	margin-left: -5rem;
	padding: .4375rem;
	position: absolute;
	text-align: left;
	width: 17.5em;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
	border-top: .3125rem solid #000;
	border-top: .3125rem solid hsla(0, 0%, 20%, .9);
	border-right: .3125rem solid transparent;
	border-left: .3125rem solid transparent;
	bottom: 150%;
	content: " ";
	font-size: 0;
	left: 50%;
	line-height: 0;
	margin-left: -0.3125rem;
	position: absolute;
	width: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before, [data-tooltip]:hover:after {
	visibility: visible;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	opacity: 1;
}

/* ---------------------------------------------------------------------------------------------- */

/* Slider */
.slick {
	float: left;
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
	min-height: 23.75rem;
	width: 32.5rem;
}

.slick-list {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0;
}

.slick-list:focus {
	outline: none;
}

.slick-list.dragging {
	cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.slick-track:before,
.slick-track:after {
	content: "";
	display: table;
}

.slick-track:after {
	clear: both;
}

.slick-loading .slick-track {
	visibility: hidden;
}

.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: .0625rem;
	position: relative;
}

[dir="rtl"] .slick-slide {
	float: right;
}

.slick-slide.slick-loading img {
	display: none;
}

.slick-slide.dragging img {
	pointer-events: none;
}

.slick-initialized .slick-slide {
	display: block;
}

.slick-loading .slick-slide {
	visibility: hidden;
}

.slick-vertical .slick-slide {
	border: .0625rem solid transparent;
	display: block;
	height: auto;
}

.slick-arrow {
	cursor: pointer;
}

.slick .prev,
.slick .next {
	background-color: rgba(11, 60, 104, .8);
	border: none;
	color: #cbd2db;
	cursor: pointer;
	display: block;
	height: 2.5rem;
	position: absolute;
	line-height: 2.5rem;
	padding: 0;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	text-align: center;
	transform: translate(0, -50%);
	-webkit-transition: opacity .3s ease-in-out;
	transition: opacity .3s ease-in-out;
	opacity: 0;
	outline: none;
	width: 2.5rem;
	z-index: 2;
}

.slick:hover .prev,
.slick:hover .next {
	opacity: 1;
}

.slick .next {
	right: 0;
}

.slick-slide {
	margin: 0;
}

.slick img {
	display: block;
	height: auto;
	width: 100%;
}

.slick-slide img {
	margin: 0 auto;
}
.slick .u-slide .img-original,
.slick-slide .img-original {
	// Static height for CLS
	height: 24.375rem;
	margin: 0 auto;
	width: auto;
}

.slick-prev:before,
.slick-next:before {
	color: black;
}

.slick-slide {
	position: relative;
	transition: all ease-in-out .3s;
	opacity: .2;
}

.slick-active {
	opacity: .5;
}

.slick-current {
	opacity: 1;
}

.slick .inner .hl,
.slick .inner .txt {
	/* Content is copied to the sidebar via JS */
	display: none;
}

.slick .inner:not(.isAd) .wrapper:after {
	background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, .3)));
	background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, .3));
	bottom: 0;
	content: "";
	height: 20%;
	left: 0;
	pointer-events: none;
	position: absolute;
	width: 100%;
}

.slick .prev-wrap {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 3.75rem;
	z-index: 2;
}

.slick .next-wrap {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	width: 3.75rem;
	z-index: 2;
}

.slick .image-number {
	display: none;
}

@media all and (min-width: 64rem) {
	.last-slide-active .next-wrap,
	.last-slide-active .prev-wrap {
		/* overwrite JS inline styles */
		display: none !important;
	}
}

.slick .inner {
	position: relative;
}

// Only show first slide if slick is not initialized to prevent layout shift
.slick:not(.slick-initialized) {
	.inner:not(:first-of-type),
	.teaser-wrapper {
		display: none;
	}
}

/* Rating */

.raty {
	white-space: nowrap;
}

.raty img {
	vertical-align: top;
}

.raty-wrapper {
	display: flex;
	margin: 1rem 0;
	// For CLS
	min-height: 1.5rem;
}

.raty-message {
	padding-left: .5rem;
	padding-top: .125rem;
}

.raty i {
	display: inline-block;
	width: 1.25rem;
}

.raty i:before {
	@include icon-styles();
	font-size: 1.125rem;
	font-style: normal;
	line-height: 1.125rem;
}

.raty .star:before {
	content: icon-char("star");
	color: #0D497F;
}

.raty .star-empty:before {
	content: icon-char("star-empty");
	color: #aaa;
}

.raty .star-half:before {
	content: icon-char("star-half");
	color: #0D497F;
	// Fix icon position
	position: relative;
	right: -0.046875rem;
}

/* Quality Info */

.quality_info {
	align-items: center;
	color: #333;
	display: flex;
	font-weight: bold;
	font-size: .9375rem;
	margin: 1em;
}

.quality_info:active, .quality_info:visited {
	color: #333;
}

.quality_info:hover {
	color: #0D497F;
	text-decoration: none;
}

.quality_info__icon {
	margin: 0 1em 0 0;
	height: 3.75rem;
}

.quality_info .fa-info-circle {
	margin-left: .5rem;
}

/* Expertise slider */

.mod_expertise {
	border-bottom: .0625rem solid #0D497F;
	border-top: .0625rem solid #0D497F;
	margin: 0;
	position: relative;
}

.mod_expertise .mod_expertise__slider {
	margin-bottom: 0;
	padding: 0;
	width: 100%;
}

.mod_expertise .mod_expertise__slide {
	padding: 1rem 2rem 4.5rem;
}

.mod_expertise .mod_expertise__slider > li:not(:first-child) {
	display: none;
}

.mod_expertise .mod_expertise__slider.slick-slider .slick-slide {
	display: block;
}

.mod_expertise .intro {
	text-align: center;
}

.mod_expertise .intro .hd {
	border: none;
	font-size: 1.375rem;
	font-weight: bold;
	margin: 1.875rem 0 2rem;
}

.mod_expertise .intro img {
	border: .0625rem solid #eee;
	border-radius: 50%;
	height: 6rem;
	margin-bottom: 2rem;
	width: 6rem;
}

.mod_expertise .intro .author__name {
	font-size: 1.125rem;
	font-weight: bold;
	margin-bottom: .5rem;
}

.mod_expertise .question.question {
	font-size: 1.125rem;
	font-weight: bold;
	line-height: 1.5rem;
	margin: 1rem 0;
}

.mod_expertise .number {
	background-color: #0D497F;
	border-radius: 50%;
	color: white;
	display: block;
	font-size: 1.125rem;
	font-weight: bold;
	height: 1.875rem;
	line-height: 1.875rem;
	margin: 0 auto;
	text-align: center;
	width: 1.875rem;
}

.mod_expertise .outro {
	text-align: center;
}

.mod_expertise .outro-headline {
	font-size: 1.25rem;
	line-height: 1.3;
	margin: 1rem 0 2rem;
}

.mod_expertise .outro-text {
	color: #0D497F;
	font-size: 1.5rem;
	font-weight: bold;
	line-height: 1.5;
	margin-bottom: 1.5rem;
}

.mod_expertise .outro-link,
.mod_expertise .outro-link:active,
.mod_expertise .outro-link:hover,
.mod_expertise .outro-link:visited {
	background: #0d497f none;
	font-size: 1rem;
	height: 2.5rem;
	line-height: 2.5rem;
	margin: 0 auto;
	width: 11rem;
}

.mod_expertise .next,
.mod_expertise .prev {
	background-color: #0D497F;
	bottom: .125rem;
	color: #fff;
	cursor: pointer;
	display: block;
	font-size: 1.875rem;
	font-style: normal;
	font-weight: normal;
	height: 2.5rem;
	line-height: 2.3rem;
	position: absolute;
	text-align: center;
	text-decoration: none;
	text-indent: 0;
	vertical-align: top;
	width: 2.5rem;
	z-index: 1;
}

.mod_expertise .prev {
	left: 0;
}

.mod_expertise .prev {
	@include icon("chevron-left");

	&:before {
		font-size: 1.4375rem;
		padding-right: .3125rem;
	}
}

.mod_expertise .next {
	@include icon("chevron-right");
	right: 0;

	&:before {
		font-size: 1.4375rem;
		padding-left: .3125rem;
	}
}

.mod_expertise .slick-dots {
	bottom: 0;
	display: flex;
	justify-content: center;
	list-style: none;
	margin: 0 0 .625rem 0;
	position: absolute;
	width: 100%;
}

.mod_expertise .slick-dots button {
	display: block;
	height: 1.4375rem;
	opacity: 0;
	width: 1.4375rem;
}

.mod_expertise .slick-dots li {
	position: relative;
}

.mod_expertise .slick-dots li:before {
	content: "";
	background-color: #0D497F;
	border-radius: 50%;
	display: block;
	height: .625rem;
	margin: .4375rem;
	outline: none;
	overflow: hidden;
	position: absolute;
	width: .625rem;
	z-index: 1;
}

.mod_expertise .slick-dots .slick-active {
	opacity: .5;
}

/* Expertise tip */

.mod_expertise--tip {
	padding: 1.5rem 1rem;
}

@media screen and (min-width: 48em) {
	.mod_expertise--tip {
		display: flex;
	}
}

@media screen and (min-width: 48em) {
	.mod_expertise--tip .tip-wrapper {
		padding-right: 1rem;
		width: 60%;
	}
}

.mod_expertise--tip .hd {
	border: none;
	font-size: 1.125rem;
	font-weight: bold;
	margin-bottom: 1rem;
}

.mod_expertise--tip .txt {
	font-size: 1.125rem;
	font-style: italic;
}

.mod_expertise--tip .author {
	padding-top: 1rem;
	text-align: center;
}

@media screen and (min-width: 48em) {
	.mod_expertise--tip .author {
		padding-top: 0;
		width: 40%;
	}
}

.mod_expertise--tip .author img {
	border: .0625rem solid #eee;
	border-radius: 50%;
	height: 10rem;
	width: 10rem;
	margin-bottom: .5rem;
}

.mod_expertise--tip .author__name {
	font-size: 1rem;
	font-weight: bold;
	margin-bottom: .25rem;
}

.mod_expertise--tip .author__job {
	font-size: .75rem;
}

/* Frage stellen - Experten-Teaser */

.mod_question_ask_expert {
	border-bottom: .0625rem solid #e6e3dc;
}

.mod_question_ask_expert .bd {
	display: flex;
	margin: .9375rem 0;
}

.mod_question_ask_expert .img {
	width: 5.625rem;
}

.mod_question_ask_expert .txt {
	flex: 1;
}

.mod_question_ask_expert .avatar {
	border: .0625rem solid #eee;
	border-radius: 50%;
	display: block;
	float: left;
	height: 3.75rem;
	margin: 0 .9375rem;
	width: 3.75rem;
}

.mod_question_ask_expert .expertname {
	font-size: 1rem;
	font-weight: bold;
	line-height: 1.25rem;
}

.mod_question_ask_expert .expertdesc {
	font-size: .875rem;
	line-height: 1.0625rem;
	margin: .625rem 0 0 0;
}

.mod_question_ask_expert .button {
	background: #0d497f;
	font-size: .875rem;
	height: 1.875rem;
	line-height: 1.875rem;
	margin: 1.5625rem 0 0 0;
}
